import { selectWithoutLabel, CommonProps$1, toNoOptionsMessage, CommonPropsMultiSelect$1, OptionType$1 } from "../../SharedComponents/ReactSelect.js";
import { ofArray, singleton as singleton_1, append, contains, filter, map, toArray } from "../../fable_modules/fable-library-js.4.19.2/List.js";
import { createObj, stringHash, safeHash, equals } from "../../fable_modules/fable-library-js.4.19.2/Util.js";
import { tryFind } from "../../fable_modules/fable-library-js.4.19.2/Array.js";
import { createElement } from "react";
import React from "react";
import * as react from "react";
import { useTranslation } from "react-i18next";
import { empty, singleton, append as append_1, delay, toList } from "../../fable_modules/fable-library-js.4.19.2/Seq.js";
import { RadioButton } from "../../Components/RadioButton.js";
import { Msg } from "./Types.js";
import { reactApi } from "../../fable_modules/Feliz.2.8.0/Interop.fs.js";
import { AutoSuggestInput, Input } from "../../Components/Input.js";
import { unwrap } from "../../fable_modules/fable-library-js.4.19.2/Option.js";
import { FormKind as FormKind_1, FormState__getValidation } from "../../Shared/Shared.js";
import { FormContainer } from "../../Components/FormContainer.js";
import { defaultOf } from "../../fable_modules/fable-library-js.4.19.2/Util.js";
import { ErrorAlert } from "../../Components/Alert.js";
import { join } from "../../fable_modules/fable-library-js.4.19.2/String.js";
import { TooltipProps, Tooltip } from "../../SharedComponents/ReactTooltip.js";
import { MultiSelect$0027 } from "../../SharedComponents/ReactSelect.js";
import { Checkbox } from "../../Components/Checkbox.js";
import { AllowUserToSubcontractorTransfer } from "../../Shared/User.js";
import { EmailNotification } from "../../Widgets/EmailNotification.js";
import { NewReceiverCustomProperties } from "../../Widgets/CustomProperties.js";
import { PropertyConfigurationVisibility } from "../../Shared/PropertyConfiguration.js";
import { React_useElmish_Z6C327F2E } from "../../fable_modules/Feliz.UseElmish.2.5.0/UseElmish.fs.js";
import { ProgramModule_mkProgram } from "../../fable_modules/Fable.Elmish.4.2.0/program.fs.js";
import { initEdit, update as update_1, initNew } from "./State.js";
import { RouterModule_nav } from "../../fable_modules/Feliz.Router.4.0.0/Router.fs.js";
import { Skeleton } from "../../Components/Skeleton.js";

function storageProps(storages, user, selectedStorageIds, selectStorage, isDisabled) {
    let storages_1;
    const storageToSelectElement = (storage) => (new OptionType$1(storage.Name, storage));
    const valueElements = new CommonPropsMultiSelect$1(3, [toArray(map(storageToSelectElement, filter((storage_3) => contains(storage_3.Id, selectedStorageIds, {
        Equals: equals,
        GetHashCode: safeHash,
    }), storages)))]);
    return [new CommonPropsMultiSelect$1(0, [(storages_1 = storages, toArray(map(storageToSelectElement, filter((storage_2) => !contains(user.Id, append(storage_2.AssignedUserIds, storage_2.AccessByUser), {
        Equals: (x, y) => (x === y),
        GetHashCode: stringHash,
    }), (user.Role === "user") ? filter((storage_1) => !storage_1.IsSelfService, storages_1) : storages_1))))]), new CommonPropsMultiSelect$1(2, [selectStorage]), new CommonPropsMultiSelect$1(5, [true]), new CommonPropsMultiSelect$1(9, [false]), new CommonPropsMultiSelect$1(8, [true]), new CommonPropsMultiSelect$1(6, ["user.select_storage_placeholder"]), new CommonPropsMultiSelect$1(11, [(_arg) => toNoOptionsMessage("user.select_storage_no_options", _arg)]), new CommonPropsMultiSelect$1(12, ["flex-grow-1"]), new CommonPropsMultiSelect$1(7, [isDisabled]), valueElements];
}

function roleProps(user, isDisabled, selectRole) {
    const options = [new OptionType$1("role.admin", "administrator"), new OptionType$1("role.tool_manager", "toolManager"), new OptionType$1("role.employee", "user")];
    return [new CommonProps$1(1, [options]), new CommonProps$1(2, [selectRole]), new CommonProps$1(5, [true]), new CommonProps$1(9, [false]), new CommonProps$1(6, ["user.role_select_placeholder"]), new CommonProps$1(10, [(_arg) => toNoOptionsMessage("user.role_select_no_options_message", _arg)]), new CommonProps$1(11, ["mechanic-select flex-grow-1"]), new CommonProps$1(7, [isDisabled]), new CommonProps$1(4, [tryFind((o) => (o.value === user.Role), options)])];
}

export function LoginInformation(props) {
    const t = useTranslation()[0];
    const user = props.User;
    const componentState = props.FormState.IsLoading ? "disabled" : "enabled";
    const xs_1 = toList(delay(() => {
        let elems;
        return append_1(singleton(createElement("div", createObj(singleton_1((elems = [RadioButton({
            ComponentState: componentState,
            Label: t("user.login_with_email"),
            OnSelect: (_arg) => {
                props.Dispatch(new Msg(10, [false]));
            },
            Selected: !(user.LoginInformation.tag === 1),
        }), RadioButton({
            ComponentState: componentState,
            Label: t("user.login_with_phone_number"),
            OnSelect: (_arg_1) => {
                props.Dispatch(new Msg(10, [true]));
            },
            Selected: !(user.LoginInformation.tag === 0),
        })], ["children", reactApi.Children.toArray(Array.from(elems))]))))), delay(() => {
            const matchValue_2 = user.LoginInformation;
            return (matchValue_2.tag === 0) ? singleton(createElement(Input, {
                ComponentState: componentState,
                Label: t("general.email"),
                OnChange: (arg_1) => {
                    props.Dispatch(new Msg(2, [arg_1]));
                },
                TestId: "",
                ValidationMessage: unwrap(FormState__getValidation(props.FormState, t, "email")),
                Value: matchValue_2.fields[0],
            })) : singleton(createElement(Input, {
                ComponentState: componentState,
                Label: t("user.phone_number"),
                OnChange: (arg) => {
                    props.Dispatch(new Msg(3, [arg]));
                },
                TestId: "",
                ValidationMessage: unwrap(FormState__getValidation(props.FormState, t, "phoneNumber")),
                Value: matchValue_2.fields[0],
            }));
        }));
    }));
    return react.createElement(react.Fragment, {}, ...xs_1);
}

export function UserForm(props) {
    const t = useTranslation()[0];
    const componentState = props.FormState.IsLoading ? "disabled" : "enabled";
    return createElement(FormContainer, {
        Children: toList(delay(() => {
            let matchValue;
            return append_1((matchValue = FormState__getValidation(props.FormState, t, "global"), (matchValue == null) ? singleton(defaultOf()) : singleton(createElement(ErrorAlert, {
                Label: matchValue,
            }))), delay(() => append_1(equals(props.FormKind, new FormKind_1(0, [])) ? singleton(createElement(LoginInformation, {
                Dispatch: props.Dispatch,
                FormState: props.FormState,
                User: props.User,
            })) : empty(), delay(() => append_1(singleton(createElement(Input, {
                ComponentState: componentState,
                Label: t("general.name"),
                OnChange: (arg) => {
                    props.Dispatch(new Msg(4, [arg]));
                },
                TestId: "",
                ValidationMessage: unwrap(FormState__getValidation(props.FormState, t, "name")),
                Value: props.User.Name,
            })), delay(() => append_1(singleton(createElement(Input, {
                ComponentState: componentState,
                Label: t("user.personnel_number"),
                OnChange: (arg_1) => {
                    props.Dispatch(new Msg(0, [arg_1]));
                },
                TestId: "",
                Value: props.User.PersonnelNumber,
            })), delay(() => {
                let dependencies_1;
                return append_1(singleton((dependencies_1 = [props.User.Department, props.DepartmentSuggestions, componentState], reactApi.useMemo(() => createElement(AutoSuggestInput, {
                    ComponentState: componentState,
                    Label: t("user.department"),
                    OnChange: (arg_2) => {
                        props.Dispatch(new Msg(1, [arg_2]));
                    },
                    Suggestions: props.DepartmentSuggestions,
                    TestId: "",
                    Value: props.User.Department,
                }), dependencies_1))), delay(() => {
                    let elems;
                    return append_1(singleton(createElement("div", createObj(ofArray([["className", "select-with-label form-group flex-grow-1 pt-5"], (elems = [createElement("label", {
                        className: join(" ", ["mt-auto", "mb-auto", "mr-2", "text-sm", "font-bold"]),
                        children: t("general.role"),
                    }), createElement(Tooltip, {
                        props: ofArray([new TooltipProps(4, [true]), new TooltipProps(0, ["user.tooltip_role"])]),
                    }), selectWithoutLabel(roleProps(props.User, false, (arg_3) => {
                        props.Dispatch(new Msg(5, [arg_3]));
                    }))], ["children", reactApi.Children.toArray(Array.from(elems))])])))), delay(() => {
                        let matchValue_1, elems_2, elems_3, elems_1;
                        return append_1((matchValue_1 = props.User.Role, (matchValue_1 === "toolManager") ? singleton(createElement("div", createObj(ofArray([["className", "select-with-label form-group flex-grow-1 pt-5"], (elems_2 = [createElement("label", {
                            className: join(" ", ["mt-auto", "mb-auto", "mr-2", "text-sm", "font-bold"]),
                            children: t("general.storage"),
                        }), createElement(Tooltip, {
                            props: ofArray([new TooltipProps(4, [true]), new TooltipProps(0, ["user.tooltip_storage"])]),
                        }), createElement(MultiSelect$0027, {
                            Props: storageProps(props.Storages, props.User, props.SelectedStorageIds, (arg_5) => {
                                props.Dispatch(new Msg(9, [arg_5]));
                            }, props.FormState.IsLoading),
                        })], ["children", reactApi.Children.toArray(Array.from(elems_2))])])))) : ((matchValue_1 === "user") ? (props.CustomerConfiguration.GlobalSelfServiceStorageActive ? (empty()) : singleton(createElement("div", createObj(ofArray([["className", "select-with-label form-group flex-grow-1 pt-5"], (elems_3 = [createElement("label", {
                            className: join(" ", ["mt-auto", "mb-auto", "mr-2", "text-sm", "font-bold"]),
                            children: t("general.storage"),
                        }), createElement(Tooltip, {
                            props: ofArray([new TooltipProps(4, [true]), new TooltipProps(0, ["user.tooltip_storage"])]),
                        }), createElement(MultiSelect$0027, {
                            Props: storageProps(props.Storages, props.User, props.SelectedStorageIds, (arg_6) => {
                                props.Dispatch(new Msg(9, [arg_6]));
                            }, props.FormState.IsLoading),
                        })], ["children", reactApi.Children.toArray(Array.from(elems_3))])]))))) : ((matchValue_1 === "system") ? singleton(defaultOf()) : singleton(createElement("div", createObj(ofArray([["className", "select-with-label form-group flex-grow-1 pt-5"], (elems_1 = [createElement("label", {
                            className: join(" ", ["mt-auto", "mb-auto", "mr-2", "text-sm", "font-bold"]),
                            children: t("general.storage"),
                        }), createElement(Tooltip, {
                            props: ofArray([new TooltipProps(4, [true]), new TooltipProps(0, ["user.tooltip_storage"])]),
                        }), createElement(MultiSelect$0027, {
                            Props: storageProps(props.Storages, props.User, props.SelectedStorageIds, (arg_4) => {
                                props.Dispatch(new Msg(9, [arg_4]));
                            }, props.FormState.IsLoading),
                        })], ["children", reactApi.Children.toArray(Array.from(elems_1))])]))))))), delay(() => {
                            let matchValue_2, elems_5, elems_4;
                            return append_1((matchValue_2 = props.User.Role, (matchValue_2 === "toolManager") ? (empty()) : ((matchValue_2 === "system") ? (empty()) : ((matchValue_2 === "user") ? singleton(createElement("div", createObj(ofArray([["className", join(" ", ["flex", "pt-5"])], (elems_5 = [Checkbox({
                                ComponentState: componentState,
                                IsChecked: equals(props.User.AllowUserToSubcontractorTransfer, new AllowUserToSubcontractorTransfer(0, [])),
                                Label: t("user.user_to_subcontractor"),
                                OnCheck: (arg_7) => {
                                    props.Dispatch(new Msg(6, [arg_7]));
                                },
                                TestId: "",
                            }), createElement("div", createObj(ofArray([["className", join(" ", ["ml-2", "pt-5"])], (elems_4 = [createElement(Tooltip, {
                                props: ofArray([new TooltipProps(4, [true]), new TooltipProps(0, ["user.user_to_subcontractor_tool_tip"])]),
                            })], ["children", reactApi.Children.toArray(Array.from(elems_4))])])))], ["children", reactApi.Children.toArray(Array.from(elems_5))])])))) : (empty())))), delay(() => append_1(singleton(createElement(EmailNotification, {
                                FormState: componentState,
                                OnChangeEmailNotification: props.OnChangeEmailNotification,
                                User: props.User,
                            })), delay(() => (props.IsCustomPropertiesEnabled ? singleton(createElement(NewReceiverCustomProperties, {
                                CustomProperties: props.User.CustomProperties,
                                FormState: props.FormState,
                                OnSetCustomProperty: (arg_8) => {
                                    props.Dispatch(new Msg(7, [arg_8]));
                                },
                                PropertyConfigurationType: new PropertyConfigurationVisibility(2, []),
                                PropertyConfigurations: props.PropertyConfigurations,
                            })) : empty())))));
                        }));
                    }));
                }));
            }))))))));
        })),
        Header: props.Header,
        OnBack: props.OnBack,
        OnSave: props.OnSave,
    });
}

export function NewUserView(props) {
    const patternInput = React_useElmish_Z6C327F2E(() => ProgramModule_mkProgram(initNew, update_1, (_arg, _arg_1) => {
    }), undefined, undefined);
    const state_1 = patternInput[0];
    const dispatch = patternInput[1];
    const patternInput_1 = useTranslation();
    const matchValue = state_1.Form;
    const matchValue_1 = state_1.CustomerConfiguration;
    const matchValue_2 = state_1.IsCustomPropertiesEnabled;
    const matchValue_3 = state_1.PropertyConfigurations;
    const matchValue_4 = state_1.DepartmentSuggestions;
    const matchValue_5 = state_1.Storages;
    let matchResult, configuration, departmentSuggestions, isCustomPropertiesEnabled, propertyConfigurations, storages, user;
    if (matchValue.tag === 0) {
        matchResult = 1;
    }
    else if (matchValue_1.tag === 0) {
        matchResult = 1;
    }
    else if (matchValue_2.tag === 0) {
        matchResult = 1;
    }
    else if (matchValue_3.tag === 0) {
        matchResult = 1;
    }
    else if (matchValue_4.tag === 0) {
        matchResult = 1;
    }
    else if (matchValue_5.tag === 0) {
        matchResult = 1;
    }
    else {
        matchResult = 0;
        configuration = matchValue_1.fields[0];
        departmentSuggestions = matchValue_4.fields[0];
        isCustomPropertiesEnabled = matchValue_2.fields[0];
        propertyConfigurations = matchValue_3.fields[0];
        storages = matchValue_5.fields[0];
        user = matchValue.fields[0];
    }
    switch (matchResult) {
        case 0:
            return createElement(UserForm, {
                CustomerConfiguration: configuration,
                DepartmentSuggestions: departmentSuggestions,
                Dispatch: dispatch,
                FormKind: new FormKind_1(0, []),
                FormState: state_1.FormState,
                Header: patternInput_1[0]("user.create_new_user"),
                IsCustomPropertiesEnabled: isCustomPropertiesEnabled,
                OnBack: () => {
                    RouterModule_nav(ofArray(props.OnBackPath), 1, 1);
                },
                OnChangeEmailNotification: (arg) => {
                    dispatch(new Msg(8, [arg]));
                },
                OnSave: () => {
                    dispatch(new Msg(11, []));
                },
                PropertyConfigurations: propertyConfigurations,
                SelectedStorageIds: user.SelectedStorages,
                Storages: storages,
                User: user.User,
            });
        default:
            return createElement(Skeleton, {
                Variant: "detail",
            });
    }
}

export function EditUserView(props) {
    let patternInput;
    const init = initEdit(props.UserId);
    patternInput = React_useElmish_Z6C327F2E(() => ProgramModule_mkProgram(() => init, update_1, (_arg, _arg_1) => {
    }), undefined, [props.UserId]);
    const state_1 = patternInput[0];
    const dispatch = patternInput[1];
    const patternInput_1 = useTranslation();
    const matchValue = state_1.Form;
    const matchValue_1 = state_1.CustomerConfiguration;
    const matchValue_2 = state_1.IsCustomPropertiesEnabled;
    const matchValue_3 = state_1.PropertyConfigurations;
    const matchValue_4 = state_1.DepartmentSuggestions;
    const matchValue_5 = state_1.Storages;
    let matchResult, configuration, departmentSuggestions, isCustomPropertiesEnabled, propertyConfigurations, storages, user;
    if (matchValue.tag === 0) {
        matchResult = 1;
    }
    else if (matchValue_1.tag === 0) {
        matchResult = 1;
    }
    else if (matchValue_2.tag === 0) {
        matchResult = 1;
    }
    else if (matchValue_3.tag === 0) {
        matchResult = 1;
    }
    else if (matchValue_4.tag === 0) {
        matchResult = 1;
    }
    else if (matchValue_5.tag === 0) {
        matchResult = 1;
    }
    else {
        matchResult = 0;
        configuration = matchValue_1.fields[0];
        departmentSuggestions = matchValue_4.fields[0];
        isCustomPropertiesEnabled = matchValue_2.fields[0];
        propertyConfigurations = matchValue_3.fields[0];
        storages = matchValue_5.fields[0];
        user = matchValue.fields[0];
    }
    switch (matchResult) {
        case 0:
            return createElement(UserForm, {
                CustomerConfiguration: configuration,
                DepartmentSuggestions: departmentSuggestions,
                Dispatch: dispatch,
                FormKind: new FormKind_1(1, []),
                FormState: state_1.FormState,
                Header: patternInput_1[0]("user.edit_user"),
                IsCustomPropertiesEnabled: isCustomPropertiesEnabled,
                OnBack: () => {
                    RouterModule_nav(ofArray(props.OnBackPath), 1, 1);
                },
                OnChangeEmailNotification: (arg) => {
                    dispatch(new Msg(8, [arg]));
                },
                OnSave: () => {
                    dispatch(new Msg(12, []));
                },
                PropertyConfigurations: propertyConfigurations,
                SelectedStorageIds: user.SelectedStorages,
                Storages: storages,
                User: user.User,
            });
        default:
            return createElement(Skeleton, {
                Variant: "detail",
            });
    }
}

