import { Helper_historyTypeToTranslationString, ProblemReportState, Helper_priceToString } from "../Shared/Tool.js";
import { utcNow, date as date_1, compare, toLocalTime, toString } from "../fable_modules/fable-library-js.4.19.2/Date.js";
import { createElement } from "react";
import React from "react";
import * as react from "react";
import { comparePrimitives, equals, createObj } from "../fable_modules/fable-library-js.4.19.2/Util.js";
import { isNullOrWhiteSpace, join } from "../fable_modules/fable-library-js.4.19.2/String.js";
import { reactApi } from "../fable_modules/Feliz.2.8.0/Interop.fs.js";
import { Button, TextButton } from "../Components/Button.js";
import { mapIndexed, map as map_1, tryFind, toArray, sortByDescending, empty as empty_1, take, length, singleton as singleton_1, filter, sortBy, tryHead, ofArray } from "../fable_modules/fable-library-js.4.19.2/List.js";
import { empty, singleton, append, delay, toList } from "../fable_modules/fable-library-js.4.19.2/Seq.js";
import { useTranslation } from "react-i18next";
import { map, defaultArg, unwrap } from "../fable_modules/fable-library-js.4.19.2/Option.js";
import Calendar_Warning from "../../public/assets/icons/Calendar_Warning.svg";
import { ChangeStorageDialog } from "./Tool/Dialogs/SwitchStorageDialog.js";
import { toast } from "react-hot-toast";
import { Msg } from "./Types.js";
import { ChangeReturnDateDialog } from "./Dialogs/ChangeReturnDateDialog.js";
import { ColoredDot } from "../Components/ColoredDot.js";
import { TextValue } from "../Components/Text.js";
import { RouterModule_encodeQueryString, RouterModule_encodeParts, RouterModule_nav } from "../fable_modules/Feliz.Router.4.0.0/Router.fs.js";
import { Feature, isFeatureEnabled } from "../FeatureFlip.js";
import { QuickOverviewList } from "../Widgets/QuickOverviewList.js";
import { EditReservation_EditToolReservationDialog, NewReservation_AddToolReservationDialog } from "./Dialogs/ToolReservationDialog.js";
import { AssignToolDialog } from "./Dialogs/AssignToolDialog.js";
import { UnassignDialog } from "./Dialogs/UnassignToolDialog.js";
import { ToolCreateProblemReportDialog } from "./Dialogs/ToolCreateProblemReportDialog.js";
import { parse } from "../fable_modules/fable-library-js.4.19.2/Guid.js";
import { NoticeId, SetId__get_unwrap, ToolId as ToolId_3 } from "../Shared/Shared.js";
import { OpenDialog } from "./Dialogs/AssignToolFromUserToUserDialog.js";
import { ToolStateChangeDialog } from "./Dialogs/ToolStateChangeDialog.js";
import Calendar_Add from "../../public/assets/icons/Calendar_Add.svg";
import Following from "../../public/assets/icons/Following.svg";
import Followers from "../../public/assets/icons/Followers.svg";
import Shipment_Warning from "../../public/assets/icons/Shipment_Warning.svg";
import Shipment_Check from "../../public/assets/icons/Shipment_Check.svg";
import Shipment_Delivery_Fail from "../../public/assets/icons/Shipment_Delivery_Fail.svg";
import Shipment_Search from "../../public/assets/icons/Shipment_Search.svg";
import Shipment_Time from "../../public/assets/icons/Shipment_Time.svg";
import { PropertyList } from "../Widgets/PropertyListWidget.js";
import { CustomPropertiesPropertyList } from "../Widgets/CustomPropertiesPropertyList.js";
import { QrCodeTable } from "../Widgets/Table/QrCodeTable.js";
import { ModalSheet } from "../Components/ModalSheet.js";
import { DocumentTable } from "../Widgets/Table/DocumentTable.js";
import { UploadType as UploadType_2 } from "../Widgets/Dialogs/FileUploadDialog.js";
import { DeleteToolReservationDialog } from "./Dialogs/ToolDeleteReservation.js";
import { OnTableRowClick$1, TableControl$1, ColumnDefinition$1, CellContent, Table } from "../Widgets/Table/Table.js";
import Pencil from "../../public/assets/icons/Pencil.svg";
import Recycle_Bin_1 from "../../public/assets/icons/Recycle_Bin_1.svg";
import { ConfirmDialog_ConfirmReminderDialog, DeleteReminder_OpenDialog, EditReminder_OpenDialog, NewReminder_OpenDialog } from "./Dialogs/AddReminderDialog.js";
import Check from "../../public/assets/icons/Check.svg";
import { Helper_eventToTranslationKey, Helper_displayHistoryComment } from "../Shared/Changelog.js";
import { ProblemReportDialog } from "./Dialogs/ProblemReportDialog.js";
import { ConfirmationDialog } from "../Components/Dialog.js";
import Information_Circle from "../../public/assets/icons/Information_Circle.svg";
import { NotificationArea } from "../Widgets/Notification/NotificationArea.js";
import { NotificationToolType } from "../Widgets/Notification/AcceptNotificationDialog.js";
import { ImageWidget } from "../Widgets/ImageWidget.js";
import { ChatWidget, Message as Message_1 } from "../Widgets/ChatWidget.js";
import { OpenNoticeDeleteDialog } from "./Dialogs/ToolDeleteNoticeDialog.js";
import { isVisibleForUserByToolDetailDto } from "./Common.js";
import { OpenToolDeleteDialog } from "./Dialogs/ToolDeleteDialog.js";
import { DetailViewHeader } from "../Widgets/DetailViewHeader.js";
import { Badge } from "../Components/Badge.js";
import { getQueryParams } from "../Common.js";
import { AreaAnchorMenu } from "../Widgets/AnchorMenu.js";
import { TableContainer, SectionContainer } from "../Widgets/Container.js";
import { update as update_1, initViewTool } from "./State.js";
import { React_useElmish_Z6C327F2E } from "../fable_modules/Feliz.UseElmish.2.5.0/UseElmish.fs.js";
import { ProgramModule_mkProgram } from "../fable_modules/Fable.Elmish.4.2.0/program.fs.js";
import { Skeleton } from "../Components/Skeleton.js";

export function price(p) {
    if (p == null) {
        return "";
    }
    else {
        return Helper_priceToString(p);
    }
}

export function purchaseDate(d) {
    if (d == null) {
        return "";
    }
    else {
        return toString(toLocalTime(d), "dd.MM.yyyy");
    }
}

export function Box(props) {
    let elems_2, elems_1;
    return createElement("div", createObj(ofArray([["className", join(" ", ["bg-background", "py-3", "px-4", "flex", "flex-col", "rounded"])], (elems_2 = [createElement("div", {
        className: join(" ", ["text-sm", "font-semibold"]),
        children: props.Title,
    }), createElement("div", {
        className: join(" ", ["flex", "flex-col", "divide-y", "divide-[#e9edf3]", "gap-2", "my-4", "grow"]),
        children: reactApi.Children.toArray([props.ContentElement]),
    }), createElement("div", createObj(ofArray([["className", join(" ", ["mt-4"])], (elems_1 = [createElement(TextButton, {
        ComponentState: "enabled",
        Label: props.AdditionalInformation,
        OnClick: props.OnClick,
        TestId: `tool-selectcard-${props.AdditionalInformation}-edit-button`,
        Variant: "blueButton",
    })], ["children", reactApi.Children.toArray(Array.from(elems_1))])])))], ["children", reactApi.Children.toArray(Array.from(elems_2))])])));
}

export function InformationBox(props) {
    let elems_2;
    const contextLength = (props.ContentText.length > 100) ? (`${props.ContentText.slice(undefined, 100 + 1)}[...]`) : props.ContentText;
    return createElement("div", createObj(ofArray([["className", join(" ", ["bg-white", "rounded", "drop-shadow-lg", "p-4"])], (elems_2 = toList(delay(() => {
        let matchValue, iconElement, elems_1;
        return append((matchValue = props.Icon, (matchValue != null) ? ((iconElement = matchValue, singleton(createElement("div", createObj(ofArray([["className", join(" ", ["flex", "flex-row", "justify-between"])], (elems_1 = [createElement("div", {
            className: join(" ", ["text-sm"]),
            children: contextLength,
        }), createElement("div", {
            className: join(" ", ["text-red", "h-5", "w-5"]),
            children: reactApi.Children.toArray([iconElement]),
        })], ["children", reactApi.Children.toArray(Array.from(elems_1))])])))))) : singleton(createElement("div", {
            className: join(" ", ["text-sm"]),
            children: contextLength,
        }))), delay(() => {
            const matchValue_1 = props.Title;
            if (matchValue_1 == null) {
                return empty();
            }
            else {
                const title = matchValue_1;
                return singleton(createElement("div", {
                    className: join(" ", ["text-sm", "font-semibold", "mt-3"]),
                    children: title,
                }));
            }
        }));
    })), ["children", reactApi.Children.toArray(Array.from(elems_2))])])));
}

export function NotificationsBox(props) {
    let Title_2;
    const t = useTranslation()[0];
    const problemReport = tryHead(sortBy((pr_1) => pr_1.Timestamp, filter((pr) => equals(pr.State, new ProblemReportState(0, [])), props.ProblemReports), {
        Compare: compare,
    }));
    const content = (problemReport == null) ? createElement(InformationBox, {
        ContentText: t("general.no_content"),
    }) : createElement(InformationBox, {
        ContentText: problemReport.Message,
    });
    return Box((Title_2 = t("tool.current_problem_report"), {
        AdditionalInformation: t("tool.to_problem_reports"),
        ContentElement: content,
        OnClick: () => {
            props.SetCurrentSelectedLink("problem-report-section-id");
        },
        Title: Title_2,
    }));
}

export function AppointmentBox(props) {
    let Title_2;
    const t = useTranslation()[0];
    const today = date_1(utcNow());
    const firstAppointment = tryHead(sortBy((a_1) => a_1.Date, filter((a) => !a.IsConfirmed, props.Tool.ReminderDates), {
        Compare: compare,
    }));
    let informationBox;
    if (firstAppointment == null) {
        informationBox = createElement(InformationBox, {
            ContentText: t("general.no_content"),
        });
    }
    else {
        const appointment = firstAppointment;
        informationBox = createElement(InformationBox, {
            ContentText: toString(appointment.Date, "dd.MM.yyyy"),
            Icon: unwrap((compare(date_1(appointment.Date), today) <= 0) ? Calendar_Warning() : undefined),
            Title: appointment.Title,
        });
    }
    return Box((Title_2 = t("general.next_appointment"), {
        AdditionalInformation: t("general.to_planning"),
        ContentElement: informationBox,
        OnClick: () => {
            props.SetCurrentSelectedLink("appointments-section-id");
        },
        Title: Title_2,
    }));
}

export function ReservationBox(props) {
    let Title_2;
    const t = useTranslation()[0];
    const now = date_1(utcNow());
    const nextReservation = tryHead(sortBy((r_1) => r_1.StartDate, filter((r) => (compare(r.StartDate, now) >= 0), props.Tool.Reservations), {
        Compare: compare,
    }));
    let informationBox;
    if (nextReservation == null) {
        informationBox = createElement(InformationBox, {
            ContentText: t("general.no_content"),
        });
    }
    else {
        const reservation = nextReservation;
        informationBox = createElement(InformationBox, {
            ContentText: `${toString(reservation.StartDate, "dd.MM.yyyy")} - ${toString(reservation.EndDate, "dd.MM.yyyy")}`,
            Title: reservation.ReceiverInformation.ReceiverName,
        });
    }
    return Box((Title_2 = t("tool.next_reservation"), {
        AdditionalInformation: t("general.to_planning"),
        ContentElement: informationBox,
        OnClick: () => {
            props.SetCurrentSelectedLink("reservations-section-id");
        },
        Title: Title_2,
    }));
}

export function ToolShortDetails(props) {
    let elems_12;
    const t = useTranslation()[0];
    const patternInput_1 = reactApi.useState(false);
    const setIsSwitchStorageDialogOpen = patternInput_1[1];
    const isSwitchStorageDialogOpen = patternInput_1[0];
    const patternInput_2 = reactApi.useState(false);
    const setIsChangeReturnDateDialogOpen = patternInput_2[1];
    let storageLocation;
    const matchValue = props.Tool.StorageInformation.Location;
    storageLocation = ((matchValue == null) ? "" : (`(${matchValue.Name})`));
    return createElement("div", createObj(singleton_1((elems_12 = toList(delay(() => append(singleton(createElement(ChangeStorageDialog, {
        IsOpen: isSwitchStorageDialogOpen,
        OnClose: () => {
            setIsSwitchStorageDialogOpen(false);
        },
        Storages: props.Storages,
        SuccessCallback: () => {
            toast.success(t("general.saved_successfully"));
            setIsSwitchStorageDialogOpen(false);
            props.Dispatch(new Msg(2, []));
        },
        Tool: props.Tool,
        UserData: props.UserData,
    })), delay(() => append(singleton(createElement(ChangeReturnDateDialog, {
        IsOpen: patternInput_2[0],
        OnClose: () => {
            setIsChangeReturnDateDialogOpen(false);
        },
        SuccessCallback: () => {
            toast.success(t("general.saved_successfully"));
            setIsChangeReturnDateDialogOpen(false);
            props.Dispatch(new Msg(2, []));
        },
        Tool: props.Tool,
    })), delay(() => {
        let elems_2, elems_1, elems, matchValue_1, matchValue_2;
        return append(singleton(createElement("div", createObj(ofArray([["className", join(" ", ["mb-4"])], (elems_2 = [createElement("div", {
            className: join(" ", ["text-sm", "font-semibold", "mb-2"]),
            children: t("general.state"),
        }), createElement("div", createObj(ofArray([["className", join(" ", ["flex", "flex-row", "items-center", "mb-2"])], (elems_1 = [createElement("div", createObj(ofArray([["className", join(" ", ["mr-1"])], (elems = [createElement(ColoredDot, {
            Color: (matchValue_1 = props.Tool.State, (matchValue_1 === "inMaintenance") ? "orange" : ((matchValue_1 === "retired") ? "red" : ((matchValue_1 === "lost") ? "red" : "green"))),
        })], ["children", reactApi.Children.toArray(Array.from(elems))])]))), createElement(TextValue, {
            FontWeight: "normal",
            TestId: "",
            Text: (matchValue_2 = props.Tool.State, (matchValue_2 === "inMaintenance") ? t("general.in_maintenance") : ((matchValue_2 === "retired") ? t("general.retired") : ((matchValue_2 === "lost") ? t("general.lost") : t("general.active")))),
        })], ["children", reactApi.Children.toArray(Array.from(elems_1))])])))], ["children", reactApi.Children.toArray(Array.from(elems_2))])])))), delay(() => {
            let elems_4, elems_3;
            return append(singleton(createElement("div", createObj(ofArray([["className", join(" ", ["mb-4"])], (elems_4 = [createElement("div", {
                className: join(" ", ["text-sm", "font-semibold", "mb-2"]),
                children: t("tool.assgined_to"),
            }), createElement("div", createObj(ofArray([["className", join(" ", ["flex", "flex-row", "items-center", "mb-2"])], ["data-test-id", "tool-assigned-to-test-id"], (elems_3 = toList(delay(() => {
                const matchValue_3 = props.Tool.AssignedReceiverInformation;
                if (matchValue_3 != null) {
                    const assignedReceiver = matchValue_3;
                    let receiverType;
                    const matchValue_4 = assignedReceiver.ReceiverTypeName;
                    receiverType = ((matchValue_4.tag === 1) ? t("receivers.employee") : ((matchValue_4.tag === 2) ? t("receivers.externalperson") : ((matchValue_4.tag === 3) ? t("receivers.subcontractor") : ((matchValue_4.tag === 4) ? t("receivers.room") : ((matchValue_4.tag === 5) ? t("receivers.project") : ((matchValue_4.tag === 6) ? t("receivers.constructionsite") : ((matchValue_4.tag === 7) ? t("receivers.student") : ((matchValue_4.tag === 8) ? t("receivers.school_class") : ((matchValue_4.tag === 9) ? t("receivers.vehicles") : t("receivers.user"))))))))));
                    const matchValue_5 = props.UserData.Role;
                    switch (matchValue_5) {
                        case "user":
                            return singleton(createElement(TextValue, {
                                FontWeight: "normal",
                                TestId: "",
                                Text: `${assignedReceiver.ReceiverName} (${receiverType})`,
                            }));
                        default:
                            return singleton(createElement(TextButton, {
                                ComponentState: "enabled",
                                Label: `${assignedReceiver.ReceiverName} (${receiverType})`,
                                OnClick: () => {
                                    RouterModule_nav(singleton_1(`/receivers/${assignedReceiver.AssignedReceiverId}`), 1, 1);
                                },
                                TestId: "assigned-receiver-text-button-test-id",
                                Variant: "blueButton",
                            }));
                    }
                }
                else {
                    return singleton(createElement(TextValue, {
                        FontWeight: "normal",
                        TestId: "",
                        Text: t("tool.not_assigned"),
                    }));
                }
            })), ["children", reactApi.Children.toArray(Array.from(elems_3))])])))], ["children", reactApi.Children.toArray(Array.from(elems_4))])])))), delay(() => {
                let matchValue_6, assignedReceiver_1, elems_6, elems_5, Label_1, matchValue_7, matchValue_8;
                return append((matchValue_6 = props.Tool.AssignedReceiverInformation, (matchValue_6 != null) ? ((assignedReceiver_1 = matchValue_6, singleton(createElement("div", createObj(ofArray([["className", join(" ", ["mb-4"])], (elems_6 = [createElement("div", {
                    className: join(" ", ["text-sm", "font-semibold", "mb-2"]),
                    children: t("tool.planned_return_date"),
                }), createElement("div", createObj(ofArray([["className", join(" ", ["flex", "flex-row", "items-center", "mb-2"])], ["data-test-id", "tool-planned-return-date-test-id"], (elems_5 = [createElement(TextButton, (Label_1 = ((matchValue_7 = assignedReceiver_1.PlannedReturnDate, (matchValue_7 != null) ? toString(toLocalTime(matchValue_7), "dd.MM.yyyy") : t("general.no_planned_return_date_set"))), {
                    ComponentState: (matchValue_8 = props.UserData.Role, (matchValue_8 === "toolManager") ? "enabled" : ((matchValue_8 === "administrator") ? "enabled" : ((matchValue_8 === "user") ? "disabled" : "enabled"))),
                    Label: Label_1,
                    OnClick: () => {
                        setIsChangeReturnDateDialogOpen(true);
                    },
                    TestId: "planned-return-date-receiver-text-button-test-id",
                    Variant: "blueButton",
                }))], ["children", reactApi.Children.toArray(Array.from(elems_5))])])))], ["children", reactApi.Children.toArray(Array.from(elems_6))])])))))) : (empty())), delay(() => {
                    let elems_8;
                    return append(singleton(createElement("div", createObj(ofArray([["className", join(" ", ["mb-4"])], (elems_8 = toList(delay(() => append(singleton(createElement("div", {
                        className: join(" ", ["text-sm", "font-semibold", "mb-2"]),
                        children: t("general.storage"),
                    })), delay(() => {
                        let elems_7;
                        return append(singleton(createElement("div", createObj(ofArray([["className", join(" ", ["flex", "flex-row", "items-center", "mb-2"])], (elems_7 = [createElement(TextValue, {
                            FontWeight: "normal",
                            TestId: "",
                            Text: `${props.Tool.StorageInformation.StorageName} ${storageLocation}`,
                        })], ["children", reactApi.Children.toArray(Array.from(elems_7))])])))), delay(() => {
                            const matchValue_9 = props.UserData.Role;
                            switch (matchValue_9) {
                                case "user": {
                                    return empty();
                                }
                                default: {
                                    const matchValue_10 = props.Tool.State;
                                    switch (matchValue_10) {
                                        case "lost":
                                        case "retired": {
                                            return empty();
                                        }
                                        default:
                                            return singleton(createElement(TextButton, {
                                                ComponentState: "enabled",
                                                Label: t("tool.switch_storage"),
                                                OnClick: () => {
                                                    setIsSwitchStorageDialogOpen(!isSwitchStorageDialogOpen);
                                                },
                                                TestId: "switch-storage-text-button-test-id",
                                                Variant: "blueButton",
                                            }));
                                    }
                                }
                            }
                        }));
                    })))), ["children", reactApi.Children.toArray(Array.from(elems_8))])])))), delay(() => {
                        let elems_10, elems_9;
                        return append(!isNullOrWhiteSpace(props.Tool.StorageInformation.StorageSpace) ? singleton(createElement("div", createObj(ofArray([["className", join(" ", ["mb-4"])], (elems_10 = [createElement("div", {
                            className: join(" ", ["text-sm", "font-semibold", "mb-2"]),
                            children: t("tool.storage_space"),
                        }), createElement("div", createObj(ofArray([["className", join(" ", ["flex", "flex-row", "items-center", "mb-2"])], (elems_9 = [createElement(TextValue, {
                            FontWeight: "normal",
                            TestId: "",
                            Text: props.Tool.StorageInformation.StorageSpace,
                        })], ["children", reactApi.Children.toArray(Array.from(elems_9))])])))], ["children", reactApi.Children.toArray(Array.from(elems_10))])])))) : empty(), delay(() => {
                            let elems_11;
                            return isFeatureEnabled(new Feature(3, [])) ? singleton(createElement("div", createObj(ofArray([["className", join(" ", [])], (elems_11 = [createElement(QuickOverviewList, {
                                Elements: {
                                    CommercialData: props.Tool.CommercialData,
                                    Custom: toList(delay(() => {
                                        let Label_3;
                                        return append(singleton((Label_3 = t("tool.manufacturer"), {
                                            Group: t("general.master_data"),
                                            Key: "tool-manufacturer-fav-field",
                                            Label: Label_3,
                                            Value: props.Tool.MasterData.Manufacturer,
                                        })), delay(() => {
                                            let Label_4;
                                            return append(singleton((Label_4 = t("tool.model"), {
                                                Group: t("general.master_data"),
                                                Key: "tool-model-fav-field",
                                                Label: Label_4,
                                                Value: props.Tool.MasterData.Model,
                                            })), delay(() => {
                                                let Label_5;
                                                return append(singleton((Label_5 = t("tool.serial_number"), {
                                                    Group: t("general.master_data"),
                                                    Key: "tool-serialnumber-fav-field",
                                                    Label: Label_5,
                                                    Value: props.Tool.MasterData.SerialNumber,
                                                })), delay(() => {
                                                    let Label_6;
                                                    return append(singleton((Label_6 = t("tool.displayname"), {
                                                        Group: t("general.master_data"),
                                                        Key: "tool-displayname-fav-field",
                                                        Label: Label_6,
                                                        Value: props.Tool.MasterData.DisplayName,
                                                    })), delay(() => {
                                                        let Label_7;
                                                        return append(singleton((Label_7 = t("tool.inventory_number"), {
                                                            Group: t("general.master_data"),
                                                            Key: "tool-inventorynumber-fav-field",
                                                            Label: Label_7,
                                                            Value: props.Tool.MasterData.InventoryNumber,
                                                        })), delay(() => {
                                                            let Label_8;
                                                            return append(singleton((Label_8 = t("general.comment"), {
                                                                Group: t("general.master_data"),
                                                                Key: "tool-comment-fav-field",
                                                                Label: Label_8,
                                                                Value: props.Tool.MasterData.Comment,
                                                            })), delay(() => {
                                                                let matchValue_12, Label_9;
                                                                return append((props.UserData.Customer.tag === 1) ? ((matchValue_12 = props.Tool.CustomerSpecific, (matchValue_12.tag === 0) ? (empty()) : ((matchValue_12.tag === 1) ? singleton((Label_9 = t("tool.prinzing.check_number"), {
                                                                    Group: t("general.master_data"),
                                                                    Key: "tool-prinzing-check-number-fav-field",
                                                                    Label: Label_9,
                                                                    Value: matchValue_12.fields[0].CheckNumber,
                                                                })) : (empty())))) : (empty()), delay(() => {
                                                                    let Label_10, Value_7;
                                                                    return singleton((Label_10 = t("tool.last_check"), (Value_7 = defaultArg(map((value_43) => toString(toLocalTime(value_43), "dd.MM.yyyy"), props.Tool.MasterData.LastInspectionDate), ""), {
                                                                        Group: t("general.master_data"),
                                                                        Key: "tool-last-inspection-data-fav-field",
                                                                        Label: Label_10,
                                                                        Value: Value_7,
                                                                    })));
                                                                }));
                                                            }));
                                                        }));
                                                    }));
                                                }));
                                            }));
                                        }));
                                    })),
                                    CustomProperties: props.Tool.CustomProperties,
                                },
                                IsDialogOpen: props.QuickOverviewDialog.IsOpen,
                                IsLoading: props.QuickOverviewDialog.IsLoading,
                                OnSave: (updatedVisibleKeys) => {
                                    props.Dispatch(new Msg(0, [updatedVisibleKeys]));
                                },
                                SetDialogOpen: (_arg_7) => {
                                    props.Dispatch(new Msg(1, []));
                                },
                                UserData: props.UserData,
                                VisibleKeys: props.UserConfiguration.QuickOverviewConfiguration.ToolViewVisibleFieldKeys,
                            })], ["children", reactApi.Children.toArray(Array.from(elems_11))])])))) : empty();
                        }));
                    }));
                }));
            }));
        }));
    })))))), ["children", reactApi.Children.toArray(Array.from(elems_12))]))));
}

export function ToolDetailsActions(props) {
    let elems_3;
    const t = useTranslation()[0];
    const patternInput_1 = reactApi.useState(false);
    const setIsReservationDialogOpen = patternInput_1[1];
    const isReservationDialogOpen = patternInput_1[0];
    const patternInput_2 = reactApi.useState(false);
    const setIsAssignDialogOpen = patternInput_2[1];
    const isAssignDialogOpen = patternInput_2[0];
    const patternInput_3 = reactApi.useState(false);
    const setIsUnassignDialogOpen = patternInput_3[1];
    const isUnassignDialogOpen = patternInput_3[0];
    const patternInput_4 = reactApi.useState(false);
    const setIsProblemReportOpen = patternInput_4[1];
    const isCreateProblemReportOpen = patternInput_4[0];
    const patternInput_5 = reactApi.useState(false);
    const setIsInMaintenanceStateDialogOpen = patternInput_5[1];
    const patternInput_6 = reactApi.useState(false);
    const setIsActiveStateDialogOpen = patternInput_6[1];
    const patternInput_7 = reactApi.useState(false);
    const setIsLostStateDialogOpen = patternInput_7[1];
    const patternInput_8 = reactApi.useState(false);
    const setIsRetiredStateDialogOpen = patternInput_8[1];
    const patternInput_9 = reactApi.useState(false);
    const setIsAssignFromReceiverToReceiverDialogOpen = patternInput_9[1];
    const isAssignFromReceiverToReceiverDialogOpen = patternInput_9[0];
    return createElement("div", createObj(ofArray([["className", join(" ", ["flex", "gap-7", "mb-12"])], (elems_3 = toList(delay(() => append(isReservationDialogOpen ? singleton(createElement(NewReservation_AddToolReservationDialog, {
        IsOpen: isReservationDialogOpen,
        OnClose: () => {
            setIsReservationDialogOpen(false);
        },
        SuccessCallback: () => {
            props.SetCurrentSelectedLink("reservations-section-id");
            props.Dispatch(new Msg(2, []));
        },
        Tool: props.Tool,
        UserData: props.UserData,
    })) : empty(), delay(() => append(isAssignDialogOpen ? singleton(createElement(AssignToolDialog, {
        IsOpen: isAssignDialogOpen,
        OnClose: () => {
            setIsAssignDialogOpen(false);
        },
        SuccessCallback: () => {
            props.Dispatch(new Msg(2, []));
        },
        Tool: props.Tool,
        UserId: props.UserId,
    })) : empty(), delay(() => append(isUnassignDialogOpen ? singleton(createElement(UnassignDialog, {
        IsOpen: isUnassignDialogOpen,
        OnClose: () => {
            setIsUnassignDialogOpen(false);
        },
        SuccessCallback: () => {
            props.Dispatch(new Msg(2, []));
        },
        Tool: props.Tool,
        UserId: props.UserId,
    })) : empty(), delay(() => append(isCreateProblemReportOpen ? singleton(createElement(ToolCreateProblemReportDialog, {
        IsOpen: isCreateProblemReportOpen,
        OnClose: () => {
            setIsProblemReportOpen(false);
        },
        SuccessCallback: () => {
            props.Dispatch(new Msg(2, []));
        },
        ToolId: new ToolId_3(parse(props.Tool.Id)),
        UserId: props.UserId,
    })) : empty(), delay(() => {
        let matchValue;
        return append((matchValue = props.Tool.AssignedReceiverInformation, (matchValue == null) ? (empty()) : (isAssignFromReceiverToReceiverDialogOpen ? singleton(createElement(OpenDialog, {
            AssignedReceiverId: matchValue.AssignedReceiverId,
            IsOpen: isAssignFromReceiverToReceiverDialogOpen,
            OnClose: () => {
                setIsAssignFromReceiverToReceiverDialogOpen(false);
            },
            SuccessCallback: () => {
                props.Dispatch(new Msg(2, []));
            },
            Tool: props.Tool,
        })) : empty())), delay(() => append(singleton(createElement(ToolStateChangeDialog, {
            IsOpen: patternInput_5[0],
            OnClose: () => {
                setIsInMaintenanceStateDialogOpen(false);
            },
            SuccessCallback: () => {
                props.Dispatch(new Msg(2, []));
            },
            Tool: props.Tool,
            ToolState: "inMaintenance",
        })), delay(() => append(singleton(createElement(ToolStateChangeDialog, {
            IsOpen: patternInput_6[0],
            OnClose: () => {
                setIsActiveStateDialogOpen(false);
            },
            SuccessCallback: () => {
                props.Dispatch(new Msg(2, []));
            },
            Tool: props.Tool,
            ToolState: "active",
        })), delay(() => append(singleton(createElement(ToolStateChangeDialog, {
            IsOpen: patternInput_8[0],
            OnClose: () => {
                setIsRetiredStateDialogOpen(false);
            },
            SuccessCallback: () => {
                props.Dispatch(new Msg(2, []));
            },
            Tool: props.Tool,
            ToolState: "retired",
        })), delay(() => append(singleton(createElement(ToolStateChangeDialog, {
            IsOpen: patternInput_7[0],
            OnClose: () => {
                setIsLostStateDialogOpen(false);
            },
            SuccessCallback: () => {
                props.Dispatch(new Msg(2, []));
            },
            Tool: props.Tool,
            ToolState: "lost",
        })), delay(() => {
            let elems_2, elems, elems_1;
            return singleton(createElement("div", createObj(ofArray([["className", join(" ", ["flex", "grow", "justify-between"])], (elems_2 = [createElement("div", createObj(ofArray([["className", join(" ", ["flex", "flex-row", "gap-4"])], (elems = toList(delay(() => {
                let Label;
                return append(((props.Tool.State === "active") ? true : (props.Tool.State === "inMaintenance")) ? singleton(createElement(TextButton, (Label = t("dialog.reservation.btn_title"), {
                    ComponentState: "enabled",
                    Icon: Calendar_Add(),
                    Label: Label,
                    OnClick: () => {
                        setIsReservationDialogOpen(true);
                    },
                    TestId: "tool-tooldetail-reservate-button",
                    Variant: "default",
                }))) : empty(), delay(() => {
                    let Label_3, Label_1;
                    const matchValue_1 = props.UserData.Role;
                    switch (matchValue_1) {
                        case "user": {
                            return empty();
                        }
                        default:
                            return (props.Tool.AssignedReceiverInformation == null) ? ((props.Tool.State === "active") ? singleton(createElement(TextButton, (Label_3 = t("general.assign"), {
                                ComponentState: "enabled",
                                Icon: Following(),
                                Label: Label_3,
                                OnClick: () => {
                                    setIsAssignDialogOpen(true);
                                },
                                TestId: "tool-tooldetail-assign-button",
                                Variant: "default",
                            }))) : empty()) : append(singleton(createElement(TextButton, (Label_1 = t("general.take_back"), {
                                ComponentState: "enabled",
                                Icon: Followers(),
                                Label: Label_1,
                                OnClick: () => {
                                    setIsUnassignDialogOpen(true);
                                },
                                TestId: "tool-tooldetail-unassign-button",
                                Variant: "default",
                            }))), delay(() => {
                                let Label_2;
                                return ((props.Tool.State !== "lost") ? true : (props.Tool.State !== "retired")) ? singleton(createElement(TextButton, (Label_2 = t("general.assign"), {
                                    ComponentState: "enabled",
                                    Icon: Following(),
                                    Label: Label_2,
                                    OnClick: () => {
                                        setIsAssignFromReceiverToReceiverDialogOpen(true);
                                    },
                                    TestId: "tool-tooldetail-assign-receiver-to-receiver-button",
                                    Variant: "default",
                                }))) : empty();
                            }));
                    }
                }));
            })), ["children", reactApi.Children.toArray(Array.from(elems))])]))), createElement("div", createObj(ofArray([["className", join(" ", ["flex", "flex-row", "gap-4"])], (elems_1 = toList(delay(() => {
                let Label_12, Label_7, Label_10, Label_11, Label_4;
                const matchValue_3 = props.UserData.Role;
                switch (matchValue_3) {
                    case "user":
                        return singleton(createElement(TextButton, (Label_12 = t("dialog.problem_report.btn_title"), {
                            ComponentState: "enabled",
                            Icon: Shipment_Warning(),
                            Label: Label_12,
                            OnClick: () => {
                                setIsProblemReportOpen(true);
                            },
                            TestId: "tool-create-problem-report-button",
                            Variant: "default",
                        })));
                    default: {
                        const matchValue_4 = props.Tool.State;
                        return (matchValue_4 === "inMaintenance") ? append(singleton(createElement(TextButton, (Label_7 = t("tool.state_active"), {
                            ComponentState: "enabled",
                            Icon: Shipment_Check(),
                            Label: Label_7,
                            OnClick: () => {
                                setIsActiveStateDialogOpen(true);
                            },
                            TestId: "tool-tooldetail-active-state-button",
                            Variant: "default",
                        }))), delay(() => {
                            let Label_8;
                            return append(singleton(createElement(TextButton, (Label_8 = t("general.retired"), {
                                ComponentState: "enabled",
                                Icon: Shipment_Delivery_Fail(),
                                Label: Label_8,
                                OnClick: () => {
                                    setIsRetiredStateDialogOpen(true);
                                },
                                TestId: "tool-tooldetail-retired-state-button",
                                Variant: "default",
                            }))), delay(() => {
                                let Label_9;
                                return singleton(createElement(TextButton, (Label_9 = t("general.lost"), {
                                    ComponentState: "enabled",
                                    Icon: Shipment_Search(),
                                    Label: Label_9,
                                    OnClick: () => {
                                        setIsLostStateDialogOpen(true);
                                    },
                                    TestId: "tool-tooldetail-lost-state-button",
                                    Variant: "default",
                                })));
                            }));
                        })) : ((matchValue_4 === "lost") ? singleton(createElement(TextButton, (Label_10 = t("tool.state_active"), {
                            ComponentState: "enabled",
                            Icon: Shipment_Check(),
                            Label: Label_10,
                            OnClick: () => {
                                setIsActiveStateDialogOpen(true);
                            },
                            TestId: "tool-tooldetail-active-state-button",
                            Variant: "default",
                        }))) : ((matchValue_4 === "retired") ? singleton(createElement(TextButton, (Label_11 = t("tool.state_active"), {
                            ComponentState: "enabled",
                            Icon: Shipment_Check(),
                            Label: Label_11,
                            OnClick: () => {
                                setIsActiveStateDialogOpen(true);
                            },
                            TestId: "tool-tooldetail-active-state-button",
                            Variant: "default",
                        }))) : append(singleton(createElement(TextButton, (Label_4 = t("tool.state_in_maintenance"), {
                            ComponentState: "enabled",
                            Icon: Shipment_Time(),
                            Label: Label_4,
                            OnClick: () => {
                                setIsInMaintenanceStateDialogOpen(true);
                            },
                            TestId: "tool-tooldetail-in-maintenance-state-button",
                            Variant: "default",
                        }))), delay(() => {
                            let Label_5;
                            return append(singleton(createElement(TextButton, (Label_5 = t("general.reject"), {
                                ComponentState: "enabled",
                                Icon: Shipment_Delivery_Fail(),
                                Label: Label_5,
                                OnClick: () => {
                                    setIsRetiredStateDialogOpen(true);
                                },
                                TestId: "tool-tooldetail-retired-state-button",
                                Variant: "default",
                            }))), delay(() => {
                                let Label_6;
                                return singleton(createElement(TextButton, (Label_6 = t("general.report_lost"), {
                                    ComponentState: "enabled",
                                    Icon: Shipment_Search(),
                                    Label: Label_6,
                                    OnClick: () => {
                                        setIsLostStateDialogOpen(true);
                                    },
                                    TestId: "tool-tooldetail-lost-state-button",
                                    Variant: "default",
                                })));
                            }));
                        }))));
                    }
                }
            })), ["children", reactApi.Children.toArray(Array.from(elems_1))])])))], ["children", reactApi.Children.toArray(Array.from(elems_2))])]))));
        }))))))))));
    })))))))))), ["children", reactApi.Children.toArray(Array.from(elems_3))])])));
}

function MasterDataSection(props) {
    let elems_4, elems_3, elems_1, Title, elems, elems_2, Title_2;
    const t = useTranslation()[0];
    return createElement("div", createObj(singleton_1((elems_4 = [createElement("div", createObj(ofArray([["className", join(" ", ["grid", "grid-cols-2", "gap-20", "h-30"])], (elems_3 = [createElement("div", createObj(singleton_1((elems_1 = [createElement(PropertyList, (Title = t("general.master_data"), {
        Properties: toList(delay(() => append(singleton({
            Label: t("tool.manufacturer"),
            TestId: "master-data-manufacturer",
            Value: props.Tool.MasterData.Manufacturer,
        }), delay(() => append(singleton({
            Label: t("tool.model"),
            TestId: "master-data-model",
            Value: props.Tool.MasterData.Model,
        }), delay(() => append(singleton({
            Label: t("tool.serial_number"),
            TestId: "master-data-serial-number",
            Value: props.Tool.MasterData.SerialNumber,
        }), delay(() => append(singleton({
            Label: t("tool.displayname"),
            TestId: "master-data-display-name",
            Value: props.Tool.MasterData.DisplayName,
        }), delay(() => append(singleton({
            Label: t("tool.inventory_number"),
            TestId: "master-data-inventory-number",
            Value: props.Tool.MasterData.InventoryNumber,
        }), delay(() => append(singleton({
            Label: t("general.comment"),
            TestId: "master-data-comment",
            Value: props.Tool.MasterData.Comment,
        }), delay(() => append(singleton({
            Label: t("tool.last_check"),
            TestId: "master-data-last-check",
            Value: defaultArg(map((lastInspectionDate) => toString(toLocalTime(lastInspectionDate), "dd.MM.yyyy"), props.Tool.MasterData.LastInspectionDate), ""),
        }), delay(() => {
            if (props.Customer.tag === 1) {
                const matchValue_1 = props.Tool.CustomerSpecific;
                switch (matchValue_1.tag) {
                    case 1:
                        return singleton({
                            Label: t("tool.prinzing.check_number"),
                            TestId: "master-data-prinzing-check-number",
                            Value: matchValue_1.fields[0].CheckNumber,
                        });
                    default: {
                        return empty();
                    }
                }
            }
            else {
                return empty();
            }
        })))))))))))))))),
        Title: unwrap(Title),
    })), createElement("div", createObj(ofArray([["className", join(" ", ["mt-8"])], (elems = [createElement(CustomPropertiesPropertyList, {
        CustomProperties: props.Tool.CustomProperties,
        Title: t("tool.custom_properties"),
    })], ["children", reactApi.Children.toArray(Array.from(elems))])])))], ["children", reactApi.Children.toArray(Array.from(elems_1))])))), createElement("div", createObj(singleton_1((elems_2 = [createElement(PropertyList, (Title_2 = t("tool.commercial_data"), {
        Properties: ofArray([{
            Label: t("general.supplier"),
            TestId: "commercial-data-supplier",
            Value: props.Tool.CommercialData.Supplier,
        }, {
            Label: t("general.supplier_article_number"),
            TestId: "commercial-data-supplier-article-number",
            Value: props.Tool.CommercialData.SupplierArticleNumber,
        }, {
            Label: t("general.purchase_price"),
            TestId: "commercial-data-purchase-price",
            Value: price(props.Tool.CommercialData.PurchasePrice),
        }, {
            Label: t("general.purchase_date"),
            TestId: "commercial-data-purchase-date",
            Value: purchaseDate(props.Tool.CommercialData.PurchaseDate),
        }, {
            Label: t("general.gurantee_date"),
            TestId: "commercial-data-guarantee-date",
            Value: purchaseDate(props.Tool.CommercialData.WarrantyDate),
        }, {
            Label: t("general.comment"),
            TestId: "commercial-data-comment",
            Value: props.Tool.CommercialData.Comment,
        }]),
        Title: unwrap(Title_2),
    }))], ["children", reactApi.Children.toArray(Array.from(elems_2))]))))], ["children", reactApi.Children.toArray(Array.from(elems_3))])])))], ["children", reactApi.Children.toArray(Array.from(elems_4))]))));
}

function BarcodeSection(props) {
    let elems_1;
    const patternInput = useTranslation();
    const patternInput_1 = reactApi.useState(false);
    const setIsModalOpen = patternInput_1[1];
    return createElement("div", createObj(ofArray([["className", join(" ", ["flex", "flex-col"])], (elems_1 = toList(delay(() => append(singleton(createElement(QrCodeTable, {
        OnAssignQrCode: (arg) => {
            props.Dispatch(new Msg(9, [arg]));
        },
        OnDeleteQrCodes: (arg_1) => {
            props.Dispatch(new Msg(11, [arg_1]));
        },
        QrCodes: (length(props.QRCodeIds) > 5) ? take(5, props.QRCodeIds) : props.QRCodeIds,
        UserData: props.UserData,
    })), delay(() => {
        let elems;
        return (length(props.QRCodeIds) > 5) ? singleton(createElement("div", createObj(ofArray([["className", join(" ", ["flex", "justify-center", "mt-4", "mb-4"])], (elems = [createElement(Button, {
            ComponentState: "enabled",
            Label: patternInput[0]("general.show_more_data"),
            OnClick: () => {
                setIsModalOpen(true);
            },
            TestId: "tool-selectcard-tooldetail-showmoredata-button",
            Variant: "blueButton",
        }), createElement(ModalSheet, {
            CloseButtonTestId: "",
            Content: createElement(QrCodeTable, {
                OnAssignQrCode: (arg_2) => {
                    props.Dispatch(new Msg(9, [arg_2]));
                },
                OnDeleteQrCodes: (arg_3) => {
                    props.Dispatch(new Msg(11, [arg_3]));
                },
                QrCodes: props.QRCodeIds,
                UserData: props.UserData,
            }),
            Controls: empty_1(),
            IsModalOpen: patternInput_1[0],
            OnClose: () => {
                setIsModalOpen(false);
            },
        })], ["children", reactApi.Children.toArray(Array.from(elems))])])))) : empty();
    })))), ["children", reactApi.Children.toArray(Array.from(elems_1))])])));
}

function DocumentSection(props) {
    let elems_1;
    const patternInput = useTranslation();
    const patternInput_1 = reactApi.useState(false);
    const setIsModalOpen = patternInput_1[1];
    return createElement("div", createObj(ofArray([["className", join(" ", ["flex", "flex-col"])], (elems_1 = toList(delay(() => append(singleton(createElement(DocumentTable, {
        Documents: (length(props.Documents) > 5) ? take(5, props.Documents) : props.Documents,
        OnDownloadFile: (tupledArg) => {
            props.Dispatch(new Msg(25, [tupledArg[0], tupledArg[1]]));
        },
        UploadSuccessCallback: () => {
            props.Dispatch(new Msg(24, []));
        },
        UploadType: new UploadType_2(0, [props.ToolId]),
        UserData: props.UserData,
    })), delay(() => {
        let elems;
        return (length(props.Documents) > 5) ? singleton(createElement("div", createObj(ofArray([["className", join(" ", ["flex", "justify-center", "mt-4", "mb-4"])], (elems = [createElement(Button, {
            ComponentState: "enabled",
            Label: patternInput[0]("general.show_more_data"),
            OnClick: () => {
                setIsModalOpen(true);
            },
            TestId: "tool-selectcard-tooldetail-showmoredata-button",
            Variant: "blueButton",
        }), createElement(ModalSheet, {
            CloseButtonTestId: "",
            Content: createElement(DocumentTable, {
                Documents: props.Documents,
                OnDownloadFile: (tupledArg_1) => {
                    props.Dispatch(new Msg(25, [tupledArg_1[0], tupledArg_1[1]]));
                },
                UploadSuccessCallback: () => {
                    props.Dispatch(new Msg(24, []));
                },
                UploadType: new UploadType_2(0, [props.ToolId]),
                UserData: props.UserData,
            }),
            Controls: empty_1(),
            IsModalOpen: patternInput_1[0],
            OnClose: () => {
                setIsModalOpen(false);
            },
        })], ["children", reactApi.Children.toArray(Array.from(elems))])])))) : empty();
    })))), ["children", reactApi.Children.toArray(Array.from(elems_1))])])));
}

function ReservationSection(props) {
    const t = useTranslation()[0];
    const patternInput_1 = reactApi.useState(false);
    const setIsModalOpen = patternInput_1[1];
    const patternInput_2 = reactApi.useState(false);
    const setIsReservationDialogOpen = patternInput_2[1];
    const isReservationDialogOpen = patternInput_2[0];
    const patternInput_3 = reactApi.useState(undefined);
    const setSelectedReservation = patternInput_3[1];
    const selectedReservation = patternInput_3[0];
    const patternInput_4 = reactApi.useState(undefined);
    const setSelectedDeleteReservation = patternInput_4[1];
    const selectedDeleteReservation = patternInput_4[0];
    const reservations = sortByDescending((r) => r.StartDate, props.Tool.Reservations, {
        Compare: compare,
    });
    const xs_2 = toList(delay(() => append(isReservationDialogOpen ? singleton(createElement(NewReservation_AddToolReservationDialog, {
        IsOpen: isReservationDialogOpen,
        OnClose: () => {
            setIsReservationDialogOpen(false);
        },
        SuccessCallback: () => {
            props.Dispatch(new Msg(2, []));
        },
        Tool: props.Tool,
        UserData: props.UserData,
    })) : empty(), delay(() => append((selectedReservation != null) ? singleton(createElement(EditReservation_EditToolReservationDialog, {
        IsOpen: selectedReservation != null,
        OnClose: () => {
            setSelectedReservation(undefined);
        },
        Reservation: unwrap(selectedReservation),
        SuccessCallback: () => {
            props.Dispatch(new Msg(2, []));
        },
        ToolId: new ToolId_3(parse(props.Tool.Id)),
        UserData: props.UserData,
    })) : empty(), delay(() => append((selectedDeleteReservation != null) ? singleton(createElement(DeleteToolReservationDialog, {
        IsOpen: selectedDeleteReservation != null,
        OnClose: () => {
            setSelectedDeleteReservation(undefined);
        },
        Reservation: unwrap(selectedDeleteReservation),
        SuccessCallback: () => {
            props.Dispatch(new Msg(2, []));
        },
        Tool: props.Tool,
        UserData: props.UserData,
    })) : empty(), delay(() => {
        let elems_1;
        const table = (rows) => {
            let TableRows, Options;
            return createElement(Table, (TableRows = toArray(rows), (Options = {
                BorderStyle: "row",
                CSVDownload: false,
                ConfigurableColumns: false,
                DefaultSortColumn: ["startDate", "asc"],
                GlobalSearch: false,
                RowSelection: "singleRow",
            }, {
                ColumnDefinitions: [new ColumnDefinition$1("startDate", "", t("general.start_date"), (dto) => (new CellContent(5, [dto.StartDate])), "dateRange", "date"), new ColumnDefinition$1("endDate", "", t("general.end_date"), (dto_1) => (new CellContent(5, [dto_1.EndDate])), "dateRange", "date"), new ColumnDefinition$1("receiverName", "", t("general.receiver"), (dto_2) => (new CellContent(0, [dto_2.ReceiverInformation.ReceiverName])), "none", "text"), new ColumnDefinition$1("reservationName", "", t("general.name"), (dto_3) => (new CellContent(0, [dto_3.Name])), "none", "text"), new ColumnDefinition$1("description", "", t("general.description"), (dto_4) => (new CellContent(0, [dto_4.Description])), "none", "text")],
                Controls: toList(delay(() => {
                    let matchValue;
                    return append((matchValue = props.Tool.State, (matchValue === "active") ? append(singleton(new TableControl$1(t("dialog.reservation.btn_title"), Calendar_Add(), (_arg_6) => {
                        setIsReservationDialogOpen(true);
                    }, false, false, "")), delay(() => singleton(new TableControl$1(t("general.edit"), Pencil(), (rows_1) => {
                        const selectedRow = tryHead(rows_1);
                        if (selectedRow == null) {
                        }
                        else {
                            const planning = selectedRow;
                            const matchValue_1 = props.UserData.Role;
                            switch (matchValue_1) {
                                case "user": {
                                    if (planning.ReceiverInformation.ReceiverId === props.UserData.UserId) {
                                        setSelectedReservation(planning);
                                    }
                                    break;
                                }
                                default:
                                    setSelectedReservation(planning);
                            }
                        }
                    }, true, false, "")))) : ((matchValue === "lost") ? (empty()) : ((matchValue === "retired") ? (empty()) : append(singleton(new TableControl$1(t("dialog.reservation.btn_title"), Calendar_Add(), (_arg_6) => {
                        setIsReservationDialogOpen(true);
                    }, false, false, "")), delay(() => singleton(new TableControl$1(t("general.edit"), Pencil(), (rows_1) => {
                        const selectedRow = tryHead(rows_1);
                        if (selectedRow == null) {
                        }
                        else {
                            const planning = selectedRow;
                            const matchValue_1 = props.UserData.Role;
                            switch (matchValue_1) {
                                case "user": {
                                    if (planning.ReceiverInformation.ReceiverId === props.UserData.UserId) {
                                        setSelectedReservation(planning);
                                    }
                                    break;
                                }
                                default:
                                    setSelectedReservation(planning);
                            }
                        }
                    }, true, false, ""))))))), delay(() => singleton(new TableControl$1(t("general.delete"), Recycle_Bin_1(), (rows_2) => {
                        const selectedRow_1 = tryHead(rows_2);
                        if (selectedRow_1 == null) {
                        }
                        else {
                            const planning_1 = selectedRow_1;
                            const matchValue_2 = props.UserData.Role;
                            switch (matchValue_2) {
                                case "user": {
                                    if (planning_1.ReceiverInformation.ReceiverId === props.UserData.UserId) {
                                        setSelectedDeleteReservation(planning_1);
                                    }
                                    break;
                                }
                                default:
                                    setSelectedDeleteReservation(planning_1);
                            }
                        }
                    }, true, false, ""))));
                })),
                Options: Options,
                TableRows: TableRows,
            })));
        };
        return singleton(createElement("div", createObj(ofArray([["className", join(" ", ["flex", "flex-col"])], (elems_1 = toList(delay(() => append(singleton(table((length(reservations) > 5) ? take(5, reservations) : reservations)), delay(() => {
            let elems;
            return (length(reservations) > 5) ? singleton(createElement("div", createObj(ofArray([["className", join(" ", ["flex", "justify-center", "mt-4", "mb-4"])], (elems = [createElement(Button, {
                ComponentState: "enabled",
                Label: t("general.show_more_data"),
                OnClick: () => {
                    setIsModalOpen(true);
                },
                TestId: "tool-reservations-showmoredata-button",
                Variant: "blueButton",
            }), createElement(ModalSheet, {
                CloseButtonTestId: "",
                Content: table(reservations),
                Controls: empty_1(),
                IsModalOpen: patternInput_1[0],
                OnClose: () => {
                    setIsModalOpen(false);
                },
            })], ["children", reactApi.Children.toArray(Array.from(elems))])])))) : empty();
        })))), ["children", reactApi.Children.toArray(Array.from(elems_1))])]))));
    }))))))));
    return react.createElement(react.Fragment, {}, ...xs_2);
}

function AppointmentSection(props) {
    const t = useTranslation()[0];
    const now = date_1(utcNow());
    const patternInput_1 = reactApi.useState(false);
    const setIsModalOpen = patternInput_1[1];
    const patternInput_2 = reactApi.useState(false);
    const setIsReminderDialogOpen = patternInput_2[1];
    const isReminderDialogOpen = patternInput_2[0];
    const patternInput_3 = reactApi.useState(undefined);
    const setSelectedReminderToConfirm = patternInput_3[1];
    const selectedReminderToConfirm = patternInput_3[0];
    const patternInput_4 = reactApi.useState(undefined);
    const setSelectedReminder = patternInput_4[1];
    const selectedReminder = patternInput_4[0];
    const patternInput_5 = reactApi.useState(undefined);
    const setSelectedDeleteReminder = patternInput_5[1];
    const selectedDeleteReminder = patternInput_5[0];
    const reminders = sortByDescending((r) => r.Date, props.Tool.ReminderDates, {
        Compare: compare,
    });
    const xs_3 = toList(delay(() => append(isReminderDialogOpen ? singleton(createElement(NewReminder_OpenDialog, {
        IsOpen: isReminderDialogOpen,
        OnClose: () => {
            setIsReminderDialogOpen(false);
        },
        SuccessCallback: () => {
            props.Dispatch(new Msg(2, []));
        },
        ToolId: new ToolId_3(parse(props.Tool.Id)),
    })) : empty(), delay(() => append((selectedReminder != null) ? singleton(createElement(EditReminder_OpenDialog, {
        IsOpen: selectedReminder != null,
        OnClose: () => {
            setSelectedReminder(undefined);
        },
        Reminder: unwrap(selectedReminder),
        SuccessCallback: () => {
            props.Dispatch(new Msg(2, []));
        },
        ToolId: new ToolId_3(parse(props.Tool.Id)),
    })) : empty(), delay(() => append((selectedDeleteReminder != null) ? singleton(createElement(DeleteReminder_OpenDialog, {
        IsOpen: selectedDeleteReminder != null,
        OnClose: () => {
            setSelectedDeleteReminder(undefined);
        },
        Reminder: unwrap(selectedDeleteReminder),
        SuccessCallback: () => {
            props.Dispatch(new Msg(2, []));
        },
        ToolId: new ToolId_3(parse(props.Tool.Id)),
    })) : empty(), delay(() => {
        let matchValue;
        return append((matchValue = selectedReminderToConfirm, (matchValue == null) ? (empty()) : singleton(createElement(ConfirmDialog_ConfirmReminderDialog, {
            IsOpen: selectedReminderToConfirm != null,
            OnClose: () => {
                setSelectedReminderToConfirm(undefined);
            },
            Reminder: matchValue,
            SuccessCallback: () => {
                props.Dispatch(new Msg(2, []));
            },
            ToolId: new ToolId_3(parse(props.Tool.Id)),
        }))), delay(() => {
            let elems_2;
            const table = (rows) => {
                let TableRows, Options;
                return createElement(Table, (TableRows = toArray(rows), (Options = {
                    BorderStyle: "row",
                    CSVDownload: false,
                    ConfigurableColumns: false,
                    DefaultSortColumn: ["startDate", "asc"],
                    GlobalSearch: false,
                    RowSelection: "singleRow",
                }, {
                    ColumnDefinitions: [new ColumnDefinition$1("date", "", t("general.date"), (dto) => (new CellContent(5, [dto.Date])), "dateRange", "date"), new ColumnDefinition$1("state", "", t("general.done"), (reminderDate) => {
                        let elems;
                        return reminderDate.IsConfirmed ? (new CellContent(1, [createElement(ColoredDot, {
                            Color: "green",
                        })])) : ((compare(reminderDate.Date, now) > 0) ? (new CellContent(1, [createElement(ColoredDot, {
                            Color: "orange",
                        })])) : (new CellContent(1, [createElement("div", createObj(ofArray([["className", join(" ", ["text-red", "h-5", "w-5"])], (elems = [Calendar_Warning()], ["children", reactApi.Children.toArray(Array.from(elems))])])))])));
                    }, "none", "text"), new ColumnDefinition$1("name", "", t("general.name"), (dto_1) => (new CellContent(0, [dto_1.Title])), "none", "text"), new ColumnDefinition$1("description", "", t("general.description"), (dto_2) => (new CellContent(0, [dto_2.Description])), "none", "text")],
                    Controls: toList(delay(() => {
                        let matchValue_1, matchValue_2;
                        return append((matchValue_1 = props.Tool.State, (matchValue_1 === "active") ? append((matchValue_2 = props.UserData.Role, (matchValue_2 === "system") ? singleton(new TableControl$1(t("dialog.reminder.btn_title"), Calendar_Add(), (_arg_8) => {
                            setIsReminderDialogOpen(true);
                        }, false, false, "tool-new-reminder-test-id")) : ((matchValue_2 === "toolManager") ? singleton(new TableControl$1(t("dialog.reminder.btn_title"), Calendar_Add(), (_arg_8) => {
                            setIsReminderDialogOpen(true);
                        }, false, false, "tool-new-reminder-test-id")) : ((matchValue_2 === "user") ? (empty()) : singleton(new TableControl$1(t("dialog.reminder.btn_title"), Calendar_Add(), (_arg_8) => {
                            setIsReminderDialogOpen(true);
                        }, false, false, "tool-new-reminder-test-id"))))), delay(() => append(singleton(new TableControl$1(t("general.edit"), Pencil(), (rows_1) => {
                            const selectedRow = tryHead(rows_1);
                            if (selectedRow == null) {
                            }
                            else {
                                const planning = selectedRow;
                                const matchValue_3 = props.UserData.Role;
                                switch (matchValue_3) {
                                    case "user": {
                                        break;
                                    }
                                    default:
                                        setSelectedReminder(planning);
                                }
                            }
                        }, true, false, "")), delay(() => {
                            const matchValue_4 = props.UserData.Role;
                            switch (matchValue_4) {
                                case "user": {
                                    return empty();
                                }
                                default:
                                    return singleton(new TableControl$1(t("general.mark_as_done"), Check(), (rows_2) => {
                                        const selectedRow_1 = tryHead(rows_2);
                                        if (selectedRow_1 != null) {
                                            setSelectedReminderToConfirm(selectedRow_1);
                                        }
                                    }, false, false, ""));
                            }
                        })))) : ((matchValue_1 === "lost") ? (empty()) : ((matchValue_1 === "retired") ? (empty()) : append((matchValue_2 = props.UserData.Role, (matchValue_2 === "system") ? singleton(new TableControl$1(t("dialog.reminder.btn_title"), Calendar_Add(), (_arg_8) => {
                            setIsReminderDialogOpen(true);
                        }, false, false, "tool-new-reminder-test-id")) : ((matchValue_2 === "toolManager") ? singleton(new TableControl$1(t("dialog.reminder.btn_title"), Calendar_Add(), (_arg_8) => {
                            setIsReminderDialogOpen(true);
                        }, false, false, "tool-new-reminder-test-id")) : ((matchValue_2 === "user") ? (empty()) : singleton(new TableControl$1(t("dialog.reminder.btn_title"), Calendar_Add(), (_arg_8) => {
                            setIsReminderDialogOpen(true);
                        }, false, false, "tool-new-reminder-test-id"))))), delay(() => append(singleton(new TableControl$1(t("general.edit"), Pencil(), (rows_1) => {
                            const selectedRow = tryHead(rows_1);
                            if (selectedRow == null) {
                            }
                            else {
                                const planning = selectedRow;
                                const matchValue_3 = props.UserData.Role;
                                switch (matchValue_3) {
                                    case "user": {
                                        break;
                                    }
                                    default:
                                        setSelectedReminder(planning);
                                }
                            }
                        }, true, false, "")), delay(() => {
                            const matchValue_4 = props.UserData.Role;
                            switch (matchValue_4) {
                                case "user": {
                                    return empty();
                                }
                                default:
                                    return singleton(new TableControl$1(t("general.mark_as_done"), Check(), (rows_2) => {
                                        const selectedRow_1 = tryHead(rows_2);
                                        if (selectedRow_1 != null) {
                                            setSelectedReminderToConfirm(selectedRow_1);
                                        }
                                    }, false, false, ""));
                            }
                        }))))))), delay(() => singleton(new TableControl$1(t("general.delete"), Recycle_Bin_1(), (rows_3) => {
                            const selectedRow_3 = tryHead(rows_3);
                            if (selectedRow_3 == null) {
                            }
                            else {
                                const planning_1 = selectedRow_3;
                                const matchValue_5 = props.UserData.Role;
                                switch (matchValue_5) {
                                    case "user": {
                                        break;
                                    }
                                    default:
                                        setSelectedDeleteReminder(planning_1);
                                }
                            }
                        }, true, false, ""))));
                    })),
                    Options: Options,
                    TableRows: TableRows,
                })));
            };
            return singleton(createElement("div", createObj(ofArray([["className", join(" ", ["flex", "flex-col"])], (elems_2 = toList(delay(() => append(singleton(table((length(reminders) > 5) ? take(5, reminders) : reminders)), delay(() => {
                let elems_1;
                return (length(reminders) > 5) ? singleton(createElement("div", createObj(ofArray([["className", join(" ", ["flex", "justify-center", "mt-4", "mb-4"])], (elems_1 = [createElement(Button, {
                    ComponentState: "enabled",
                    Label: t("general.show_more_data"),
                    OnClick: () => {
                        setIsModalOpen(true);
                    },
                    TestId: "tool-reminders-showmoredata-button",
                    Variant: "blueButton",
                }), createElement(ModalSheet, {
                    CloseButtonTestId: "",
                    Content: table(reminders),
                    Controls: empty_1(),
                    IsModalOpen: patternInput_1[0],
                    OnClose: () => {
                        setIsModalOpen(false);
                    },
                })], ["children", reactApi.Children.toArray(Array.from(elems_1))])])))) : empty();
            })))), ["children", reactApi.Children.toArray(Array.from(elems_2))])]))));
        }));
    }))))))));
    return react.createElement(react.Fragment, {}, ...xs_3);
}

function HistorySection(props) {
    let elems_1;
    const t = useTranslation()[0];
    const patternInput_1 = reactApi.useState(false);
    const setIsModalOpen = patternInput_1[1];
    const table = (rows) => {
        let TableRows;
        return createElement(Table, (TableRows = toArray(rows), {
            ColumnDefinitions: [new ColumnDefinition$1("timestamp", "", t("general.date"), (dto) => (new CellContent(6, [dto.Timestamp])), "none", "dateTime"), new ColumnDefinition$1("eventType", "", t("general.event"), (dto_1) => (new CellContent(0, [t(Helper_historyTypeToTranslationString(dto_1.EventType))])), "select", "text"), new ColumnDefinition$1("comment", "", t("general.comment"), (dto_2) => {
                const matchValue = dto_2.Event;
                return (matchValue == null) ? (new CellContent(0, [t(dto_2.Comment)])) : (new CellContent(0, [Helper_displayHistoryComment(t(Helper_eventToTranslationKey(matchValue)), dto_2.Comment)]));
            }, "none", "text"), new ColumnDefinition$1("receiver", "", t("general.receiver"), (dto_3) => (new CellContent(0, [defaultArg(map((receiverInformation) => receiverInformation.Name, dto_3.ReceiverInformation), "")])), "none", "text"), new ColumnDefinition$1("sender", "", t("general.sender"), (dto_4) => (new CellContent(0, [dto_4.SenderInformation.Name])), "none", "text"), new ColumnDefinition$1("signatureUrl", "", t("general.signature"), (dto_5) => {
                let matchValue_1;
                return new CellContent(4, [(matchValue_1 = dto_5.ReceiverInformation, (matchValue_1 != null) ? defaultArg(map((x) => x, matchValue_1.SignatureUrl), "") : "")]);
            }, "none", "image"), new ColumnDefinition$1("storage", "", t("general.storage"), (dto_6) => (new CellContent(0, [defaultArg(map((storage) => storage.Name, dto_6.StorageInformation), "")])), "none", "text")],
            Controls: empty_1(),
            Options: {
                BorderStyle: "row",
                CSVDownload: false,
                ConfigurableColumns: false,
                DefaultSortColumn: ["timestamp", "desc"],
                GlobalSearch: false,
                RowSelection: "disabled",
            },
            TableRows: TableRows,
        }));
    };
    return createElement("div", createObj(ofArray([["className", join(" ", ["flex", "flex-col"])], (elems_1 = toList(delay(() => append(singleton(table((length(props.HistoryRows) > 5) ? take(5, props.HistoryRows) : props.HistoryRows)), delay(() => {
        let elems;
        return (length(props.HistoryRows) > 5) ? singleton(createElement("div", createObj(ofArray([["className", join(" ", ["flex", "justify-center", "mt-4", "mb-4"])], (elems = [createElement(Button, {
            ComponentState: "enabled",
            Label: t("general.show_more_data"),
            OnClick: () => {
                setIsModalOpen(true);
            },
            TestId: "tool-selectcard-tooldetail-showmoredata-button",
            Variant: "blueButton",
        }), createElement(ModalSheet, {
            CloseButtonTestId: "",
            Content: table(props.HistoryRows),
            Controls: empty_1(),
            IsModalOpen: patternInput_1[0],
            OnClose: () => {
                setIsModalOpen(false);
            },
        })], ["children", reactApi.Children.toArray(Array.from(elems))])])))) : empty();
    })))), ["children", reactApi.Children.toArray(Array.from(elems_1))])])));
}

function ProblemReportSection(props) {
    const t = useTranslation()[0];
    const patternInput_1 = reactApi.useState(false);
    const setIsModalOpen = patternInput_1[1];
    const patternInput_2 = reactApi.useState(false);
    const setIsProblemReportOpen = patternInput_2[1];
    const patternInput_3 = reactApi.useState(undefined);
    const setSelectedProblemReport = patternInput_3[1];
    const selectedProblemReport = patternInput_3[0];
    const patternInput_4 = reactApi.useState(undefined);
    const setSelectedDeleteProblemReport = patternInput_4[1];
    const selectedDeleteProblemReport = patternInput_4[0];
    const table = (rows) => {
        let TableRows, ColumnDefinitions, Options, matchValue_1;
        return createElement(Table, (TableRows = toArray(sortByDescending((r) => equals(r.State, new ProblemReportState(0, [])), rows, {
            Compare: comparePrimitives,
        })), (ColumnDefinitions = [new ColumnDefinition$1("timestamp", "", t("tool.problem_report_registered_on"), (dto) => (new CellContent(6, [dto.Timestamp])), "none", "dateTime"), new ColumnDefinition$1("sender", "", t("tool.problem_report_reported_by"), (dto_1) => (new CellContent(0, [dto_1.Sender])), "none", "text"), new ColumnDefinition$1("comment", "", t("general.message"), (dto_2) => (new CellContent(0, [dto_2.Message])), "none", "text"), new ColumnDefinition$1("solver", "", t("tool.problem_report_solved_by"), (dto_3) => (new CellContent(0, [dto_3.Solver])), "none", "text"), new ColumnDefinition$1("state", "", t("general.state"), (dto_4) => (new CellContent(1, [(dto_4.State.tag === 0) ? createElement(ColoredDot, {
            Color: "orange",
        }) : createElement(ColoredDot, {
            Color: "green",
        })])), "none", "text")], (Options = {
            BorderStyle: "row",
            CSVDownload: false,
            ConfigurableColumns: false,
            DefaultSortColumn: ["timestamp", "desc"],
            GlobalSearch: false,
            OnRowClick: unwrap((matchValue_1 = props.UserData.Role, (matchValue_1 === "system") ? (new OnTableRowClick$1(0, [(problemReport) => {
                setSelectedProblemReport(tryFind((pr) => equals(problemReport.Id, pr.Id), props.ProblemReports));
            }])) : ((matchValue_1 === "toolManager") ? (new OnTableRowClick$1(0, [(problemReport) => {
                setSelectedProblemReport(tryFind((pr) => equals(problemReport.Id, pr.Id), props.ProblemReports));
            }])) : ((matchValue_1 === "user") ? undefined : (new OnTableRowClick$1(0, [(problemReport) => {
                setSelectedProblemReport(tryFind((pr) => equals(problemReport.Id, pr.Id), props.ProblemReports));
            }])))))),
            RowSelection: "singleRow",
        }, {
            ColumnDefinitions: ColumnDefinitions,
            Controls: toList(delay(() => {
                let matchValue_2;
                return append((matchValue_2 = props.Tool.State, (matchValue_2 === "active") ? singleton(new TableControl$1(t("dialog.problem_report.btn_title"), Shipment_Warning(), (_arg) => {
                    setIsProblemReportOpen(true);
                }, false, false, "")) : ((matchValue_2 === "lost") ? (empty()) : ((matchValue_2 === "retired") ? (empty()) : singleton(new TableControl$1(t("dialog.problem_report.btn_title"), Shipment_Warning(), (_arg) => {
                    setIsProblemReportOpen(true);
                }, false, false, ""))))), delay(() => {
                    const matchValue_3 = props.UserData.Role;
                    switch (matchValue_3) {
                        case "toolManager":
                        case "user": {
                            return empty();
                        }
                        default:
                            return singleton(new TableControl$1(t("general.delete"), Recycle_Bin_1(), (rows_1) => {
                                const selectedRow = tryHead(rows_1);
                                if (selectedRow == null) {
                                }
                                else {
                                    setSelectedDeleteProblemReport(selectedRow);
                                }
                            }, true, false, ""));
                    }
                }));
            })),
            Options: Options,
            TableRows: TableRows,
        }))));
    };
    const children = toList(delay(() => append(singleton(createElement(ToolCreateProblemReportDialog, {
        IsOpen: patternInput_2[0],
        OnClose: () => {
            setIsProblemReportOpen(false);
        },
        SuccessCallback: () => {
            props.Dispatch(new Msg(2, []));
        },
        ToolId: new ToolId_3(parse(props.Tool.Id)),
        UserId: props.UserId,
    })), delay(() => {
        let matchValue_4;
        return append((matchValue_4 = selectedProblemReport, (matchValue_4 == null) ? (empty()) : singleton(createElement(ProblemReportDialog, {
            IsOpen: selectedProblemReport != null,
            OnClose: () => {
                setSelectedProblemReport(undefined);
            },
            ProblemReportId: matchValue_4.Id,
            SuccessCallback: () => {
                props.Dispatch(new Msg(27, []));
            },
            ToolId: new ToolId_3(parse(props.Tool.Id)),
            UserData: props.UserData,
            UserId: props.UserId,
        }))), delay(() => {
            let Title;
            return append(singleton(createElement(ConfirmationDialog, (Title = t("tool.delete_problem_report"), {
                Description: t("tool.delete_problem_report_description"),
                IsOpen: selectedDeleteProblemReport != null,
                OnClose: () => {
                    setSelectedDeleteProblemReport(undefined);
                },
                OnConfirm: () => {
                    if (selectedDeleteProblemReport == null) {
                    }
                    else {
                        props.Dispatch(new Msg(5, [selectedDeleteProblemReport]));
                    }
                    setSelectedDeleteProblemReport(undefined);
                },
                Title: Title,
            }))), delay(() => {
                let elems_1;
                return singleton(createElement("div", createObj(ofArray([["className", join(" ", ["flex", "flex-col"])], (elems_1 = toList(delay(() => append(singleton(table((length(props.ProblemReports) > 5) ? take(5, props.ProblemReports) : props.ProblemReports)), delay(() => {
                    let elems;
                    return (length(props.ProblemReports) > 5) ? singleton(createElement("div", createObj(ofArray([["className", join(" ", ["flex", "justify-center", "mt-4", "mb-4"])], (elems = [createElement(Button, {
                        ComponentState: "enabled",
                        Label: t("general.show_more_data"),
                        OnClick: () => {
                            setIsModalOpen(true);
                        },
                        TestId: "tool-selectcard-tooldetail-showmoredata-button",
                        Variant: "blueButton",
                    }), createElement(ModalSheet, {
                        CloseButtonTestId: "",
                        Content: table(props.ProblemReports),
                        Controls: empty_1(),
                        IsModalOpen: patternInput_1[0],
                        OnClose: () => {
                            setIsModalOpen(false);
                        },
                    })], ["children", reactApi.Children.toArray(Array.from(elems))])])))) : empty();
                })))), ["children", reactApi.Children.toArray(Array.from(elems_1))])]))));
            }));
        }));
    }))));
    return createElement("div", {
        children: reactApi.Children.toArray(Array.from(children)),
    });
}

function SetInfo(props) {
    let elems_6, elems_1, elems, elems_5, elems_2, arg_1, elems_3, elems_4;
    const patternInput = useTranslation();
    const t = patternInput[0];
    return createElement("div", createObj(ofArray([["data-test-id", `set-info-${SetId__get_unwrap(props.SetId)}`], ["className", join(" ", ["bg-[#EFF6FF]", "min-w-85", "text-sm", "flex", "flex-row", "p-3.5", "rounded"])], (elems_6 = [createElement("div", createObj(ofArray([["className", join(" ", ["flex", "flex-col"])], (elems_1 = [createElement("div", createObj(ofArray([["className", join(" ", ["mr-3.5", "mt-0.5", "h-3.5", "w-3.5", "text-icon-blue", "stroke-2"])], (elems = [Information_Circle()], ["children", reactApi.Children.toArray(Array.from(elems))])])))], ["children", reactApi.Children.toArray(Array.from(elems_1))])]))), createElement("div", createObj(ofArray([["className", join(" ", ["flex", "flex-col", "gap-2.5"])], (elems_5 = [createElement("div", createObj(ofArray([["className", join(" ", ["text-[#284CB1]", "font-medium"])], (elems_2 = [(arg_1 = {
        name: props.Name,
    }, patternInput[1].t("tool.assigned_to_set", arg_1))], ["children", reactApi.Children.toArray(Array.from(elems_2))])]))), createElement("div", createObj(ofArray([["className", join(" ", ["text-[#315CD5]", "flex", "flex-col", "mb-5.5", "leading-5", "font-normal", "text-sm", "whitespace-pre"])], (elems_3 = [createElement("div", {
        children: [t("tool.assigned_to_set_details")],
    })], ["children", reactApi.Children.toArray(Array.from(elems_3))])]))), createElement("div", createObj(ofArray([["className", join(" ", ["text-[#284CB1]", "font-medium"])], (elems_4 = [createElement("a", {
        href: RouterModule_encodeParts(ofArray(["sets", SetId__get_unwrap(props.SetId)]), 1),
        children: t("tool.assigned_to_set_link"),
    })], ["children", reactApi.Children.toArray(Array.from(elems_4))])])))], ["children", reactApi.Children.toArray(Array.from(elems_5))])])))], ["children", reactApi.Children.toArray(Array.from(elems_6))])])));
}

export function ToolDetailsArea(props) {
    let elems_4;
    const tool = props.Tool;
    return createElement("div", createObj(ofArray([["className", join(" ", ["flex", "flex-shrink-0", "flex-col", "mb-3", "bg-white", "rounded", "px-12", "py-9"])], (elems_4 = toList(delay(() => {
        let matchValue, set$, elems;
        return append((matchValue = tool.AssignedSet, (matchValue == null) ? singleton(createElement(ToolDetailsActions, {
            Dispatch: props.Dispatch,
            IsVisibleForUser: props.IsVisibleForUser,
            SetCurrentSelectedLink: props.SetCurrentSelectedLink,
            Tool: props.Tool,
            UserData: props.UserData,
            UserId: props.UserData.UserId,
        })) : ((set$ = matchValue, singleton(createElement("div", createObj(ofArray([["className", join(" ", ["mb-8"])], (elems = [createElement(SetInfo, {
            Name: set$.DisplayName,
            SetId: set$.SetId,
        })], ["children", reactApi.Children.toArray(Array.from(elems))])]))))))), delay(() => append(singleton(createElement(NotificationArea, {
            NotificationToolType: new NotificationToolType(0, []),
            Notifications: props.Notifications,
            OnUpdate: () => {
                props.Dispatch(new Msg(2, []));
            },
            UserData: props.UserData,
        })), delay(() => {
            let elems_2, elems_1, matchValue_1;
            return append(singleton(createElement("div", createObj(ofArray([["className", join(" ", ["flex", "mb-8"])], (elems_2 = [createElement("div", createObj(ofArray([["className", join(" ", ["mr-16"])], (elems_1 = [createElement(ImageWidget, {
                CanEditImage: (matchValue_1 = props.UserData.Role, (matchValue_1 === "system") ? true : ((matchValue_1 === "toolManager") ? true : (!(matchValue_1 === "user")))),
                ImageUrl: tool.ImageUrl,
                OnDeleteImage: () => {
                    props.Dispatch(new Msg(18, []));
                },
                OnUploadImage: (file) => {
                    props.Dispatch(new Msg(17, [file]));
                },
            })], ["children", reactApi.Children.toArray(Array.from(elems_1))])]))), createElement(ToolShortDetails, {
                Dispatch: props.Dispatch,
                QuickOverviewDialog: props.QuickOverviewDialog,
                Storages: props.Storages,
                Tool: tool,
                UserConfiguration: props.UserConfiguration,
                UserData: props.UserData,
            })], ["children", reactApi.Children.toArray(Array.from(elems_2))])])))), delay(() => {
                let elems_3;
                return singleton(createElement("div", createObj(ofArray([["className", join(" ", ["grid", "grid-cols-3", "gap-5", "mt-3"])], (elems_3 = [createElement(AppointmentBox, {
                    SetCurrentSelectedLink: props.SetCurrentSelectedLink,
                    Tool: tool,
                }), createElement(ReservationBox, {
                    SetCurrentSelectedLink: props.SetCurrentSelectedLink,
                    Tool: tool,
                }), createElement(NotificationsBox, {
                    ProblemReports: props.ProblemReports,
                    SetCurrentSelectedLink: props.SetCurrentSelectedLink,
                })], ["children", reactApi.Children.toArray(Array.from(elems_3))])]))));
            }));
        }))));
    })), ["children", reactApi.Children.toArray(Array.from(elems_4))])])));
}

function NoticeSection(props) {
    let elems;
    const messages = map_1((n) => (new Message_1(new NoticeId(parse(n.Id)), n.Description, undefined, n.CreatedAt, n.CreatorInformation.Name, n.CreatorInformation.Id, n.IsVisibleForUser)), props.Tool.Notices);
    const patternInput = reactApi.useState(true);
    const setIsVisibleForUser = patternInput[1];
    const isVisibleForUser = patternInput[0];
    const patternInput_1 = reactApi.useState(undefined);
    const setSelectedNoticeIdToDelete = patternInput_1[1];
    const selectedNoticeIdToDelete = patternInput_1[0];
    return createElement("div", createObj(singleton_1((elems = toList(delay(() => {
        let matchValue;
        return append((matchValue = selectedNoticeIdToDelete, (matchValue == null) ? (empty()) : singleton(createElement(OpenNoticeDeleteDialog, {
            IsOpen: selectedNoticeIdToDelete != null,
            NoticeId: matchValue,
            OnClose: () => {
                setSelectedNoticeIdToDelete(undefined);
            },
            SuccessCallback: () => {
                props.Dispatch(new Msg(2, []));
            },
            ToolId: new ToolId_3(parse(props.Tool.Id)),
        }))), delay(() => singleton(createElement(ChatWidget, {
            CustomerConfiguration: props.CustomerConfiguration,
            MaxContentHeigth: "max-h-96",
            Messages: messages,
            OnDeleteClicked: (message_1) => {
                setSelectedNoticeIdToDelete(message_1.Id);
            },
            OnEditClicked: (message) => {
                props.Dispatch(new Msg(15, [message]));
            },
            OnSubmit: (msg) => {
                props.Dispatch(new Msg(13, [msg, isVisibleForUser]));
                setIsVisibleForUser(true);
            },
            UserData: props.UserData,
            UserId: props.UserId,
            UserVisibility: {
                IsVisibleForUserChecked: isVisibleForUser,
                OnVisibleForUserChecked: setIsVisibleForUser,
            },
        }))));
    })), ["children", reactApi.Children.toArray(Array.from(elems))]))));
}

function ToolDetail(props) {
    let elems_13, elems_12, elems, Title, SubTitle, matchValue, matchValue_1, matchValue_2, elems_1, elems_11;
    const t = useTranslation()[0];
    const patternInput_1 = reactApi.useState("overview-section-id");
    const setCurrentSelectedLink = patternInput_1[1];
    const patternInput_2 = reactApi.useState(false);
    const setIsDeleteDialogOpen = patternInput_2[1];
    const isVisibleForUser = isVisibleForUserByToolDetailDto(props.Tool, props.Storages, props.UserData);
    return createElement("div", createObj(ofArray([["className", "flex grow flex-col"], (elems_13 = [createElement(OpenToolDeleteDialog, {
        IsOpen: patternInput_2[0],
        OnClose: () => {
            setIsDeleteDialogOpen(false);
        },
        SuccessCallback: () => {
            props.Dispatch(new Msg(4, []));
        },
        ToolId: new ToolId_3(parse(props.Tool.Id)),
    }), createElement("div", createObj(ofArray([["className", join(" ", ["grid", "grid-cols-[10rem,min(calc(100vw-16rem-2px-28px-11rem),80rem)]", "print:flex", "print:flex-col"])], (elems_12 = [createElement("div", createObj(ofArray([["className", join(" ", ["col-span-2"])], (elems = [createElement(DetailViewHeader, (Title = ((props.Tool.MasterData.DisplayName !== "") ? props.Tool.MasterData.DisplayName : (`${props.Tool.MasterData.Manufacturer} ${props.Tool.MasterData.Model}`)), (SubTitle = ((props.Tool.MasterData.DisplayName !== "") ? (`${props.Tool.MasterData.Manufacturer} ${props.Tool.MasterData.Model}`) : undefined), {
        Badges: mapIndexed((badgeIndex, category) => createElement(Badge, {
            Color: category.ColorHex,
            Label: category.CategoryName,
            TestId: `${badgeIndex}-${category}-badge-id`,
        }), props.Tool.Categories),
        OnBack: () => {
            RouterModule_nav(singleton_1("tools" + RouterModule_encodeQueryString(getQueryParams(undefined))), 1, 1);
        },
        OnDelete: unwrap((props.Tool.AssignedSet == null) ? ((matchValue = props.UserData.Role, (matchValue === "system") ? (() => {
            setIsDeleteDialogOpen(true);
        }) : ((matchValue === "toolManager") ? undefined : ((matchValue === "user") ? undefined : (() => {
            setIsDeleteDialogOpen(true);
        }))))) : undefined),
        OnDuplicate: unwrap((matchValue_1 = props.UserData.Role, (matchValue_1 === "system") ? (() => {
            RouterModule_nav(ofArray(["tools", props.Tool.Id, "copy" + RouterModule_encodeQueryString(getQueryParams(undefined))]), 1, 1);
        }) : ((matchValue_1 === "toolManager") ? (() => {
            RouterModule_nav(ofArray(["tools", props.Tool.Id, "copy" + RouterModule_encodeQueryString(getQueryParams(undefined))]), 1, 1);
        }) : ((matchValue_1 === "user") ? undefined : (() => {
            RouterModule_nav(ofArray(["tools", props.Tool.Id, "copy" + RouterModule_encodeQueryString(getQueryParams(undefined))]), 1, 1);
        }))))),
        OnEdit: unwrap((matchValue_2 = props.UserData.Role, (matchValue_2 === "system") ? (() => {
            RouterModule_nav(ofArray(["tools", props.Tool.Id, "edit" + RouterModule_encodeQueryString(getQueryParams(undefined))]), 1, 1);
        }) : ((matchValue_2 === "toolManager") ? (() => {
            RouterModule_nav(ofArray(["tools", props.Tool.Id, "edit" + RouterModule_encodeQueryString(getQueryParams(undefined))]), 1, 1);
        }) : ((matchValue_2 === "user") ? undefined : (() => {
            RouterModule_nav(ofArray(["tools", props.Tool.Id, "edit" + RouterModule_encodeQueryString(getQueryParams(undefined))]), 1, 1);
        }))))),
        SubTitle: unwrap(SubTitle),
        Title: Title,
    })))], ["children", reactApi.Children.toArray(Array.from(elems))])]))), createElement("div", createObj(ofArray([["className", join(" ", ["print:hidden"])], (elems_1 = [createElement(AreaAnchorMenu, {
        Anchors: toList(delay(() => append(singleton({
            Id: "overview-section-id",
            Label: t("general.overview"),
        }), delay(() => append(singleton({
            Id: "master-data-section-id",
            Label: t("general.master_data"),
        }), delay(() => append(singleton({
            Id: "documents-section-id",
            Label: t("general.documents"),
        }), delay(() => append(singleton({
            Id: "appointments-section-id",
            Label: t("general.appointments"),
        }), delay(() => append(singleton({
            Id: "reservations-section-id",
            Label: t("navbar.reservation"),
        }), delay(() => append(singleton({
            Id: "notices-section-id",
            Label: t("notice.notices"),
        }), delay(() => {
            let matchValue_3;
            return append((matchValue_3 = props.UserData.Role, (matchValue_3 === "system") ? singleton({
                Id: "problem-report-section-id",
                Label: t("tool.reported_problems"),
            }) : ((matchValue_3 === "toolManager") ? singleton({
                Id: "problem-report-section-id",
                Label: t("tool.reported_problems"),
            }) : ((matchValue_3 === "user") ? (empty()) : singleton({
                Id: "problem-report-section-id",
                Label: t("tool.reported_problems"),
            })))), delay(() => append(singleton({
                Id: "barcodes-section-id",
                Label: t("general.qr_code"),
            }), delay(() => {
                const matchValue_4 = props.UserData.Role;
                switch (matchValue_4) {
                    case "user": {
                        return empty();
                    }
                    default:
                        return singleton({
                            Id: "history-section-id",
                            Label: t("general.history"),
                        });
                }
            }))));
        })))))))))))))),
        OnSelectAnchor: setCurrentSelectedLink,
        SelectedAnchor: patternInput_1[0],
    })], ["children", reactApi.Children.toArray(Array.from(elems_1))])]))), createElement("div", createObj(singleton_1((elems_11 = toList(delay(() => {
        let elems_2;
        return append(singleton(createElement("section", createObj(ofArray([["id", "overview-section-id"], (elems_2 = [createElement(ToolDetailsArea, {
            Dispatch: props.Dispatch,
            IsVisibleForUser: isVisibleForUser,
            Notifications: props.Notifications,
            ProblemReports: props.ProblemReports,
            QuickOverviewDialog: props.QuickOverviewDialog,
            SetCurrentSelectedLink: setCurrentSelectedLink,
            Storages: props.Storages,
            Tool: props.Tool,
            UserConfiguration: props.UserConfiguration,
            UserData: props.UserData,
        })], ["children", reactApi.Children.toArray(Array.from(elems_2))])])))), delay(() => {
            let elems_3, Title_1;
            return append(singleton(createElement("section", createObj(ofArray([["id", "master-data-section-id"], (elems_3 = [createElement(SectionContainer, (Title_1 = t("general.master_data"), {
                Content: createElement(MasterDataSection, {
                    Customer: props.UserData.Customer,
                    Tool: props.Tool,
                }),
                Title: unwrap(Title_1),
            }))], ["children", reactApi.Children.toArray(Array.from(elems_3))])])))), delay(() => {
                let elems_4, Title_2;
                return append(singleton(createElement("section", createObj(ofArray([["id", "documents-section-id"], (elems_4 = [createElement(TableContainer, (Title_2 = t("general.documents"), {
                    Content: createElement(DocumentSection, {
                        Dispatch: props.Dispatch,
                        Documents: props.Documents,
                        IsVisibleForUser: isVisibleForUser,
                        ToolId: new ToolId_3(parse(props.Tool.Id)),
                        UserData: props.UserData,
                    }),
                    Title: unwrap(Title_2),
                }))], ["children", reactApi.Children.toArray(Array.from(elems_4))])])))), delay(() => {
                    let elems_5, Title_3;
                    return append(singleton(createElement("section", createObj(ofArray([["id", "appointments-section-id"], (elems_5 = [createElement(TableContainer, (Title_3 = t("general.appointments"), {
                        Content: createElement(AppointmentSection, {
                            Dispatch: props.Dispatch,
                            Tool: props.Tool,
                            UserData: props.UserData,
                        }),
                        Title: unwrap(Title_3),
                    }))], ["children", reactApi.Children.toArray(Array.from(elems_5))])])))), delay(() => {
                        let elems_6, Title_4;
                        return append(singleton(createElement("section", createObj(ofArray([["id", "reservations-section-id"], (elems_6 = [createElement(TableContainer, (Title_4 = t("navbar.reservation"), {
                            Content: createElement(ReservationSection, {
                                Dispatch: props.Dispatch,
                                Tool: props.Tool,
                                UserData: props.UserData,
                            }),
                            Title: unwrap(Title_4),
                        }))], ["children", reactApi.Children.toArray(Array.from(elems_6))])])))), delay(() => {
                            let elems_7, Title_5;
                            return append(singleton(createElement("section", createObj(ofArray([["id", "notices-section-id"], (elems_7 = [createElement(SectionContainer, (Title_5 = t("notice.notices"), {
                                Content: createElement(NoticeSection, {
                                    CustomerConfiguration: props.CustomerConfiguration,
                                    Dispatch: props.Dispatch,
                                    Tool: props.Tool,
                                    UserData: props.UserData,
                                    UserId: props.UserData.UserId,
                                }),
                                Title: unwrap(Title_5),
                            }))], ["children", reactApi.Children.toArray(Array.from(elems_7))])])))), delay(() => {
                                let matchValue_5, elems_8, Title_6;
                                return append((matchValue_5 = props.UserData.Role, (matchValue_5 === "system") ? singleton(createElement("section", createObj(ofArray([["id", "problem-report-section-id"], (elems_8 = [createElement(TableContainer, (Title_6 = t("tool.reported_problems"), {
                                    Content: createElement(ProblemReportSection, {
                                        Dispatch: props.Dispatch,
                                        IsVisibleForUser: isVisibleForUser,
                                        ProblemReports: props.ProblemReports,
                                        Tool: props.Tool,
                                        UserData: props.UserData,
                                        UserId: props.UserData.UserId,
                                    }),
                                    Title: unwrap(Title_6),
                                }))], ["children", reactApi.Children.toArray(Array.from(elems_8))])])))) : ((matchValue_5 === "toolManager") ? singleton(createElement("section", createObj(ofArray([["id", "problem-report-section-id"], (elems_8 = [createElement(TableContainer, (Title_6 = t("tool.reported_problems"), {
                                    Content: createElement(ProblemReportSection, {
                                        Dispatch: props.Dispatch,
                                        IsVisibleForUser: isVisibleForUser,
                                        ProblemReports: props.ProblemReports,
                                        Tool: props.Tool,
                                        UserData: props.UserData,
                                        UserId: props.UserData.UserId,
                                    }),
                                    Title: unwrap(Title_6),
                                }))], ["children", reactApi.Children.toArray(Array.from(elems_8))])])))) : ((matchValue_5 === "user") ? (empty()) : singleton(createElement("section", createObj(ofArray([["id", "problem-report-section-id"], (elems_8 = [createElement(TableContainer, (Title_6 = t("tool.reported_problems"), {
                                    Content: createElement(ProblemReportSection, {
                                        Dispatch: props.Dispatch,
                                        IsVisibleForUser: isVisibleForUser,
                                        ProblemReports: props.ProblemReports,
                                        Tool: props.Tool,
                                        UserData: props.UserData,
                                        UserId: props.UserData.UserId,
                                    }),
                                    Title: unwrap(Title_6),
                                }))], ["children", reactApi.Children.toArray(Array.from(elems_8))])]))))))), delay(() => {
                                    let elems_9, Title_7;
                                    return append(singleton(createElement("section", createObj(ofArray([["id", "barcodes-section-id"], (elems_9 = [createElement(TableContainer, (Title_7 = t("general.qr_code"), {
                                        Content: createElement(BarcodeSection, {
                                            Dispatch: props.Dispatch,
                                            QRCodeIds: props.Tool.QRCodeIds,
                                            UserData: props.UserData,
                                        }),
                                        Title: unwrap(Title_7),
                                    }))], ["children", reactApi.Children.toArray(Array.from(elems_9))])])))), delay(() => {
                                        let elems_10, Title_8;
                                        const matchValue_6 = props.UserData.Role;
                                        switch (matchValue_6) {
                                            case "user": {
                                                return empty();
                                            }
                                            default:
                                                return singleton(createElement("section", createObj(ofArray([["id", "history-section-id"], (elems_10 = [createElement(TableContainer, (Title_8 = t("general.history"), {
                                                    Content: createElement(HistorySection, {
                                                        HistoryRows: props.History,
                                                    }),
                                                    Title: unwrap(Title_8),
                                                }))], ["children", reactApi.Children.toArray(Array.from(elems_10))])]))));
                                        }
                                    }));
                                }));
                            }));
                        }));
                    }));
                }));
            }));
        }));
    })), ["children", reactApi.Children.toArray(Array.from(elems_11))]))))], ["children", reactApi.Children.toArray(Array.from(elems_12))])])))], ["children", reactApi.Children.toArray(Array.from(elems_13))])])));
}

export function ViewToolView(props) {
    let patternInput;
    const init = initViewTool(props.ToolId, props.CurrentUser);
    patternInput = React_useElmish_Z6C327F2E(() => ProgramModule_mkProgram(() => init, update_1, (_arg, _arg_1) => {
    }), undefined, [props.ToolId]);
    const state_1 = patternInput[0];
    const matchValue = state_1.Tool;
    const matchValue_1 = state_1.ProblemReports;
    const matchValue_2 = state_1.UserConfiguration;
    const matchValue_3 = state_1.Documents;
    const matchValue_4 = state_1.CustomerConfiguration;
    const matchValue_5 = state_1.Storages;
    const matchValue_6 = state_1.History;
    const matchValue_7 = state_1.Notifications;
    let matchResult, customerConfiguration, documents, history, notifications, problemReports, storages, tool, userConfiguration;
    if (matchValue.tag === 0) {
        matchResult = 1;
    }
    else if (matchValue_1.tag === 0) {
        matchResult = 1;
    }
    else if (matchValue_2.tag === 0) {
        matchResult = 1;
    }
    else if (matchValue_3.tag === 0) {
        matchResult = 1;
    }
    else if (matchValue_4.tag === 0) {
        matchResult = 1;
    }
    else if (matchValue_5.tag === 0) {
        matchResult = 1;
    }
    else if (matchValue_6.tag === 0) {
        matchResult = 1;
    }
    else if (matchValue_7.tag === 0) {
        matchResult = 1;
    }
    else {
        matchResult = 0;
        customerConfiguration = matchValue_4.fields[0];
        documents = matchValue_3.fields[0];
        history = matchValue_6.fields[0];
        notifications = matchValue_7.fields[0];
        problemReports = matchValue_1.fields[0];
        storages = matchValue_5.fields[0];
        tool = matchValue.fields[0];
        userConfiguration = matchValue_2.fields[0];
    }
    switch (matchResult) {
        case 0:
            return createElement(ToolDetail, {
                CustomerConfiguration: customerConfiguration,
                Dispatch: patternInput[1],
                Documents: documents,
                History: history,
                Notifications: notifications,
                ProblemReports: problemReports,
                QuickOverviewDialog: state_1.QuickOverviewDialog,
                Storages: storages,
                Tool: tool,
                UserConfiguration: userConfiguration,
                UserData: props.CurrentUser,
            });
        default:
            return createElement(Skeleton, {
                Variant: "detail",
            });
    }
}

