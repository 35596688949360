import { Union, Record } from "../../fable_modules/fable-library-js.4.19.2/Types.js";
import { FormState__getValidation, FormState__setValidationResponse_5219762A, FormState__startLoading, FormState__setValidation, FormState_get_empty, ClientApiResponse$1_$reflection, FormState_$reflection, StocktakingListId_$reflection, StocktakingId_$reflection } from "../../Shared/Shared.js";
import { CreateStocktakingListConsumableResultDto_$reflection, CreateStocktakingListConsumableResultDto, CreateStocktakingListToolWithQuantityResultDto_$reflection, CreateStocktakingListToolWithQuantityResultDto, StocktakingResultType_$reflection } from "../../Shared/Stocktaking.js";
import { union_type, class_type, string_type, record_type, lambda_type, unit_type, int32_type, option_type } from "../../fable_modules/fable-library-js.4.19.2/Reflection.js";
import { Cmd_ofEffect, Cmd_none } from "../../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { Cmd_OfPromise_either } from "../../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "../../fable_modules/Fable.Promise.3.2.0/Promise.fs.js";
import { promise } from "../../fable_modules/Fable.Promise.3.2.0/PromiseImpl.fs.js";
import { fetchWithDecoder, addPrefix } from "../../CommunicationV2.js";
import { Types_RequestProperties } from "../../fable_modules/Fable.Fetch.2.7.0/Fetch.fs.js";
import { singleton, ofArray } from "../../fable_modules/fable-library-js.4.19.2/List.js";
import { Reader_$ctor_Z3F6BC7B1, Reader__Read_24524716 } from "../../fable_modules/Fable.Remoting.MsgPack.1.24.0/Read.fs.js";
import { ConsumableId__get_unwrap, ToolId__get_unwrap, StocktakingListId__get_unwrap, StocktakingId__get_unwrap } from "../../Shared/Shared.js";
import { Auto_generateBoxedEncoder_437914C6, toString } from "../../fable_modules/Thoth.Json.10.2.0/Encode.fs.js";
import { defaultOf } from "../../fable_modules/fable-library-js.4.19.2/Util.js";
import { Cmd_errorToast } from "../../Components/Toast.js";
import { createElement } from "react";
import React from "react";
import { useTranslation } from "react-i18next";
import { createObj } from "../../fable_modules/fable-library-js.4.19.2/Util.js";
import { NumberInput } from "../../Components/Input.js";
import { unwrap, map } from "../../fable_modules/fable-library-js.4.19.2/Option.js";
import { reactApi } from "../../fable_modules/Feliz.2.8.0/Interop.fs.js";
import { React_useElmish_Z6C327F2E } from "../../fable_modules/Feliz.UseElmish.2.5.0/UseElmish.fs.js";
import { ProgramModule_mkProgram } from "../../fable_modules/Fable.Elmish.4.2.0/program.fs.js";
import { Dialog } from "../../Components/Dialog.js";
import { TextButton } from "../../Components/Button.js";

class State extends Record {
    constructor(StocktakingId, StocktakingListId, StocktakingResultType, Quantity, FormState, OnClose) {
        super();
        this.StocktakingId = StocktakingId;
        this.StocktakingListId = StocktakingListId;
        this.StocktakingResultType = StocktakingResultType;
        this.Quantity = Quantity;
        this.FormState = FormState;
        this.OnClose = OnClose;
    }
}

function State_$reflection() {
    return record_type("Stocktaking.Dialog.ConfirmStocktakingResultDialog.State", [], State, () => [["StocktakingId", StocktakingId_$reflection()], ["StocktakingListId", StocktakingListId_$reflection()], ["StocktakingResultType", option_type(StocktakingResultType_$reflection())], ["Quantity", option_type(int32_type)], ["FormState", FormState_$reflection()], ["OnClose", lambda_type(unit_type, unit_type)]]);
}

class Msg extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["SetQuantity", "SaveStocktakingResult", "StocktakingResultSaved", "FetchError"];
    }
}

function Msg_$reflection() {
    return union_type("Stocktaking.Dialog.ConfirmStocktakingResultDialog.Msg", [], Msg, () => [[["Item", option_type(int32_type)]], [], [["Item", ClientApiResponse$1_$reflection(string_type)]], [["Item", class_type("System.Exception")]]]);
}

function init(stocktaking, stocktakingListId, stocktakingResultType, onClose) {
    return [new State(stocktaking.Id, stocktakingListId, stocktakingResultType, undefined, FormState_get_empty(), onClose), Cmd_none()];
}

function update(msg, state) {
    let dto, dto_1;
    switch (msg.tag) {
        case 1: {
            const matchValue = state.StocktakingResultType;
            const matchValue_1 = state.Quantity;
            if (matchValue == null) {
                return [state, Cmd_none()];
            }
            else {
                switch (matchValue.tag) {
                    case 1:
                        if (matchValue_1 == null) {
                            const toolId_4 = matchValue.fields[0];
                            return [new State(state.StocktakingId, state.StocktakingListId, state.StocktakingResultType, state.Quantity, FormState__setValidation(state.FormState, "quantity", "validation.input.quantity_not_empty"), state.OnClose), Cmd_none()];
                        }
                        else {
                            const quantity_1 = matchValue_1 | 0;
                            const toolId_2 = matchValue.fields[0];
                            return [new State(state.StocktakingId, state.StocktakingListId, state.StocktakingResultType, state.Quantity, FormState__startLoading(state.FormState), state.OnClose), (dto = (new CreateStocktakingListToolWithQuantityResultDto(quantity_1)), Cmd_OfPromise_either((tupledArg_1) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
                                let url_5;
                                return ((url_5 = addPrefix(tupledArg_1[0]), (refreshOn_1) => fetchWithDecoder(url_5, ofArray([new Types_RequestProperties(0, ["POST"]), new Types_RequestProperties(2, [tupledArg_1[1]])]), "application/json", (response_1) => {
                                    const pr_1 = response_1.arrayBuffer();
                                    return pr_1.then((blob_1) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob_1)), string_type));
                                }, refreshOn_1)))(true);
                            })), [`/api/stocktakings/${StocktakingId__get_unwrap(state.StocktakingId)}/lists/${StocktakingListId__get_unwrap(state.StocktakingListId)}/toolswithquantity/${ToolId__get_unwrap(toolId_2)}/result`, toString(0, Auto_generateBoxedEncoder_437914C6(CreateStocktakingListToolWithQuantityResultDto_$reflection(), undefined, undefined, undefined)(dto))], (Item_2) => (new Msg(2, [Item_2])), (Item_3) => (new Msg(3, [Item_3]))))];
                        }
                    case 2:
                        if (matchValue_1 == null) {
                            const consumableId_2 = matchValue.fields[0];
                            return [new State(state.StocktakingId, state.StocktakingListId, state.StocktakingResultType, state.Quantity, FormState__setValidation(state.FormState, "quantity", "validation.input.quantity_not_empty"), state.OnClose), Cmd_none()];
                        }
                        else {
                            const consumableId = matchValue.fields[0];
                            const quantity_2 = matchValue_1 | 0;
                            return [new State(state.StocktakingId, state.StocktakingListId, state.StocktakingResultType, state.Quantity, FormState__startLoading(state.FormState), state.OnClose), (dto_1 = (new CreateStocktakingListConsumableResultDto(quantity_2)), Cmd_OfPromise_either((tupledArg_2) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
                                let url_8;
                                return ((url_8 = addPrefix(tupledArg_2[0]), (refreshOn_2) => fetchWithDecoder(url_8, ofArray([new Types_RequestProperties(0, ["POST"]), new Types_RequestProperties(2, [tupledArg_2[1]])]), "application/json", (response_2) => {
                                    const pr_2 = response_2.arrayBuffer();
                                    return pr_2.then((blob_2) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob_2)), string_type));
                                }, refreshOn_2)))(true);
                            })), [`/api/stocktakings/${StocktakingId__get_unwrap(state.StocktakingId)}/lists/${StocktakingListId__get_unwrap(state.StocktakingListId)}/consumables/${ConsumableId__get_unwrap(consumableId)}/result`, toString(0, Auto_generateBoxedEncoder_437914C6(CreateStocktakingListConsumableResultDto_$reflection(), undefined, undefined, undefined)(dto_1))], (Item_4) => (new Msg(2, [Item_4])), (Item_5) => (new Msg(3, [Item_5]))))];
                        }
                    default: {
                        const toolId = matchValue.fields[0];
                        return [new State(state.StocktakingId, state.StocktakingListId, state.StocktakingResultType, state.Quantity, FormState__startLoading(state.FormState), state.OnClose), Cmd_OfPromise_either((tupledArg) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
                            let url_2;
                            return ((url_2 = addPrefix(tupledArg[0]), (refreshOn) => fetchWithDecoder(url_2, ofArray([new Types_RequestProperties(0, ["POST"]), new Types_RequestProperties(2, [tupledArg[1]])]), "application/json", (response) => {
                                const pr = response.arrayBuffer();
                                return pr.then((blob) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob)), string_type));
                            }, refreshOn)))(true);
                        })), [`/api/stocktakings/${StocktakingId__get_unwrap(state.StocktakingId)}/lists/${StocktakingListId__get_unwrap(state.StocktakingListId)}/tools/${ToolId__get_unwrap(toolId)}/result`, defaultOf()], (Item) => (new Msg(2, [Item])), (Item_1) => (new Msg(3, [Item_1])))];
                    }
                }
            }
        }
        case 2: {
            const response_3 = msg.fields[0];
            if (response_3.tag === 1) {
                return [new State(state.StocktakingId, state.StocktakingListId, state.StocktakingResultType, state.Quantity, FormState__setValidationResponse_5219762A(state.FormState, response_3.fields[0]), state.OnClose), Cmd_errorToast("validation.toast")];
            }
            else {
                return [new State(state.StocktakingId, state.StocktakingListId, state.StocktakingResultType, state.Quantity, FormState_get_empty(), state.OnClose), Cmd_ofEffect((_arg) => {
                    state.OnClose();
                })];
            }
        }
        case 3:
            return [state, Cmd_none()];
        default:
            return [new State(state.StocktakingId, state.StocktakingListId, state.StocktakingResultType, msg.fields[0], state.FormState, state.OnClose), Cmd_none()];
    }
}

function DialogBody(props) {
    let elems, Label, Value;
    const t = useTranslation()[0];
    return createElement("div", createObj(singleton((elems = [createElement(NumberInput, (Label = t("general.quantity"), (Value = map((value) => value, props.Quantity), {
        ComponentState: props.ComponentState,
        Label: unwrap(Label),
        OnChange: (arg_1) => {
            props.Dispatch(new Msg(0, [map((value_1) => ~~value_1, arg_1)]));
        },
        TestId: "stocktaking-result-quantity",
        ValidationMessage: unwrap(FormState__getValidation(props.FormState, t, "quantity")),
        Value: unwrap(Value),
    })))], ["children", reactApi.Children.toArray(Array.from(elems))]))));
}

export function ConfirmStocktakingResultDialog(props) {
    let Title;
    let patternInput;
    const init_1 = init(props.Stocktaking, props.StocktakingListId, props.IsOpen, props.OnClose);
    patternInput = React_useElmish_Z6C327F2E(() => ProgramModule_mkProgram(() => init_1, update, (_arg, _arg_1) => {
    }), undefined, [props.IsOpen, props.Stocktaking]);
    const state_1 = patternInput[0];
    const dispatch = patternInput[1];
    const t = useTranslation()[0];
    const componentState = state_1.FormState.IsLoading ? "disabled" : "enabled";
    return createElement(Dialog, (Title = t("stocktaking.assign_stocktaking_helper_to_list"), {
        Body: singleton(createElement(DialogBody, {
            ComponentState: componentState,
            Dispatch: dispatch,
            FormState: state_1.FormState,
            Quantity: unwrap(state_1.Quantity),
            Stocktaking: props.Stocktaking,
        })),
        Controls: ofArray([createElement(TextButton, {
            ComponentState: componentState,
            Label: t("general.cancel"),
            OnClick: props.OnClose,
            TestId: "close-select-users-dialog",
            Variant: "blueButton",
        }), createElement(TextButton, {
            ComponentState: componentState,
            Label: t("general.save"),
            OnClick: () => {
                dispatch(new Msg(1, []));
            },
            TestId: "save-selected-users",
            Variant: "blueButton",
        })]),
        IsOpen: props.IsOpen != null,
        OnClose: props.OnClose,
        Title: Title,
    }));
}

