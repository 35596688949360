import { createElement } from "react";
import React from "react";
import { useTranslation } from "react-i18next";
import { empty, ofArray, singleton, toArray, contains, filter } from "../../fable_modules/fable-library-js.4.19.2/List.js";
import { createObj, stringHash } from "../../fable_modules/fable-library-js.4.19.2/Util.js";
import { ModalSheet } from "../../Components/ModalSheet.js";
import { join } from "../../fable_modules/fable-library-js.4.19.2/String.js";
import { TableControl$1, ColumnDefinition$1, CellContent, Table } from "../../Widgets/Table/Table.js";
import Followers from "../../../public/assets/icons/Followers.svg";
import { reactApi } from "../../fable_modules/Feliz.2.8.0/Interop.fs.js";

export function AddStocktakingUserModal(props) {
    let elems, TableRows, Options;
    const t = useTranslation()[0];
    const users = filter((user) => !contains(user.Id, props.SelectedUserIds, {
        Equals: (x, y) => (x === y),
        GetHashCode: stringHash,
    }), props.Users);
    return createElement(ModalSheet, {
        CloseButtonTestId: "close-stocktaking-modal-button",
        Content: createElement("div", createObj(ofArray([["className", join(" ", ["p-4"])], (elems = [createElement(Table, (TableRows = toArray(users), (Options = {
            BorderStyle: "full",
            CSVDownload: false,
            ConfigurableColumns: false,
            DefaultSortColumn: ["name", "asc"],
            GlobalSearch: false,
            RowSelection: "multiRow",
        }, {
            ColumnDefinitions: [new ColumnDefinition$1("name", "", t("general.name"), (row) => (new CellContent(0, [row.Name])), "text", "text"), new ColumnDefinition$1("department", "", t("user.department"), (row_1) => (new CellContent(0, [row_1.Department])), "select", "text")],
            Controls: singleton(new TableControl$1(t("stocktaking.add"), Followers(), props.OnSave, false, false, "stocktaking-add-button")),
            Options: Options,
            TableRows: TableRows,
        })))], ["children", reactApi.Children.toArray(Array.from(elems))])]))),
        Controls: empty(),
        IsModalOpen: props.IsOpen,
        OnClose: props.OnClose,
    });
}

