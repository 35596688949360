import { Union, Record } from "../fable_modules/fable-library-js.4.19.2/Types.js";
import { bool_type, class_type, union_type, float64_type, int32_type, record_type, list_type, option_type, string_type } from "../fable_modules/fable-library-js.4.19.2/Reflection.js";
import { PropertyConfigurationId, StorageId_$reflection, NotificationToken_$reflection, LocationId_$reflection, DispolistId_$reflection, DispolistSnapshotId_$reflection, LoginInformation_$reflection, UserTentantConfiguration_$reflection } from "./Shared.js";
import { PropertyConfigurationVisibility, Helper_mapCustomProperties, CustomPropertyValueDto_$reflection, CustomProperty_$reflection } from "./PropertyConfiguration.js";
import { empty, map, filter } from "../fable_modules/fable-library-js.4.19.2/List.js";
import { compare, equals } from "../fable_modules/fable-library-js.4.19.2/Util.js";
import { ofSeq } from "../fable_modules/fable-library-js.4.19.2/Map.js";
import { parse } from "../fable_modules/fable-library-js.4.19.2/Guid.js";
import { defaultArg } from "../fable_modules/fable-library-js.4.19.2/Option.js";
import { printf, toText } from "../fable_modules/fable-library-js.4.19.2/String.js";

export class FirebaseAuth extends Record {
    constructor(Id, KeycloakId, Customer, Tenants, LoginInformation, Role) {
        super();
        this.Id = Id;
        this.KeycloakId = KeycloakId;
        this.Customer = Customer;
        this.Tenants = Tenants;
        this.LoginInformation = LoginInformation;
        this.Role = Role;
    }
}

export function FirebaseAuth_$reflection() {
    return record_type("Shared.User.FirebaseAuth", [], FirebaseAuth, () => [["Id", string_type], ["KeycloakId", option_type(string_type)], ["Customer", string_type], ["Tenants", option_type(list_type(UserTentantConfiguration_$reflection()))], ["LoginInformation", LoginInformation_$reflection()], ["Role", string_type]]);
}

export class DispolistToolSnapshot extends Record {
    constructor(ToolId, StorageId) {
        super();
        this.ToolId = ToolId;
        this.StorageId = StorageId;
    }
}

export function DispolistToolSnapshot_$reflection() {
    return record_type("Shared.User.DispolistToolSnapshot", [], DispolistToolSnapshot, () => [["ToolId", string_type], ["StorageId", string_type]]);
}

export class DispolistToolWithQuantitySnapshot extends Record {
    constructor(ToolId, Quantity, StorageId) {
        super();
        this.ToolId = ToolId;
        this.Quantity = (Quantity | 0);
        this.StorageId = StorageId;
    }
}

export function DispolistToolWithQuantitySnapshot_$reflection() {
    return record_type("Shared.User.DispolistToolWithQuantitySnapshot", [], DispolistToolWithQuantitySnapshot, () => [["ToolId", string_type], ["Quantity", int32_type], ["StorageId", string_type]]);
}

export class DispolistConsumableSnapshot extends Record {
    constructor(ConsumableId, Quantity, StorageId) {
        super();
        this.ConsumableId = ConsumableId;
        this.Quantity = Quantity;
        this.StorageId = StorageId;
    }
}

export function DispolistConsumableSnapshot_$reflection() {
    return record_type("Shared.User.DispolistConsumableSnapshot", [], DispolistConsumableSnapshot, () => [["ConsumableId", string_type], ["Quantity", float64_type], ["StorageId", string_type]]);
}

export class DispolistAssignedElementSnapshot extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["DispolistTool", "DispolistToolWithQuantity", "DispolistConsumable"];
    }
}

export function DispolistAssignedElementSnapshot_$reflection() {
    return union_type("Shared.User.DispolistAssignedElementSnapshot", [], DispolistAssignedElementSnapshot, () => [[["Item", DispolistToolSnapshot_$reflection()]], [["Item", DispolistToolWithQuantitySnapshot_$reflection()]], [["Item", DispolistConsumableSnapshot_$reflection()]]]);
}

export class DispolistSnapshot extends Record {
    constructor(Id, AssignDate, DispolistId, ElementsSnapshot) {
        super();
        this.Id = Id;
        this.AssignDate = AssignDate;
        this.DispolistId = DispolistId;
        this.ElementsSnapshot = ElementsSnapshot;
    }
}

export function DispolistSnapshot_$reflection() {
    return record_type("Shared.User.DispolistSnapshot", [], DispolistSnapshot, () => [["Id", DispolistSnapshotId_$reflection()], ["AssignDate", class_type("System.DateTime")], ["DispolistId", DispolistId_$reflection()], ["ElementsSnapshot", list_type(DispolistAssignedElementSnapshot_$reflection())]]);
}

export class StorageToUserDispolistAssignment extends Record {
    constructor(SenderId, Sender, Comment$, DispolistSnapshotId, Timestamp) {
        super();
        this.SenderId = SenderId;
        this.Sender = Sender;
        this.Comment = Comment$;
        this.DispolistSnapshotId = DispolistSnapshotId;
        this.Timestamp = Timestamp;
    }
}

export function StorageToUserDispolistAssignment_$reflection() {
    return record_type("Shared.User.StorageToUserDispolistAssignment", [], StorageToUserDispolistAssignment, () => [["SenderId", string_type], ["Sender", string_type], ["Comment", string_type], ["DispolistSnapshotId", DispolistSnapshotId_$reflection()], ["Timestamp", class_type("System.DateTime")]]);
}

export class UserEvent extends Union {
    constructor(Item) {
        super();
        this.tag = 0;
        this.fields = [Item];
    }
    cases() {
        return ["StorageToUserDispolistAssignment"];
    }
}

export function UserEvent_$reflection() {
    return union_type("Shared.User.UserEvent", [], UserEvent, () => [[["Item", StorageToUserDispolistAssignment_$reflection()]]]);
}

export class LanguageKey extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["En", "De", "Fr", "It", "Uk", "Pl"];
    }
}

export function LanguageKey_$reflection() {
    return union_type("Shared.User.LanguageKey", [], LanguageKey, () => [[], [], [], [], [], []]);
}

export class AllowUserToSubcontractorTransfer extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Allowed", "NotAllowed"];
    }
}

export function AllowUserToSubcontractorTransfer_$reflection() {
    return union_type("Shared.User.AllowUserToSubcontractorTransfer", [], AllowUserToSubcontractorTransfer, () => [[], []]);
}

export class TableConfiguration extends Record {
    constructor(ColumnOrder, VisibleColumns) {
        super();
        this.ColumnOrder = ColumnOrder;
        this.VisibleColumns = VisibleColumns;
    }
}

export function TableConfiguration_$reflection() {
    return record_type("Shared.User.TableConfiguration", [], TableConfiguration, () => [["ColumnOrder", list_type(string_type)], ["VisibleColumns", list_type(string_type)]]);
}

export class QuickOverviewConfiguration extends Record {
    constructor(ConsumableViewVisibleFieldKeys, ToolWithQuantityViewVisibleFieldKeys, ToolViewVisibleFieldKeys) {
        super();
        this.ConsumableViewVisibleFieldKeys = ConsumableViewVisibleFieldKeys;
        this.ToolWithQuantityViewVisibleFieldKeys = ToolWithQuantityViewVisibleFieldKeys;
        this.ToolViewVisibleFieldKeys = ToolViewVisibleFieldKeys;
    }
}

export function QuickOverviewConfiguration_$reflection() {
    return record_type("Shared.User.QuickOverviewConfiguration", [], QuickOverviewConfiguration, () => [["ConsumableViewVisibleFieldKeys", list_type(string_type)], ["ToolWithQuantityViewVisibleFieldKeys", list_type(string_type)], ["ToolViewVisibleFieldKeys", list_type(string_type)]]);
}

export class UserConfiguration extends Record {
    constructor(TableConfiguration, QuickOverviewConfiguration) {
        super();
        this.TableConfiguration = TableConfiguration;
        this.QuickOverviewConfiguration = QuickOverviewConfiguration;
    }
}

export function UserConfiguration_$reflection() {
    return record_type("Shared.User.UserConfiguration", [], UserConfiguration, () => [["TableConfiguration", class_type("Microsoft.FSharp.Collections.FSharpMap`2", [string_type, TableConfiguration_$reflection()])], ["QuickOverviewConfiguration", QuickOverviewConfiguration_$reflection()]]);
}

export class ActivatedEmailNotification extends Record {
    constructor(ToolReminder, ConsumableMinimumStock, ToolWithQuantityMinimumStock, ProblemReport, ToolReservation) {
        super();
        this.ToolReminder = ToolReminder;
        this.ConsumableMinimumStock = ConsumableMinimumStock;
        this.ToolWithQuantityMinimumStock = ToolWithQuantityMinimumStock;
        this.ProblemReport = ProblemReport;
        this.ToolReservation = ToolReservation;
    }
}

export function ActivatedEmailNotification_$reflection() {
    return record_type("Shared.User.ActivatedEmailNotification", [], ActivatedEmailNotification, () => [["ToolReminder", bool_type], ["ConsumableMinimumStock", bool_type], ["ToolWithQuantityMinimumStock", bool_type], ["ProblemReport", bool_type], ["ToolReservation", bool_type]]);
}

export class User extends Record {
    constructor(Id, ImageUrl, Name, PersonnelNumber, Events, QRCodeIds, LoginInformation, LocationId, Role, IsDeleted, NotificationToken, ShownWelcomeTourSteps, AllowUserToSubcontractorTransfer, Department, Language, ActivatedEmailNotification, UserConfiguration, IsArchived, CustomProperties) {
        super();
        this.Id = Id;
        this.ImageUrl = ImageUrl;
        this.Name = Name;
        this.PersonnelNumber = PersonnelNumber;
        this.Events = Events;
        this.QRCodeIds = QRCodeIds;
        this.LoginInformation = LoginInformation;
        this.LocationId = LocationId;
        this.Role = Role;
        this.IsDeleted = IsDeleted;
        this.NotificationToken = NotificationToken;
        this.ShownWelcomeTourSteps = ShownWelcomeTourSteps;
        this.AllowUserToSubcontractorTransfer = AllowUserToSubcontractorTransfer;
        this.Department = Department;
        this.Language = Language;
        this.ActivatedEmailNotification = ActivatedEmailNotification;
        this.UserConfiguration = UserConfiguration;
        this.IsArchived = IsArchived;
        this.CustomProperties = CustomProperties;
    }
}

export function User_$reflection() {
    return record_type("Shared.User.User", [], User, () => [["Id", string_type], ["ImageUrl", string_type], ["Name", string_type], ["PersonnelNumber", string_type], ["Events", list_type(UserEvent_$reflection())], ["QRCodeIds", list_type(string_type)], ["LoginInformation", LoginInformation_$reflection()], ["LocationId", option_type(LocationId_$reflection())], ["Role", string_type], ["IsDeleted", bool_type], ["NotificationToken", NotificationToken_$reflection()], ["ShownWelcomeTourSteps", list_type(string_type)], ["AllowUserToSubcontractorTransfer", AllowUserToSubcontractorTransfer_$reflection()], ["Department", string_type], ["Language", LanguageKey_$reflection()], ["ActivatedEmailNotification", ActivatedEmailNotification_$reflection()], ["UserConfiguration", UserConfiguration_$reflection()], ["IsArchived", bool_type], ["CustomProperties", list_type(CustomProperty_$reflection())]]);
}

export class Employee extends Record {
    constructor(Id, ImageUrl, Name, PersonnelNumber, LocationId, QRCodeIds, IsDeleted, Events, Department, CreatedAt, IsArchived, Email, CustomProperties) {
        super();
        this.Id = Id;
        this.ImageUrl = ImageUrl;
        this.Name = Name;
        this.PersonnelNumber = PersonnelNumber;
        this.LocationId = LocationId;
        this.QRCodeIds = QRCodeIds;
        this.IsDeleted = IsDeleted;
        this.Events = Events;
        this.Department = Department;
        this.CreatedAt = CreatedAt;
        this.IsArchived = IsArchived;
        this.Email = Email;
        this.CustomProperties = CustomProperties;
    }
}

export function Employee_$reflection() {
    return record_type("Shared.User.Employee", [], Employee, () => [["Id", string_type], ["ImageUrl", string_type], ["Name", string_type], ["PersonnelNumber", string_type], ["LocationId", option_type(LocationId_$reflection())], ["QRCodeIds", list_type(string_type)], ["IsDeleted", bool_type], ["Events", list_type(UserEvent_$reflection())], ["Department", string_type], ["CreatedAt", class_type("System.DateTime")], ["IsArchived", bool_type], ["Email", option_type(string_type)], ["CustomProperties", list_type(CustomProperty_$reflection())]]);
}

export class Subcontractor extends Record {
    constructor(Id, Name, ImageUrl, QRCodeIds, IsDeleted, Events, CreatedAt, IsArchived, Email, CustomProperties) {
        super();
        this.Id = Id;
        this.Name = Name;
        this.ImageUrl = ImageUrl;
        this.QRCodeIds = QRCodeIds;
        this.IsDeleted = IsDeleted;
        this.Events = Events;
        this.CreatedAt = CreatedAt;
        this.IsArchived = IsArchived;
        this.Email = Email;
        this.CustomProperties = CustomProperties;
    }
}

export function Subcontractor_$reflection() {
    return record_type("Shared.User.Subcontractor", [], Subcontractor, () => [["Id", string_type], ["Name", string_type], ["ImageUrl", string_type], ["QRCodeIds", list_type(string_type)], ["IsDeleted", bool_type], ["Events", list_type(UserEvent_$reflection())], ["CreatedAt", class_type("System.DateTime")], ["IsArchived", bool_type], ["Email", option_type(string_type)], ["CustomProperties", list_type(CustomProperty_$reflection())]]);
}

export class ExternalPerson extends Record {
    constructor(Id, Name, ImageUrl, QRCodeIds, IsDeleted, Events, CreatedAt, IsArchived, Email, CustomProperties) {
        super();
        this.Id = Id;
        this.Name = Name;
        this.ImageUrl = ImageUrl;
        this.QRCodeIds = QRCodeIds;
        this.IsDeleted = IsDeleted;
        this.Events = Events;
        this.CreatedAt = CreatedAt;
        this.IsArchived = IsArchived;
        this.Email = Email;
        this.CustomProperties = CustomProperties;
    }
}

export function ExternalPerson_$reflection() {
    return record_type("Shared.User.ExternalPerson", [], ExternalPerson, () => [["Id", string_type], ["Name", string_type], ["ImageUrl", string_type], ["QRCodeIds", list_type(string_type)], ["IsDeleted", bool_type], ["Events", list_type(UserEvent_$reflection())], ["CreatedAt", class_type("System.DateTime")], ["IsArchived", bool_type], ["Email", option_type(string_type)], ["CustomProperties", list_type(CustomProperty_$reflection())]]);
}

export class Room extends Record {
    constructor(Id, Name, Number$, LocationId, ImageUrl, QRCodeIds, IsDeleted, Events, CreatedAt, IsArchived, ResponsibleUserIds, VisibleForUserIds, Email, CustomProperties) {
        super();
        this.Id = Id;
        this.Name = Name;
        this.Number = Number$;
        this.LocationId = LocationId;
        this.ImageUrl = ImageUrl;
        this.QRCodeIds = QRCodeIds;
        this.IsDeleted = IsDeleted;
        this.Events = Events;
        this.CreatedAt = CreatedAt;
        this.IsArchived = IsArchived;
        this.ResponsibleUserIds = ResponsibleUserIds;
        this.VisibleForUserIds = VisibleForUserIds;
        this.Email = Email;
        this.CustomProperties = CustomProperties;
    }
}

export function Room_$reflection() {
    return record_type("Shared.User.Room", [], Room, () => [["Id", string_type], ["Name", string_type], ["Number", string_type], ["LocationId", option_type(LocationId_$reflection())], ["ImageUrl", string_type], ["QRCodeIds", list_type(string_type)], ["IsDeleted", bool_type], ["Events", list_type(UserEvent_$reflection())], ["CreatedAt", class_type("System.DateTime")], ["IsArchived", bool_type], ["ResponsibleUserIds", list_type(string_type)], ["VisibleForUserIds", list_type(string_type)], ["Email", option_type(string_type)], ["CustomProperties", list_type(CustomProperty_$reflection())]]);
}

export class Project extends Record {
    constructor(Id, Name, Description, ImageUrl, QRCodeIds, IsDeleted, Events, CreatedAt, IsArchived, ResponsibleUserIds, VisibleForUserIds, Email, CustomProperties) {
        super();
        this.Id = Id;
        this.Name = Name;
        this.Description = Description;
        this.ImageUrl = ImageUrl;
        this.QRCodeIds = QRCodeIds;
        this.IsDeleted = IsDeleted;
        this.Events = Events;
        this.CreatedAt = CreatedAt;
        this.IsArchived = IsArchived;
        this.ResponsibleUserIds = ResponsibleUserIds;
        this.VisibleForUserIds = VisibleForUserIds;
        this.Email = Email;
        this.CustomProperties = CustomProperties;
    }
}

export function Project_$reflection() {
    return record_type("Shared.User.Project", [], Project, () => [["Id", string_type], ["Name", string_type], ["Description", string_type], ["ImageUrl", string_type], ["QRCodeIds", list_type(string_type)], ["IsDeleted", bool_type], ["Events", list_type(UserEvent_$reflection())], ["CreatedAt", class_type("System.DateTime")], ["IsArchived", bool_type], ["ResponsibleUserIds", list_type(string_type)], ["VisibleForUserIds", list_type(string_type)], ["Email", option_type(string_type)], ["CustomProperties", list_type(CustomProperty_$reflection())]]);
}

export class ConstructionSite extends Record {
    constructor(Id, Name, Description, ImageUrl, QRCodeIds, IsDeleted, Events, CreatedAt, IsArchived, ResponsibleUserIds, VisibleForUserIds, Email, CustomProperties) {
        super();
        this.Id = Id;
        this.Name = Name;
        this.Description = Description;
        this.ImageUrl = ImageUrl;
        this.QRCodeIds = QRCodeIds;
        this.IsDeleted = IsDeleted;
        this.Events = Events;
        this.CreatedAt = CreatedAt;
        this.IsArchived = IsArchived;
        this.ResponsibleUserIds = ResponsibleUserIds;
        this.VisibleForUserIds = VisibleForUserIds;
        this.Email = Email;
        this.CustomProperties = CustomProperties;
    }
}

export function ConstructionSite_$reflection() {
    return record_type("Shared.User.ConstructionSite", [], ConstructionSite, () => [["Id", string_type], ["Name", string_type], ["Description", string_type], ["ImageUrl", string_type], ["QRCodeIds", list_type(string_type)], ["IsDeleted", bool_type], ["Events", list_type(UserEvent_$reflection())], ["CreatedAt", class_type("System.DateTime")], ["IsArchived", bool_type], ["ResponsibleUserIds", list_type(string_type)], ["VisibleForUserIds", list_type(string_type)], ["Email", option_type(string_type)], ["CustomProperties", list_type(CustomProperty_$reflection())]]);
}

export class Student extends Record {
    constructor(Id, Name, StudentNumber, ImageUrl, QRCodeIds, IsDeleted, Events, CreatedAt, IsArchived, Email, CustomProperties) {
        super();
        this.Id = Id;
        this.Name = Name;
        this.StudentNumber = StudentNumber;
        this.ImageUrl = ImageUrl;
        this.QRCodeIds = QRCodeIds;
        this.IsDeleted = IsDeleted;
        this.Events = Events;
        this.CreatedAt = CreatedAt;
        this.IsArchived = IsArchived;
        this.Email = Email;
        this.CustomProperties = CustomProperties;
    }
}

export function Student_$reflection() {
    return record_type("Shared.User.Student", [], Student, () => [["Id", string_type], ["Name", string_type], ["StudentNumber", string_type], ["ImageUrl", string_type], ["QRCodeIds", list_type(string_type)], ["IsDeleted", bool_type], ["Events", list_type(UserEvent_$reflection())], ["CreatedAt", class_type("System.DateTime")], ["IsArchived", bool_type], ["Email", option_type(string_type)], ["CustomProperties", list_type(CustomProperty_$reflection())]]);
}

export class SchoolClass extends Record {
    constructor(Id, Name, Description, ImageUrl, QRCodeIds, IsDeleted, Events, CreatedAt, IsArchived, Email, CustomProperties) {
        super();
        this.Id = Id;
        this.Name = Name;
        this.Description = Description;
        this.ImageUrl = ImageUrl;
        this.QRCodeIds = QRCodeIds;
        this.IsDeleted = IsDeleted;
        this.Events = Events;
        this.CreatedAt = CreatedAt;
        this.IsArchived = IsArchived;
        this.Email = Email;
        this.CustomProperties = CustomProperties;
    }
}

export function SchoolClass_$reflection() {
    return record_type("Shared.User.SchoolClass", [], SchoolClass, () => [["Id", string_type], ["Name", string_type], ["Description", string_type], ["ImageUrl", string_type], ["QRCodeIds", list_type(string_type)], ["IsDeleted", bool_type], ["Events", list_type(UserEvent_$reflection())], ["CreatedAt", class_type("System.DateTime")], ["IsArchived", bool_type], ["Email", option_type(string_type)], ["CustomProperties", list_type(CustomProperty_$reflection())]]);
}

export class Vehicle extends Record {
    constructor(Id, Name, NumberPlate, Model, Manufacturer, ImageUrl, QRCodeIds, IsDeleted, Events, CreatedAt, IsArchived, ResponsibleUserIds, VisibleForUserIds, Email, CustomProperties) {
        super();
        this.Id = Id;
        this.Name = Name;
        this.NumberPlate = NumberPlate;
        this.Model = Model;
        this.Manufacturer = Manufacturer;
        this.ImageUrl = ImageUrl;
        this.QRCodeIds = QRCodeIds;
        this.IsDeleted = IsDeleted;
        this.Events = Events;
        this.CreatedAt = CreatedAt;
        this.IsArchived = IsArchived;
        this.ResponsibleUserIds = ResponsibleUserIds;
        this.VisibleForUserIds = VisibleForUserIds;
        this.Email = Email;
        this.CustomProperties = CustomProperties;
    }
}

export function Vehicle_$reflection() {
    return record_type("Shared.User.Vehicle", [], Vehicle, () => [["Id", string_type], ["Name", string_type], ["NumberPlate", string_type], ["Model", string_type], ["Manufacturer", string_type], ["ImageUrl", string_type], ["QRCodeIds", list_type(string_type)], ["IsDeleted", bool_type], ["Events", list_type(UserEvent_$reflection())], ["CreatedAt", class_type("System.DateTime")], ["IsArchived", bool_type], ["ResponsibleUserIds", list_type(string_type)], ["VisibleForUserIds", list_type(string_type)], ["Email", option_type(string_type)], ["CustomProperties", list_type(CustomProperty_$reflection())]]);
}

export class SaveReceiverResponse$1 extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Saved", "UserLimitReached"];
    }
}

export function SaveReceiverResponse$1_$reflection(gen0) {
    return union_type("Shared.User.SaveReceiverResponse`1", [gen0], SaveReceiverResponse$1, () => [[["Item", gen0]], []]);
}

export class ReceiverType extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["User", "Employee", "ExternalPerson", "Subcontractor", "Room", "Project", "ConstructionSite", "Student", "SchoolClass", "Vehicle"];
    }
}

export function ReceiverType_$reflection() {
    return union_type("Shared.User.ReceiverType", [], ReceiverType, () => [[["Item", User_$reflection()]], [["Item", Employee_$reflection()]], [["Item", ExternalPerson_$reflection()]], [["Item", Subcontractor_$reflection()]], [["Item", Room_$reflection()]], [["Item", Project_$reflection()]], [["Item", ConstructionSite_$reflection()]], [["Item", Student_$reflection()]], [["Item", SchoolClass_$reflection()]], [["Item", Vehicle_$reflection()]]]);
}

export class ReceiverTypeName extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["User", "Employee", "ExternalPerson", "Subcontractor", "Room", "Project", "ConstructionSite", "Student", "SchoolClass", "Vehicles"];
    }
}

export function ReceiverTypeName_$reflection() {
    return union_type("Shared.User.ReceiverTypeName", [], ReceiverTypeName, () => [[], [], [], [], [], [], [], [], [], []]);
}

export class Receiver extends Record {
    constructor(Id, Name, Events, ReceiverType, QRCodeIds, LocationName, Email, IsArchived, ResponsibleUserIds, VisibleForUserIds) {
        super();
        this.Id = Id;
        this.Name = Name;
        this.Events = Events;
        this.ReceiverType = ReceiverType;
        this.QRCodeIds = QRCodeIds;
        this.LocationName = LocationName;
        this.Email = Email;
        this.IsArchived = IsArchived;
        this.ResponsibleUserIds = ResponsibleUserIds;
        this.VisibleForUserIds = VisibleForUserIds;
    }
}

export function Receiver_$reflection() {
    return record_type("Shared.User.Receiver", [], Receiver, () => [["Id", string_type], ["Name", string_type], ["Events", list_type(UserEvent_$reflection())], ["ReceiverType", ReceiverTypeName_$reflection()], ["QRCodeIds", list_type(string_type)], ["LocationName", option_type(string_type)], ["Email", option_type(string_type)], ["IsArchived", bool_type], ["ResponsibleUserIds", list_type(string_type)], ["VisibleForUserIds", list_type(string_type)]]);
}

export class Requests_UserDto extends Record {
    constructor(Id, ImageUrl, Name, PersonnelNumber, Events, QRCodeIds, LoginInformation, LocationId, Role, IsDeleted, AllowUserToSubcontractorTransfer, Department, IsArchived, CustomProperties) {
        super();
        this.Id = Id;
        this.ImageUrl = ImageUrl;
        this.Name = Name;
        this.PersonnelNumber = PersonnelNumber;
        this.Events = Events;
        this.QRCodeIds = QRCodeIds;
        this.LoginInformation = LoginInformation;
        this.LocationId = LocationId;
        this.Role = Role;
        this.IsDeleted = IsDeleted;
        this.AllowUserToSubcontractorTransfer = AllowUserToSubcontractorTransfer;
        this.Department = Department;
        this.IsArchived = IsArchived;
        this.CustomProperties = CustomProperties;
    }
}

export function Requests_UserDto_$reflection() {
    return record_type("Shared.User.Requests.UserDto", [], Requests_UserDto, () => [["Id", string_type], ["ImageUrl", string_type], ["Name", string_type], ["PersonnelNumber", string_type], ["Events", list_type(UserEvent_$reflection())], ["QRCodeIds", list_type(string_type)], ["LoginInformation", LoginInformation_$reflection()], ["LocationId", option_type(LocationId_$reflection())], ["Role", string_type], ["IsDeleted", bool_type], ["AllowUserToSubcontractorTransfer", AllowUserToSubcontractorTransfer_$reflection()], ["Department", string_type], ["IsArchived", bool_type], ["CustomProperties", list_type(CustomPropertyValueDto_$reflection())]]);
}

export class Requests_CreateUserFormDto extends Record {
    constructor(Name, PersonnelNumber, Department, ImageUrl, LoginInformation, Role, StorageIds, AllowUserToSubcontractorTransfer, ActivatedEmailNotification, CustomProperties) {
        super();
        this.Name = Name;
        this.PersonnelNumber = PersonnelNumber;
        this.Department = Department;
        this.ImageUrl = ImageUrl;
        this.LoginInformation = LoginInformation;
        this.Role = Role;
        this.StorageIds = StorageIds;
        this.AllowUserToSubcontractorTransfer = AllowUserToSubcontractorTransfer;
        this.ActivatedEmailNotification = ActivatedEmailNotification;
        this.CustomProperties = CustomProperties;
    }
}

export function Requests_CreateUserFormDto_$reflection() {
    return record_type("Shared.User.Requests.CreateUserFormDto", [], Requests_CreateUserFormDto, () => [["Name", string_type], ["PersonnelNumber", string_type], ["Department", string_type], ["ImageUrl", string_type], ["LoginInformation", LoginInformation_$reflection()], ["Role", string_type], ["StorageIds", list_type(StorageId_$reflection())], ["AllowUserToSubcontractorTransfer", AllowUserToSubcontractorTransfer_$reflection()], ["ActivatedEmailNotification", ActivatedEmailNotification_$reflection()], ["CustomProperties", list_type(CustomProperty_$reflection())]]);
}

export class Requests_EditUserFormDto extends Record {
    constructor(Name, PersonnelNumber, Department, ImageUrl, Role, StorageIds, AllowUserToSubcontractorTransfer, ActivatedEmailNotification, CustomProperties) {
        super();
        this.Name = Name;
        this.PersonnelNumber = PersonnelNumber;
        this.Department = Department;
        this.ImageUrl = ImageUrl;
        this.Role = Role;
        this.StorageIds = StorageIds;
        this.AllowUserToSubcontractorTransfer = AllowUserToSubcontractorTransfer;
        this.ActivatedEmailNotification = ActivatedEmailNotification;
        this.CustomProperties = CustomProperties;
    }
}

export function Requests_EditUserFormDto_$reflection() {
    return record_type("Shared.User.Requests.EditUserFormDto", [], Requests_EditUserFormDto, () => [["Name", string_type], ["PersonnelNumber", string_type], ["Department", string_type], ["ImageUrl", string_type], ["Role", string_type], ["StorageIds", list_type(StorageId_$reflection())], ["AllowUserToSubcontractorTransfer", AllowUserToSubcontractorTransfer_$reflection()], ["ActivatedEmailNotification", ActivatedEmailNotification_$reflection()], ["CustomProperties", list_type(CustomProperty_$reflection())]]);
}

export class Requests_EmployeeDto extends Record {
    constructor(Id, ImageUrl, Name, PersonnelNumber, Email, LocationId, QRCodeIds, IsDeleted, Events, Department, CreatedAt, IsArchived, CustomProperties) {
        super();
        this.Id = Id;
        this.ImageUrl = ImageUrl;
        this.Name = Name;
        this.PersonnelNumber = PersonnelNumber;
        this.Email = Email;
        this.LocationId = LocationId;
        this.QRCodeIds = QRCodeIds;
        this.IsDeleted = IsDeleted;
        this.Events = Events;
        this.Department = Department;
        this.CreatedAt = CreatedAt;
        this.IsArchived = IsArchived;
        this.CustomProperties = CustomProperties;
    }
}

export function Requests_EmployeeDto_$reflection() {
    return record_type("Shared.User.Requests.EmployeeDto", [], Requests_EmployeeDto, () => [["Id", string_type], ["ImageUrl", string_type], ["Name", string_type], ["PersonnelNumber", string_type], ["Email", string_type], ["LocationId", option_type(LocationId_$reflection())], ["QRCodeIds", list_type(string_type)], ["IsDeleted", bool_type], ["Events", list_type(UserEvent_$reflection())], ["Department", string_type], ["CreatedAt", class_type("System.DateTime")], ["IsArchived", bool_type], ["CustomProperties", list_type(CustomPropertyValueDto_$reflection())]]);
}

export class Requests_EmployeeFormDto extends Record {
    constructor(ImageUrl, Name, PersonnelNumber, LocationId, QRCodeIds, Department, Email, CustomProperties) {
        super();
        this.ImageUrl = ImageUrl;
        this.Name = Name;
        this.PersonnelNumber = PersonnelNumber;
        this.LocationId = LocationId;
        this.QRCodeIds = QRCodeIds;
        this.Department = Department;
        this.Email = Email;
        this.CustomProperties = CustomProperties;
    }
}

export function Requests_EmployeeFormDto_$reflection() {
    return record_type("Shared.User.Requests.EmployeeFormDto", [], Requests_EmployeeFormDto, () => [["ImageUrl", string_type], ["Name", string_type], ["PersonnelNumber", string_type], ["LocationId", option_type(LocationId_$reflection())], ["QRCodeIds", list_type(string_type)], ["Department", string_type], ["Email", option_type(string_type)], ["CustomProperties", list_type(CustomProperty_$reflection())]]);
}

export class Requests_SubcontractorDto extends Record {
    constructor(Id, Name, ImageUrl, Email, QRCodeIds, IsDeleted, Events, CreatedAt, IsArchived, CustomProperties) {
        super();
        this.Id = Id;
        this.Name = Name;
        this.ImageUrl = ImageUrl;
        this.Email = Email;
        this.QRCodeIds = QRCodeIds;
        this.IsDeleted = IsDeleted;
        this.Events = Events;
        this.CreatedAt = CreatedAt;
        this.IsArchived = IsArchived;
        this.CustomProperties = CustomProperties;
    }
}

export function Requests_SubcontractorDto_$reflection() {
    return record_type("Shared.User.Requests.SubcontractorDto", [], Requests_SubcontractorDto, () => [["Id", string_type], ["Name", string_type], ["ImageUrl", string_type], ["Email", string_type], ["QRCodeIds", list_type(string_type)], ["IsDeleted", bool_type], ["Events", list_type(UserEvent_$reflection())], ["CreatedAt", class_type("System.DateTime")], ["IsArchived", bool_type], ["CustomProperties", list_type(CustomPropertyValueDto_$reflection())]]);
}

export class Requests_SubcontractorFormDto extends Record {
    constructor(Name, ImageUrl, QRCodeIds, Email, CustomProperties) {
        super();
        this.Name = Name;
        this.ImageUrl = ImageUrl;
        this.QRCodeIds = QRCodeIds;
        this.Email = Email;
        this.CustomProperties = CustomProperties;
    }
}

export function Requests_SubcontractorFormDto_$reflection() {
    return record_type("Shared.User.Requests.SubcontractorFormDto", [], Requests_SubcontractorFormDto, () => [["Name", string_type], ["ImageUrl", string_type], ["QRCodeIds", list_type(string_type)], ["Email", option_type(string_type)], ["CustomProperties", list_type(CustomProperty_$reflection())]]);
}

export class Requests_ExternalPersonDto extends Record {
    constructor(Id, Name, ImageUrl, Email, QRCodeIds, IsDeleted, Events, CreatedAt, IsArchived, CustomProperties) {
        super();
        this.Id = Id;
        this.Name = Name;
        this.ImageUrl = ImageUrl;
        this.Email = Email;
        this.QRCodeIds = QRCodeIds;
        this.IsDeleted = IsDeleted;
        this.Events = Events;
        this.CreatedAt = CreatedAt;
        this.IsArchived = IsArchived;
        this.CustomProperties = CustomProperties;
    }
}

export function Requests_ExternalPersonDto_$reflection() {
    return record_type("Shared.User.Requests.ExternalPersonDto", [], Requests_ExternalPersonDto, () => [["Id", string_type], ["Name", string_type], ["ImageUrl", string_type], ["Email", string_type], ["QRCodeIds", list_type(string_type)], ["IsDeleted", bool_type], ["Events", list_type(UserEvent_$reflection())], ["CreatedAt", class_type("System.DateTime")], ["IsArchived", bool_type], ["CustomProperties", list_type(CustomPropertyValueDto_$reflection())]]);
}

export class Requests_ExternalPersonFormDto extends Record {
    constructor(Name, ImageUrl, QRCodeIds, Email, CustomProperties) {
        super();
        this.Name = Name;
        this.ImageUrl = ImageUrl;
        this.QRCodeIds = QRCodeIds;
        this.Email = Email;
        this.CustomProperties = CustomProperties;
    }
}

export function Requests_ExternalPersonFormDto_$reflection() {
    return record_type("Shared.User.Requests.ExternalPersonFormDto", [], Requests_ExternalPersonFormDto, () => [["Name", string_type], ["ImageUrl", string_type], ["QRCodeIds", list_type(string_type)], ["Email", option_type(string_type)], ["CustomProperties", list_type(CustomProperty_$reflection())]]);
}

export class Requests_RoomDto extends Record {
    constructor(Id, Name, Number$, Email, LocationId, ImageUrl, QRCodeIds, IsDeleted, Events, CreatedAt, IsArchived, CustomProperties) {
        super();
        this.Id = Id;
        this.Name = Name;
        this.Number = Number$;
        this.Email = Email;
        this.LocationId = LocationId;
        this.ImageUrl = ImageUrl;
        this.QRCodeIds = QRCodeIds;
        this.IsDeleted = IsDeleted;
        this.Events = Events;
        this.CreatedAt = CreatedAt;
        this.IsArchived = IsArchived;
        this.CustomProperties = CustomProperties;
    }
}

export function Requests_RoomDto_$reflection() {
    return record_type("Shared.User.Requests.RoomDto", [], Requests_RoomDto, () => [["Id", string_type], ["Name", string_type], ["Number", string_type], ["Email", string_type], ["LocationId", option_type(LocationId_$reflection())], ["ImageUrl", string_type], ["QRCodeIds", list_type(string_type)], ["IsDeleted", bool_type], ["Events", list_type(UserEvent_$reflection())], ["CreatedAt", class_type("System.DateTime")], ["IsArchived", bool_type], ["CustomProperties", list_type(CustomPropertyValueDto_$reflection())]]);
}

export class Requests_RoomFormDto extends Record {
    constructor(Name, Number$, LocationId, ImageUrl, Email, QRCodeIds, VisibleForUserIds, CustomProperties) {
        super();
        this.Name = Name;
        this.Number = Number$;
        this.LocationId = LocationId;
        this.ImageUrl = ImageUrl;
        this.Email = Email;
        this.QRCodeIds = QRCodeIds;
        this.VisibleForUserIds = VisibleForUserIds;
        this.CustomProperties = CustomProperties;
    }
}

export function Requests_RoomFormDto_$reflection() {
    return record_type("Shared.User.Requests.RoomFormDto", [], Requests_RoomFormDto, () => [["Name", string_type], ["Number", string_type], ["LocationId", option_type(LocationId_$reflection())], ["ImageUrl", string_type], ["Email", option_type(string_type)], ["QRCodeIds", list_type(string_type)], ["VisibleForUserIds", list_type(string_type)], ["CustomProperties", list_type(CustomProperty_$reflection())]]);
}

export class Requests_ProjectDto extends Record {
    constructor(Id, Name, Description, Email, ImageUrl, QRCodeIds, IsDeleted, Events, CreatedAt, IsArchived, CustomProperties) {
        super();
        this.Id = Id;
        this.Name = Name;
        this.Description = Description;
        this.Email = Email;
        this.ImageUrl = ImageUrl;
        this.QRCodeIds = QRCodeIds;
        this.IsDeleted = IsDeleted;
        this.Events = Events;
        this.CreatedAt = CreatedAt;
        this.IsArchived = IsArchived;
        this.CustomProperties = CustomProperties;
    }
}

export function Requests_ProjectDto_$reflection() {
    return record_type("Shared.User.Requests.ProjectDto", [], Requests_ProjectDto, () => [["Id", string_type], ["Name", string_type], ["Description", string_type], ["Email", string_type], ["ImageUrl", string_type], ["QRCodeIds", list_type(string_type)], ["IsDeleted", bool_type], ["Events", list_type(UserEvent_$reflection())], ["CreatedAt", class_type("System.DateTime")], ["IsArchived", bool_type], ["CustomProperties", list_type(CustomPropertyValueDto_$reflection())]]);
}

export class Requests_ProjectFormDto extends Record {
    constructor(Name, Description, ImageUrl, QRCodeIds, VisibleForUserIds, Email, CustomProperties) {
        super();
        this.Name = Name;
        this.Description = Description;
        this.ImageUrl = ImageUrl;
        this.QRCodeIds = QRCodeIds;
        this.VisibleForUserIds = VisibleForUserIds;
        this.Email = Email;
        this.CustomProperties = CustomProperties;
    }
}

export function Requests_ProjectFormDto_$reflection() {
    return record_type("Shared.User.Requests.ProjectFormDto", [], Requests_ProjectFormDto, () => [["Name", string_type], ["Description", string_type], ["ImageUrl", string_type], ["QRCodeIds", list_type(string_type)], ["VisibleForUserIds", list_type(string_type)], ["Email", option_type(string_type)], ["CustomProperties", list_type(CustomProperty_$reflection())]]);
}

export class Requests_ConstructionSiteDto extends Record {
    constructor(Id, Name, Description, Email, ImageUrl, QRCodeIds, IsDeleted, Events, CreatedAt, IsArchived, CustomProperties) {
        super();
        this.Id = Id;
        this.Name = Name;
        this.Description = Description;
        this.Email = Email;
        this.ImageUrl = ImageUrl;
        this.QRCodeIds = QRCodeIds;
        this.IsDeleted = IsDeleted;
        this.Events = Events;
        this.CreatedAt = CreatedAt;
        this.IsArchived = IsArchived;
        this.CustomProperties = CustomProperties;
    }
}

export function Requests_ConstructionSiteDto_$reflection() {
    return record_type("Shared.User.Requests.ConstructionSiteDto", [], Requests_ConstructionSiteDto, () => [["Id", string_type], ["Name", string_type], ["Description", string_type], ["Email", string_type], ["ImageUrl", string_type], ["QRCodeIds", list_type(string_type)], ["IsDeleted", bool_type], ["Events", list_type(UserEvent_$reflection())], ["CreatedAt", class_type("System.DateTime")], ["IsArchived", bool_type], ["CustomProperties", list_type(CustomPropertyValueDto_$reflection())]]);
}

export class Requests_ConstructionSiteFormDto extends Record {
    constructor(Name, Description, ImageUrl, Email, QRCodeIds, VisibleForUserIds, CustomProperties) {
        super();
        this.Name = Name;
        this.Description = Description;
        this.ImageUrl = ImageUrl;
        this.Email = Email;
        this.QRCodeIds = QRCodeIds;
        this.VisibleForUserIds = VisibleForUserIds;
        this.CustomProperties = CustomProperties;
    }
}

export function Requests_ConstructionSiteFormDto_$reflection() {
    return record_type("Shared.User.Requests.ConstructionSiteFormDto", [], Requests_ConstructionSiteFormDto, () => [["Name", string_type], ["Description", string_type], ["ImageUrl", string_type], ["Email", option_type(string_type)], ["QRCodeIds", list_type(string_type)], ["VisibleForUserIds", list_type(string_type)], ["CustomProperties", list_type(CustomProperty_$reflection())]]);
}

export class Requests_StudentDto extends Record {
    constructor(Id, Name, StudentNumber, Email, ImageUrl, QRCodeIds, IsDeleted, Events, CreatedAt, IsArchived, CustomProperties) {
        super();
        this.Id = Id;
        this.Name = Name;
        this.StudentNumber = StudentNumber;
        this.Email = Email;
        this.ImageUrl = ImageUrl;
        this.QRCodeIds = QRCodeIds;
        this.IsDeleted = IsDeleted;
        this.Events = Events;
        this.CreatedAt = CreatedAt;
        this.IsArchived = IsArchived;
        this.CustomProperties = CustomProperties;
    }
}

export function Requests_StudentDto_$reflection() {
    return record_type("Shared.User.Requests.StudentDto", [], Requests_StudentDto, () => [["Id", string_type], ["Name", string_type], ["StudentNumber", string_type], ["Email", string_type], ["ImageUrl", string_type], ["QRCodeIds", list_type(string_type)], ["IsDeleted", bool_type], ["Events", list_type(UserEvent_$reflection())], ["CreatedAt", class_type("System.DateTime")], ["IsArchived", bool_type], ["CustomProperties", list_type(CustomPropertyValueDto_$reflection())]]);
}

export class Requests_StudentFormDto extends Record {
    constructor(Name, StudentNumber, ImageUrl, QRCodeIds, Email, CustomProperties) {
        super();
        this.Name = Name;
        this.StudentNumber = StudentNumber;
        this.ImageUrl = ImageUrl;
        this.QRCodeIds = QRCodeIds;
        this.Email = Email;
        this.CustomProperties = CustomProperties;
    }
}

export function Requests_StudentFormDto_$reflection() {
    return record_type("Shared.User.Requests.StudentFormDto", [], Requests_StudentFormDto, () => [["Name", string_type], ["StudentNumber", string_type], ["ImageUrl", string_type], ["QRCodeIds", list_type(string_type)], ["Email", option_type(string_type)], ["CustomProperties", list_type(CustomProperty_$reflection())]]);
}

export class Requests_SchoolClassDto extends Record {
    constructor(Id, Name, Description, ImageUrl, Email, QRCodeIds, IsDeleted, Events, CreatedAt, IsArchived, CustomProperties) {
        super();
        this.Id = Id;
        this.Name = Name;
        this.Description = Description;
        this.ImageUrl = ImageUrl;
        this.Email = Email;
        this.QRCodeIds = QRCodeIds;
        this.IsDeleted = IsDeleted;
        this.Events = Events;
        this.CreatedAt = CreatedAt;
        this.IsArchived = IsArchived;
        this.CustomProperties = CustomProperties;
    }
}

export function Requests_SchoolClassDto_$reflection() {
    return record_type("Shared.User.Requests.SchoolClassDto", [], Requests_SchoolClassDto, () => [["Id", string_type], ["Name", string_type], ["Description", string_type], ["ImageUrl", string_type], ["Email", string_type], ["QRCodeIds", list_type(string_type)], ["IsDeleted", bool_type], ["Events", list_type(UserEvent_$reflection())], ["CreatedAt", class_type("System.DateTime")], ["IsArchived", bool_type], ["CustomProperties", list_type(CustomPropertyValueDto_$reflection())]]);
}

export class Requests_SchoolClassFormDto extends Record {
    constructor(Name, Description, ImageUrl, QRCodeIds, Email, CustomProperties) {
        super();
        this.Name = Name;
        this.Description = Description;
        this.ImageUrl = ImageUrl;
        this.QRCodeIds = QRCodeIds;
        this.Email = Email;
        this.CustomProperties = CustomProperties;
    }
}

export function Requests_SchoolClassFormDto_$reflection() {
    return record_type("Shared.User.Requests.SchoolClassFormDto", [], Requests_SchoolClassFormDto, () => [["Name", string_type], ["Description", string_type], ["ImageUrl", string_type], ["QRCodeIds", list_type(string_type)], ["Email", option_type(string_type)], ["CustomProperties", list_type(CustomProperty_$reflection())]]);
}

export class Requests_VehicleDto extends Record {
    constructor(Id, Name, Email, NumberPlate, Model, Manufacturer, ImageUrl, QRCodeIds, IsDeleted, Events, CreatedAt, IsArchived, CustomProperties) {
        super();
        this.Id = Id;
        this.Name = Name;
        this.Email = Email;
        this.NumberPlate = NumberPlate;
        this.Model = Model;
        this.Manufacturer = Manufacturer;
        this.ImageUrl = ImageUrl;
        this.QRCodeIds = QRCodeIds;
        this.IsDeleted = IsDeleted;
        this.Events = Events;
        this.CreatedAt = CreatedAt;
        this.IsArchived = IsArchived;
        this.CustomProperties = CustomProperties;
    }
}

export function Requests_VehicleDto_$reflection() {
    return record_type("Shared.User.Requests.VehicleDto", [], Requests_VehicleDto, () => [["Id", string_type], ["Name", string_type], ["Email", string_type], ["NumberPlate", string_type], ["Model", string_type], ["Manufacturer", string_type], ["ImageUrl", string_type], ["QRCodeIds", list_type(string_type)], ["IsDeleted", bool_type], ["Events", list_type(UserEvent_$reflection())], ["CreatedAt", class_type("System.DateTime")], ["IsArchived", bool_type], ["CustomProperties", list_type(CustomPropertyValueDto_$reflection())]]);
}

export class Requests_VehicleFormDto extends Record {
    constructor(Name, NumberPlate, Model, Manufacturer, ImageUrl, QRCodeIds, VisibleForUserIds, Email, CustomProperties) {
        super();
        this.Name = Name;
        this.NumberPlate = NumberPlate;
        this.Model = Model;
        this.Manufacturer = Manufacturer;
        this.ImageUrl = ImageUrl;
        this.QRCodeIds = QRCodeIds;
        this.VisibleForUserIds = VisibleForUserIds;
        this.Email = Email;
        this.CustomProperties = CustomProperties;
    }
}

export function Requests_VehicleFormDto_$reflection() {
    return record_type("Shared.User.Requests.VehicleFormDto", [], Requests_VehicleFormDto, () => [["Name", string_type], ["NumberPlate", string_type], ["Model", string_type], ["Manufacturer", string_type], ["ImageUrl", string_type], ["QRCodeIds", list_type(string_type)], ["VisibleForUserIds", list_type(string_type)], ["Email", option_type(string_type)], ["CustomProperties", list_type(CustomProperty_$reflection())]]);
}

export class Requests_ReceiverTypeDto extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["User", "Employee", "ExternalPerson", "Subcontractor", "Room", "Project", "ConstructionSite", "Student", "SchoolClass", "Vehicle"];
    }
}

export function Requests_ReceiverTypeDto_$reflection() {
    return union_type("Shared.User.Requests.ReceiverTypeDto", [], Requests_ReceiverTypeDto, () => [[["Item", Requests_UserDto_$reflection()]], [["Item", Requests_EmployeeDto_$reflection()]], [["Item", Requests_ExternalPersonDto_$reflection()]], [["Item", Requests_SubcontractorDto_$reflection()]], [["Item", Requests_RoomDto_$reflection()]], [["Item", Requests_ProjectDto_$reflection()]], [["Item", Requests_ConstructionSiteDto_$reflection()]], [["Item", Requests_StudentDto_$reflection()]], [["Item", Requests_SchoolClassDto_$reflection()]], [["Item", Requests_VehicleDto_$reflection()]]]);
}

export class Requests_ArchiveReceiverDto extends Record {
    constructor(Archive) {
        super();
        this.Archive = Archive;
    }
}

export function Requests_ArchiveReceiverDto_$reflection() {
    return record_type("Shared.User.Requests.ArchiveReceiverDto", [], Requests_ArchiveReceiverDto, () => [["Archive", bool_type]]);
}

export class Requests_UpdateUserLanguageDto extends Record {
    constructor(NewLanguage) {
        super();
        this.NewLanguage = NewLanguage;
    }
}

export function Requests_UpdateUserLanguageDto_$reflection() {
    return record_type("Shared.User.Requests.UpdateUserLanguageDto", [], Requests_UpdateUserLanguageDto, () => [["NewLanguage", LanguageKey_$reflection()]]);
}

export class Requests_UpdateLoginInformationDto extends Record {
    constructor(LoginInformation) {
        super();
        this.LoginInformation = LoginInformation;
    }
}

export function Requests_UpdateLoginInformationDto_$reflection() {
    return record_type("Shared.User.Requests.UpdateLoginInformationDto", [], Requests_UpdateLoginInformationDto, () => [["LoginInformation", LoginInformation_$reflection()]]);
}

export class Requests_UserWithoutLoginToUserWithLoginDto extends Record {
    constructor(UserId, LoginInformation) {
        super();
        this.UserId = UserId;
        this.LoginInformation = LoginInformation;
    }
}

export function Requests_UserWithoutLoginToUserWithLoginDto_$reflection() {
    return record_type("Shared.User.Requests.UserWithoutLoginToUserWithLoginDto", [], Requests_UserWithoutLoginToUserWithLoginDto, () => [["UserId", string_type], ["LoginInformation", LoginInformation_$reflection()]]);
}

export class Requests_ReceiverNameDto extends Record {
    constructor(ReceiverId, Name) {
        super();
        this.ReceiverId = ReceiverId;
        this.Name = Name;
    }
}

export function Requests_ReceiverNameDto_$reflection() {
    return record_type("Shared.User.Requests.ReceiverNameDto", [], Requests_ReceiverNameDto, () => [["ReceiverId", string_type], ["Name", string_type]]);
}

export class Requests_UserDeleteResponse extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Deleted", "SelfDeleteError", "HasToolsInBox"];
    }
}

export function Requests_UserDeleteResponse_$reflection() {
    return union_type("Shared.User.Requests.UserDeleteResponse", [], Requests_UserDeleteResponse, () => [[], [], []]);
}

export class Requests_UserWithoutLoginToUserWithLoginResponse extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Updated", "EmailOrPhoneNumberAlreadyExists", "Failed"];
    }
}

export function Requests_UserWithoutLoginToUserWithLoginResponse_$reflection() {
    return union_type("Shared.User.Requests.UserWithoutLoginToUserWithLoginResponse", [], Requests_UserWithoutLoginToUserWithLoginResponse, () => [[["Item", string_type]], [], []]);
}

export class Requests_UserUpdateEmailOrPhoneNumberResponse extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Updated", "EmailOrPhoneNumberAlreadyExists", "Failed"];
    }
}

export function Requests_UserUpdateEmailOrPhoneNumberResponse_$reflection() {
    return union_type("Shared.User.Requests.UserUpdateEmailOrPhoneNumberResponse", [], Requests_UserUpdateEmailOrPhoneNumberResponse, () => [[], [], []]);
}

export class Requests_UpdateImageUrl extends Record {
    constructor(Value) {
        super();
        this.Value = Value;
    }
}

export function Requests_UpdateImageUrl_$reflection() {
    return record_type("Shared.User.Requests.UpdateImageUrl", [], Requests_UpdateImageUrl, () => [["Value", string_type]]);
}

export class Requests_UpdateReceiverImageResponse extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Successful", "Failed"];
    }
}

export function Requests_UpdateReceiverImageResponse_$reflection() {
    return union_type("Shared.User.Requests.UpdateReceiverImageResponse", [], Requests_UpdateReceiverImageResponse, () => [[], []]);
}

export class Requests_UpdateReceiverArchivedResponse extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Successful", "ValidationFailed", "Failed"];
    }
}

export function Requests_UpdateReceiverArchivedResponse_$reflection() {
    return union_type("Shared.User.Requests.UpdateReceiverArchivedResponse", [], Requests_UpdateReceiverArchivedResponse, () => [[], [], []]);
}

export class Requests_DeleteReceiverImageResponse extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Successful", "Failed"];
    }
}

export function Requests_DeleteReceiverImageResponse_$reflection() {
    return union_type("Shared.User.Requests.DeleteReceiverImageResponse", [], Requests_DeleteReceiverImageResponse, () => [[], []]);
}

export function Requests_customPropertiesToDto(propertyConfigurations, propertyConfigurationType, customProperties) {
    const propertyConfigurations_1 = filter((propertyConfiguration) => equals(propertyConfiguration.Visibility, propertyConfigurationType), propertyConfigurations);
    return Helper_mapCustomProperties(ofSeq(map((customProperty) => [new PropertyConfigurationId(parse(customProperty.Id)), customProperty], customProperties), {
        Compare: compare,
    }), empty(), propertyConfigurations_1);
}

export function Requests_toUserDto(propertyConfigurations, user) {
    return new Requests_UserDto(user.Id, user.ImageUrl, user.Name, user.PersonnelNumber, user.Events, user.QRCodeIds, user.LoginInformation, user.LocationId, user.Role, user.IsDeleted, user.AllowUserToSubcontractorTransfer, user.Department, user.IsArchived, Requests_customPropertiesToDto(propertyConfigurations, new PropertyConfigurationVisibility(2, []), user.CustomProperties));
}

export function Requests_toEmployeeDto(propertyConfigurations, employee) {
    return new Requests_EmployeeDto(employee.Id, employee.ImageUrl, employee.Name, employee.PersonnelNumber, defaultArg(employee.Email, ""), employee.LocationId, employee.QRCodeIds, employee.IsDeleted, employee.Events, employee.Department, employee.CreatedAt, employee.IsArchived, Requests_customPropertiesToDto(propertyConfigurations, new PropertyConfigurationVisibility(3, []), employee.CustomProperties));
}

export function Requests_toExternalPersonDto(propertyConfigurations, externalPerson) {
    return new Requests_ExternalPersonDto(externalPerson.Id, externalPerson.Name, externalPerson.ImageUrl, defaultArg(externalPerson.Email, ""), externalPerson.QRCodeIds, externalPerson.IsDeleted, externalPerson.Events, externalPerson.CreatedAt, externalPerson.IsArchived, Requests_customPropertiesToDto(propertyConfigurations, new PropertyConfigurationVisibility(4, []), externalPerson.CustomProperties));
}

export function Requests_toSubcontractorDto(propertyConfigurations, subcontractor) {
    return new Requests_SubcontractorDto(subcontractor.Id, subcontractor.Name, subcontractor.ImageUrl, defaultArg(subcontractor.Email, ""), subcontractor.QRCodeIds, subcontractor.IsDeleted, subcontractor.Events, subcontractor.CreatedAt, subcontractor.IsArchived, Requests_customPropertiesToDto(propertyConfigurations, new PropertyConfigurationVisibility(5, []), subcontractor.CustomProperties));
}

export function Requests_toRoomDto(propertyConfigurations, room) {
    return new Requests_RoomDto(room.Id, room.Name, room.Number, defaultArg(room.Email, ""), room.LocationId, room.ImageUrl, room.QRCodeIds, room.IsDeleted, room.Events, room.CreatedAt, room.IsArchived, Requests_customPropertiesToDto(propertyConfigurations, new PropertyConfigurationVisibility(6, []), room.CustomProperties));
}

export function Requests_toProjectDto(propertyConfigurations, project) {
    return new Requests_ProjectDto(project.Id, project.Name, project.Description, defaultArg(project.Email, ""), project.ImageUrl, project.QRCodeIds, project.IsDeleted, project.Events, project.CreatedAt, project.IsArchived, Requests_customPropertiesToDto(propertyConfigurations, new PropertyConfigurationVisibility(7, []), project.CustomProperties));
}

export function Requests_toConstructionSiteDto(propertyConfigurations, constructionSite) {
    return new Requests_ConstructionSiteDto(constructionSite.Id, constructionSite.Name, constructionSite.Description, defaultArg(constructionSite.Email, ""), constructionSite.ImageUrl, constructionSite.QRCodeIds, constructionSite.IsDeleted, constructionSite.Events, constructionSite.CreatedAt, constructionSite.IsArchived, Requests_customPropertiesToDto(propertyConfigurations, new PropertyConfigurationVisibility(8, []), constructionSite.CustomProperties));
}

export function Requests_toSchoolClassDto(propertyConfigurations, schoolClass) {
    return new Requests_SchoolClassDto(schoolClass.Id, schoolClass.Name, schoolClass.Description, schoolClass.ImageUrl, defaultArg(schoolClass.Email, ""), schoolClass.QRCodeIds, schoolClass.IsDeleted, schoolClass.Events, schoolClass.CreatedAt, schoolClass.IsArchived, Requests_customPropertiesToDto(propertyConfigurations, new PropertyConfigurationVisibility(11, []), schoolClass.CustomProperties));
}

export function Requests_toStudentDto(propertyConfigurations, student) {
    return new Requests_StudentDto(student.Id, student.Name, student.StudentNumber, defaultArg(student.Email, ""), student.ImageUrl, student.QRCodeIds, student.IsDeleted, student.Events, student.CreatedAt, student.IsArchived, Requests_customPropertiesToDto(propertyConfigurations, new PropertyConfigurationVisibility(10, []), student.CustomProperties));
}

export function Requests_toVehicleDto(propertyConfigurations, vehicle) {
    return new Requests_VehicleDto(vehicle.Id, vehicle.Name, defaultArg(vehicle.Email, ""), vehicle.NumberPlate, vehicle.Model, vehicle.Manufacturer, vehicle.ImageUrl, vehicle.QRCodeIds, vehicle.IsDeleted, vehicle.Events, vehicle.CreatedAt, vehicle.IsArchived, Requests_customPropertiesToDto(propertyConfigurations, new PropertyConfigurationVisibility(9, []), vehicle.CustomProperties));
}

export class ChangePasswordDto extends Record {
    constructor(Password) {
        super();
        this.Password = Password;
    }
}

export function ChangePasswordDto_$reflection() {
    return record_type("Shared.User.ChangePasswordDto", [], ChangePasswordDto, () => [["Password", string_type]]);
}

export class ChangePasswordResponse extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Successful", "Error"];
    }
}

export function ChangePasswordResponse_$reflection() {
    return union_type("Shared.User.ChangePasswordResponse", [], ChangePasswordResponse, () => [[], []]);
}

export function Helper_displayName(_arg) {
    switch (_arg.tag) {
        case 1:
            return _arg.fields[0].Name;
        case 2:
            return _arg.fields[0].Name;
        case 3:
            return _arg.fields[0].Name;
        case 4:
            return _arg.fields[0].Name;
        case 5:
            return _arg.fields[0].Name;
        case 6:
            return _arg.fields[0].Name;
        case 7:
            return _arg.fields[0].Name;
        case 8:
            return _arg.fields[0].Name;
        case 9:
            return _arg.fields[0].Name;
        default:
            return _arg.fields[0].Name;
    }
}

export function Helper_receiverId(_arg) {
    switch (_arg.tag) {
        case 1:
            return _arg.fields[0].Id;
        case 2:
            return _arg.fields[0].Id;
        case 3:
            return _arg.fields[0].Id;
        case 4:
            return _arg.fields[0].Id;
        case 5:
            return _arg.fields[0].Id;
        case 6:
            return _arg.fields[0].Id;
        case 7:
            return _arg.fields[0].Id;
        case 8:
            return _arg.fields[0].Id;
        case 9:
            return _arg.fields[0].Id;
        default:
            return _arg.fields[0].Id;
    }
}

export function Helper_receiverDtoId(_arg) {
    switch (_arg.tag) {
        case 1:
            return _arg.fields[0].Id;
        case 2:
            return _arg.fields[0].Id;
        case 3:
            return _arg.fields[0].Id;
        case 4:
            return _arg.fields[0].Id;
        case 5:
            return _arg.fields[0].Id;
        case 6:
            return _arg.fields[0].Id;
        case 7:
            return _arg.fields[0].Id;
        case 8:
            return _arg.fields[0].Id;
        case 9:
            return _arg.fields[0].Id;
        default:
            return _arg.fields[0].Id;
    }
}

export function Helper_receiverEvents(_arg) {
    switch (_arg.tag) {
        case 1:
            return _arg.fields[0].Events;
        case 2:
            return _arg.fields[0].Events;
        case 3:
            return _arg.fields[0].Events;
        case 4:
            return _arg.fields[0].Events;
        case 5:
            return _arg.fields[0].Events;
        case 6:
            return _arg.fields[0].Events;
        case 7:
            return _arg.fields[0].Events;
        case 8:
            return _arg.fields[0].Events;
        case 9:
            return _arg.fields[0].Events;
        default:
            return _arg.fields[0].Events;
    }
}

export function Helper_department(_arg) {
    switch (_arg.tag) {
        case 1:
            return _arg.fields[0].Department;
        case 2:
        case 3:
        case 4:
        case 5:
        case 6:
        case 7:
        case 9:
        case 8:
            return "";
        default:
            return _arg.fields[0].Department;
    }
}

export function Helper_receiverName(_arg) {
    switch (_arg.tag) {
        case 1:
            return _arg.fields[0].Name;
        case 2:
            return _arg.fields[0].Name;
        case 3:
            return _arg.fields[0].Name;
        case 4:
            return _arg.fields[0].Name;
        case 5:
            return _arg.fields[0].Name;
        case 6:
            return _arg.fields[0].Name;
        case 7:
            return _arg.fields[0].Name;
        case 8:
            return _arg.fields[0].Name;
        case 9:
            return _arg.fields[0].Name;
        default:
            return _arg.fields[0].Name;
    }
}

export function Helper_receiverQrCodes(_arg) {
    switch (_arg.tag) {
        case 1:
            return _arg.fields[0].QRCodeIds;
        case 2:
            return _arg.fields[0].QRCodeIds;
        case 3:
            return _arg.fields[0].QRCodeIds;
        case 4:
            return _arg.fields[0].QRCodeIds;
        case 5:
            return _arg.fields[0].QRCodeIds;
        case 6:
            return _arg.fields[0].QRCodeIds;
        case 7:
            return _arg.fields[0].QRCodeIds;
        case 8:
            return _arg.fields[0].QRCodeIds;
        case 9:
            return _arg.fields[0].QRCodeIds;
        default:
            return _arg.fields[0].QRCodeIds;
    }
}

export function Helper_receiverTypeName(_arg) {
    switch (_arg.tag) {
        case 1:
            return new ReceiverTypeName(1, []);
        case 2:
            return new ReceiverTypeName(2, []);
        case 3:
            return new ReceiverTypeName(3, []);
        case 4:
            return new ReceiverTypeName(4, []);
        case 5:
            return new ReceiverTypeName(5, []);
        case 6:
            return new ReceiverTypeName(6, []);
        case 7:
            return new ReceiverTypeName(7, []);
        case 8:
            return new ReceiverTypeName(8, []);
        case 9:
            return new ReceiverTypeName(9, []);
        default:
            return new ReceiverTypeName(0, []);
    }
}

export function Helper_personnelNumber(_arg) {
    switch (_arg.tag) {
        case 0:
            return _arg.fields[0].PersonnelNumber;
        case 2:
        case 4:
        case 3:
        case 6:
        case 5:
        case 8:
        case 9:
        case 7:
            return "";
        default:
            return _arg.fields[0].PersonnelNumber;
    }
}

export function Helper_languageFromString(language) {
    switch (language) {
        case "en":
            return new LanguageKey(0, []);
        case "de":
            return new LanguageKey(1, []);
        case "fr":
            return new LanguageKey(2, []);
        case "it":
            return new LanguageKey(3, []);
        case "uk":
            return new LanguageKey(4, []);
        case "pl":
            return new LanguageKey(5, []);
        default:
            throw new Error(toText(printf("invalid language: %s"))(language));
    }
}

export function Helper_languageToString(language) {
    switch (language.tag) {
        case 1:
            return "de";
        case 2:
            return "fr";
        case 3:
            return "it";
        case 4:
            return "uk";
        case 5:
            return "pl";
        default:
            return "en";
    }
}

export function Helper_timestamp(userEvent) {
    return userEvent.fields[0].Timestamp;
}

export function Helper_typeToTranslationString(_arg) {
    return "event.handing_out_dispolist";
}

export function Helper_comment(userEvent) {
    return userEvent.fields[0].Comment;
}

export function Helper_senderId(_arg) {
    return _arg.fields[0].SenderId;
}

export function Helper_dispolistSnapshotId(userEvent) {
    return userEvent.fields[0].DispolistSnapshotId;
}

export function Helper_receiverTypeNameTranslation(t, _arg) {
    switch (_arg.tag) {
        case 1:
            return t("receivers.employee");
        case 5:
            return t("receivers.project");
        case 3:
            return t("receivers.subcontractor");
        case 6:
            return t("receivers.constructionsite");
        case 2:
            return t("receivers.externalperson");
        case 4:
            return t("receivers.room");
        case 7:
            return t("receivers.student");
        case 8:
            return t("receivers.school_class");
        case 9:
            return t("receivers.vehicle");
        default:
            return t("receivers.user");
    }
}

export function Helper_receiverTypeId(_arg) {
    switch (_arg.tag) {
        case 1:
            return _arg.fields[0].Id;
        case 2:
            return _arg.fields[0].Id;
        case 3:
            return _arg.fields[0].Id;
        case 4:
            return _arg.fields[0].Id;
        case 5:
            return _arg.fields[0].Id;
        case 6:
            return _arg.fields[0].Id;
        case 7:
            return _arg.fields[0].Id;
        case 8:
            return _arg.fields[0].Id;
        case 9:
            return _arg.fields[0].Id;
        default:
            return _arg.fields[0].Id;
    }
}

export function Helper_receiverTypeLocation(_arg) {
    switch (_arg.tag) {
        case 1:
            return _arg.fields[0].LocationId;
        case 2:
            return undefined;
        case 3:
            return undefined;
        case 4:
            return _arg.fields[0].LocationId;
        case 5:
            return undefined;
        case 6:
            return undefined;
        case 7:
            return undefined;
        case 8:
            return undefined;
        case 9:
            return undefined;
        default:
            return _arg.fields[0].LocationId;
    }
}

export function Helper_receiverTypeNameToString(_arg) {
    switch (_arg.tag) {
        case 1:
            return "employees";
        case 2:
            return "external-persons";
        case 3:
            return "subcontractors";
        case 4:
            return "rooms";
        case 5:
            return "projects";
        case 6:
            return "construction-sites";
        case 7:
            return "students";
        case 8:
            return "school-classes";
        case 9:
            return "vehicles";
        default:
            return "users";
    }
}

export function Helper_receiverIsArchived(_arg) {
    switch (_arg.tag) {
        case 1:
            return _arg.fields[0].IsArchived;
        case 2:
            return _arg.fields[0].IsArchived;
        case 3:
            return _arg.fields[0].IsArchived;
        case 4:
            return _arg.fields[0].IsArchived;
        case 5:
            return _arg.fields[0].IsArchived;
        case 6:
            return _arg.fields[0].IsArchived;
        case 7:
            return _arg.fields[0].IsArchived;
        case 8:
            return _arg.fields[0].IsArchived;
        case 9:
            return _arg.fields[0].IsArchived;
        default:
            return _arg.fields[0].IsArchived;
    }
}

