import { Variant } from "./Variant.js";
import { Customer } from "./Shared/Shared.js";
import { toString } from "./fable_modules/fable-library-js.4.19.2/Date.js";
import { empty, tryFind } from "./fable_modules/fable-library-js.4.19.2/List.js";
import { RouterModule_urlSegments } from "./fable_modules/Feliz.Router.4.0.0/Router.fs.js";
import { map, toList } from "./fable_modules/fable-library-js.4.19.2/Seq.js";
import { item } from "./fable_modules/fable-library-js.4.19.2/Array.js";

export function parseCustomer(customerName) {
    if (Variant.tag === 0) {
        switch (customerName) {
            case "sww-staging-account":
                return new Customer(2, []);
            case "prinzing-elektrotechnik-gmbh":
                return new Customer(1, []);
            case "elektro-schöffmann-gmbh-&-co.-kg":
                return new Customer(3, []);
            case "sbucher-abbruch-und-recycling-gmbh":
                return new Customer(4, []);
            case "wasser-nord-gmbh-&-co.-kg":
                return new Customer(5, []);
            case "aerq--aircraft-solutions-gmbh":
                return new Customer(7, []);
            case "1557":
                return new Customer(9, []);
            default:
                return new Customer(0, []);
        }
    }
    else if (Variant.tag === 2) {
        return new Customer(0, []);
    }
    else {
        switch (customerName) {
            case "70":
                return new Customer(6, []);
            case "169":
                return new Customer(8, []);
            case "232":
                return new Customer(10, []);
            default:
                return new Customer(0, []);
        }
    }
}

export const numberInputIntMaxValue = 2147483647;

export function toISOString(dateTime) {
    return toString(dateTime, "yyyy-MM-ddTHH:mm:ss.fffffffZ", {});
}

export function getQueryParams(_arg) {
    const matchValue = tryFind((segment) => (segment.indexOf("?") === 0), RouterModule_urlSegments(window.location.hash, 1));
    if (matchValue == null) {
        return empty();
    }
    else {
        const queryParams = matchValue;
        return toList(map((query) => [item(0, query), item(1, query)], (new URLSearchParams(queryParams)).entries()));
    }
}

