import { Record, Union } from "../../fable_modules/fable-library-js.4.19.2/Types.js";
import { record_type, bool_type, tuple_type, lambda_type, unit_type, union_type, class_type, list_type, float64_type, option_type, anonRecord_type, string_type } from "../../fable_modules/fable-library-js.4.19.2/Reflection.js";
import { Helper_receiverTypeNameTranslation, ReceiverTypeName, Receiver_$reflection } from "../../Shared/User.js";
import { Storage_$reflection } from "../../Shared/Storage.js";
import { FormState__getValidation, FormState__startLoading, UnassignConsumableV2Dto, FormState__setValidationResponse_5219762A, ConsumableId as ConsumableId_1, RequestedValue$1, FormState_get_empty, RequestedValue$1_$reflection, FormState_$reflection, ClientApiResponse$1_$reflection } from "../../Shared/Shared.js";
import { Consumable_$reflection } from "../../Shared/Consumable.js";
import { Cmd_ofEffect, Cmd_none, Cmd_batch } from "../../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { Cmd_OfPromise_either } from "../../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "../../fable_modules/Fable.Promise.3.2.0/Promise.fs.js";
import { promise } from "../../fable_modules/Fable.Promise.3.2.0/PromiseImpl.fs.js";
import { addPrefix, fetchWithDecoder } from "../../Communication.js";
import { Types_RequestProperties } from "../../fable_modules/Fable.Fetch.2.7.0/Fetch.fs.js";
import { sortBy, toArray, isEmpty, contains, tryFind, choose, sumBy, map, filter, ofArray, singleton } from "../../fable_modules/fable-library-js.4.19.2/List.js";
import { Reader_$ctor_Z3F6BC7B1, Reader__Read_24524716 } from "../../fable_modules/Fable.Remoting.MsgPack.1.24.0/Read.fs.js";
import { unassignCmd, getConsumable } from "../../Requests/Consumable.js";
import { parse } from "../../fable_modules/fable-library-js.4.19.2/Guid.js";
import { List_groupBy } from "../../fable_modules/fable-library-js.4.19.2/Seq2.js";
import { comparePrimitives, createObj, safeHash, equals, stringHash } from "../../fable_modules/fable-library-js.4.19.2/Util.js";
import { unwrap, map as map_1 } from "../../fable_modules/fable-library-js.4.19.2/Option.js";
import { Cmd_successToast, Cmd_errorToast } from "../../Components/Toast.js";
import { unwrapStorageId } from "../../Shared/Helper.js";
import { createElement } from "react";
import React from "react";
import { useTranslation } from "react-i18next";
import { reactApi } from "../../fable_modules/Feliz.2.8.0/Interop.fs.js";
import { join } from "../../fable_modules/fable-library-js.4.19.2/String.js";
import { empty, singleton as singleton_1, append, delay, toList } from "../../fable_modules/fable-library-js.4.19.2/Seq.js";
import { GroupedSelect, Select } from "../../Components/Select.js";
import { TextArea } from "../../Components/TextArea.js";
import { NumberInput } from "../../Components/Input.js";
import { ErrorAlert } from "../../Components/Alert.js";
import { React_useElmish_Z6C327F2E } from "../../fable_modules/Feliz.UseElmish.2.5.0/UseElmish.fs.js";
import { ProgramModule_mkProgram } from "../../fable_modules/Fable.Elmish.4.2.0/program.fs.js";
import { Dialog } from "../../Components/Dialog.js";
import { Skeleton } from "../../Components/Skeleton.js";
import { TextButton } from "../../Components/Button.js";

class Msg extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["SetSelectedReceiver", "SetSelectedStorage", "SetComment", "SetQuantity", "UnassignConsumableResponse", "UnassignConsumable", "ReceiversFetched", "ConsumableFetched", "FetchError"];
    }
}

function Msg_$reflection() {
    return union_type("Consumables.UnassignConsumableDialog.Msg", [], Msg, () => [[["Item", option_type(anonRecord_type(["label", string_type], ["value", Receiver_$reflection()]))]], [["Item", option_type(anonRecord_type(["label", string_type], ["value", Storage_$reflection()]))]], [["Item", string_type]], [["Item", option_type(float64_type)]], [["Item", ClientApiResponse$1_$reflection(string_type)]], [], [["Item", list_type(Receiver_$reflection())]], [["Item", Consumable_$reflection()]], [["Item", class_type("System.Exception")]]]);
}

class State extends Record {
    constructor(FormState, SuccessCallback, OnClose, ConsumableId, Consumable, Quantity, Comment$, ReceiverId, SelectedReceiver, SelectedStorage, QuantityInvalidTranslationKey, IsSelectedReceiverInvalid, IsSelectedStorageInvalid, IsQuantityInvalid, Receivers) {
        super();
        this.FormState = FormState;
        this.SuccessCallback = SuccessCallback;
        this.OnClose = OnClose;
        this.ConsumableId = ConsumableId;
        this.Consumable = Consumable;
        this.Quantity = Quantity;
        this.Comment = Comment$;
        this.ReceiverId = ReceiverId;
        this.SelectedReceiver = SelectedReceiver;
        this.SelectedStorage = SelectedStorage;
        this.QuantityInvalidTranslationKey = QuantityInvalidTranslationKey;
        this.IsSelectedReceiverInvalid = IsSelectedReceiverInvalid;
        this.IsSelectedStorageInvalid = IsSelectedStorageInvalid;
        this.IsQuantityInvalid = IsQuantityInvalid;
        this.Receivers = Receivers;
    }
}

function State_$reflection() {
    return record_type("Consumables.UnassignConsumableDialog.State", [], State, () => [["FormState", FormState_$reflection()], ["SuccessCallback", lambda_type(unit_type, unit_type)], ["OnClose", lambda_type(unit_type, unit_type)], ["ConsumableId", string_type], ["Consumable", RequestedValue$1_$reflection(Consumable_$reflection())], ["Quantity", option_type(float64_type)], ["Comment", string_type], ["ReceiverId", string_type], ["SelectedReceiver", option_type(anonRecord_type(["label", string_type], ["value", Receiver_$reflection()]))], ["SelectedStorage", option_type(anonRecord_type(["label", string_type], ["value", Storage_$reflection()]))], ["QuantityInvalidTranslationKey", tuple_type(option_type(string_type), option_type(float64_type))], ["IsSelectedReceiverInvalid", bool_type], ["IsSelectedStorageInvalid", bool_type], ["IsQuantityInvalid", bool_type], ["Receivers", RequestedValue$1_$reflection(list_type(Receiver_$reflection()))]]);
}

function init(props) {
    return [new State(FormState_get_empty(), props.SuccessCallback, props.OnClose, props.ConsumableId, new RequestedValue$1(0, []), undefined, "", props.ReceiverId, undefined, undefined, [undefined, undefined], false, false, false, new RequestedValue$1(0, [])), Cmd_batch(ofArray([Cmd_OfPromise_either((url) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (fetchWithDecoder(0, addPrefix(url), singleton(new Types_RequestProperties(0, ["GET"])), "application/json", (response) => {
        const pr = response.arrayBuffer();
        return pr.then((blob) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob)), list_type(Receiver_$reflection())));
    })))), "/api/receivers/active", (Item) => (new Msg(6, [Item])), (Item_1) => (new Msg(8, [Item_1]))), getConsumable((Item_2) => (new Msg(7, [Item_2])), (Item_3) => (new Msg(8, [Item_3])), new ConsumableId_1(parse(props.ConsumableId)))]))];
}

function getStockPerUser(consumable) {
    return filter((tupledArg_1) => (tupledArg_1[1] > 0), map((tupledArg) => {
        const events = tupledArg[1];
        return [tupledArg[0], sumBy((event_2) => {
            switch (event_2.tag) {
                case 0:
                    return event_2.fields[0].Quantity;
                case 1:
                    return event_2.fields[0].Quantity;
                case 3:
                    return 0;
                case 4:
                    return 0;
                default:
                    return 0;
            }
        }, events, {
            GetZero: () => 0,
            Add: (x_1, y_1) => (x_1 + y_1),
        }) - sumBy((event_3) => {
            switch (event_3.tag) {
                case 0:
                    return 0;
                case 1:
                    return 0;
                case 3:
                    return 0;
                case 4:
                    return 0;
                default:
                    return event_3.fields[0].Quantity;
            }
        }, events, {
            GetZero: () => 0,
            Add: (x_2, y_2) => (x_2 + y_2),
        })];
    }, List_groupBy((event_1) => {
        switch (event_1.tag) {
            case 0:
                return event_1.fields[0].ReceiverId;
            case 1:
                return event_1.fields[0].ReceiverId;
            case 3:
                throw new Error("invalid event");
            case 4:
                throw new Error("invalid event");
            default:
                return event_1.fields[0].SenderId;
        }
    }, choose((event) => {
        switch (event.tag) {
            case 0:
                return event;
            case 1:
                return event;
            case 3:
                return undefined;
            case 4:
                return undefined;
            default:
                return event;
        }
    }, consumable.Events), {
        Equals: (x, y) => (x === y),
        GetHashCode: stringHash,
    })));
}

function userStock(consumable, userId) {
    return map_1((value) => value[1], tryFind((tupledArg) => (tupledArg[0] === userId), getStockPerUser(consumable)));
}

function update(msg, state) {
    switch (msg.tag) {
        case 1:
            return [new State(state.FormState, state.SuccessCallback, state.OnClose, state.ConsumableId, state.Consumable, state.Quantity, state.Comment, state.ReceiverId, state.SelectedReceiver, msg.fields[0], state.QuantityInvalidTranslationKey, state.IsSelectedReceiverInvalid, state.IsSelectedStorageInvalid, state.IsQuantityInvalid, state.Receivers), Cmd_none()];
        case 2:
            return [new State(state.FormState, state.SuccessCallback, state.OnClose, state.ConsumableId, state.Consumable, state.Quantity, msg.fields[0], state.ReceiverId, state.SelectedReceiver, state.SelectedStorage, state.QuantityInvalidTranslationKey, state.IsSelectedReceiverInvalid, state.IsSelectedStorageInvalid, state.IsQuantityInvalid, state.Receivers), Cmd_none()];
        case 3:
            return [new State(state.FormState, state.SuccessCallback, state.OnClose, state.ConsumableId, state.Consumable, msg.fields[0], state.Comment, state.ReceiverId, state.SelectedReceiver, state.SelectedStorage, state.QuantityInvalidTranslationKey, state.IsSelectedReceiverInvalid, state.IsSelectedStorageInvalid, state.IsQuantityInvalid, state.Receivers), Cmd_none()];
        case 4: {
            const response = msg.fields[0];
            if (response.tag === 1) {
                return [new State(FormState__setValidationResponse_5219762A(state.FormState, response.fields[0]), state.SuccessCallback, state.OnClose, state.ConsumableId, state.Consumable, state.Quantity, state.Comment, state.ReceiverId, state.SelectedReceiver, state.SelectedStorage, state.QuantityInvalidTranslationKey, state.IsSelectedReceiverInvalid, state.IsSelectedStorageInvalid, state.IsQuantityInvalid, state.Receivers), Cmd_errorToast("validation.toast")];
            }
            else {
                return [new State(FormState_get_empty(), state.SuccessCallback, state.OnClose, state.ConsumableId, state.Consumable, state.Quantity, state.Comment, state.ReceiverId, state.SelectedReceiver, state.SelectedStorage, state.QuantityInvalidTranslationKey, state.IsSelectedReceiverInvalid, state.IsSelectedStorageInvalid, state.IsQuantityInvalid, state.Receivers), Cmd_batch(ofArray([Cmd_ofEffect((_arg) => {
                    state.SuccessCallback();
                }), Cmd_successToast("general.saved_successfully"), Cmd_ofEffect((_arg_1) => {
                    state.OnClose();
                })]))];
            }
        }
        case 5: {
            const matchValue = state.Quantity;
            const matchValue_1 = state.SelectedReceiver;
            const matchValue_2 = state.SelectedStorage;
            const matchValue_3 = state.Consumable;
            let matchResult, quantity_1, consumable, quantity_2, receiver, storage;
            if (matchValue == null) {
                if (matchValue_1 == null) {
                    matchResult = 5;
                }
                else if (matchValue_2 != null) {
                    matchResult = 1;
                }
                else {
                    matchResult = 6;
                }
            }
            else if (matchValue_1 == null) {
                matchResult = 4;
            }
            else if (matchValue_2 != null) {
                if (matchValue === 0) {
                    matchResult = 0;
                    quantity_1 = matchValue;
                }
                else if (matchValue_3.tag === 1) {
                    matchResult = 2;
                    consumable = matchValue_3.fields[0];
                    quantity_2 = matchValue;
                    receiver = matchValue_1;
                    storage = matchValue_2;
                }
                else {
                    matchResult = 3;
                }
            }
            else {
                matchResult = 3;
            }
            switch (matchResult) {
                case 0:
                    return [new State(state.FormState, state.SuccessCallback, state.OnClose, state.ConsumableId, state.Consumable, state.Quantity, state.Comment, state.ReceiverId, state.SelectedReceiver, state.SelectedStorage, ["tool.dialog_assign_quantity_empty_validation", undefined], state.IsSelectedReceiverInvalid, state.IsSelectedStorageInvalid, state.IsQuantityInvalid, state.Receivers), Cmd_none()];
                case 1:
                    return [new State(state.FormState, state.SuccessCallback, state.OnClose, state.ConsumableId, state.Consumable, state.Quantity, state.Comment, state.ReceiverId, state.SelectedReceiver, state.SelectedStorage, ["tool.dialog_assign_quantity_empty_validation", undefined], state.IsSelectedReceiverInvalid, state.IsSelectedStorageInvalid, state.IsQuantityInvalid, state.Receivers), Cmd_none()];
                case 2: {
                    const userStock_1 = userStock(consumable, receiver.value.Id);
                    if (userStock_1 == null) {
                        return [new State(state.FormState, state.SuccessCallback, state.OnClose, state.ConsumableId, state.Consumable, state.Quantity, state.Comment, state.ReceiverId, state.SelectedReceiver, state.SelectedStorage, ["consumable.dialog_unassign_quantity_to_low", undefined], state.IsSelectedReceiverInvalid, state.IsSelectedStorageInvalid, state.IsQuantityInvalid, state.Receivers), Cmd_none()];
                    }
                    else {
                        const userStock_2 = userStock_1;
                        if (userStock_2 < quantity_2) {
                            return [new State(state.FormState, state.SuccessCallback, state.OnClose, state.ConsumableId, state.Consumable, state.Quantity, state.Comment, state.ReceiverId, state.SelectedReceiver, state.SelectedStorage, ["consumable.dialog_unassign_quantity_to_low", userStock_2], state.IsSelectedReceiverInvalid, state.IsSelectedStorageInvalid, state.IsQuantityInvalid, state.Receivers), Cmd_none()];
                        }
                        else {
                            const dto = new UnassignConsumableV2Dto(receiver.value.Id, unwrapStorageId(storage.value.Id), state.Comment, quantity_2, "");
                            return [new State(FormState__startLoading(state.FormState), state.SuccessCallback, state.OnClose, state.ConsumableId, state.Consumable, state.Quantity, state.Comment, state.ReceiverId, state.SelectedReceiver, state.SelectedStorage, [undefined, undefined], false, false, state.IsQuantityInvalid, state.Receivers), unassignCmd(new ConsumableId_1(parse(state.ConsumableId)), dto, (Item_1) => (new Msg(4, [Item_1])), (Item_2) => (new Msg(8, [Item_2])))];
                        }
                    }
                }
                case 3:
                    return [new State(state.FormState, state.SuccessCallback, state.OnClose, state.ConsumableId, state.Consumable, state.Quantity, state.Comment, state.ReceiverId, state.SelectedReceiver, state.SelectedStorage, state.QuantityInvalidTranslationKey, false, true, state.IsQuantityInvalid, state.Receivers), Cmd_none()];
                case 4:
                    return [new State(state.FormState, state.SuccessCallback, state.OnClose, state.ConsumableId, state.Consumable, state.Quantity, state.Comment, state.ReceiverId, state.SelectedReceiver, state.SelectedStorage, state.QuantityInvalidTranslationKey, true, true, state.IsQuantityInvalid, state.Receivers), Cmd_none()];
                case 5:
                    return [new State(state.FormState, state.SuccessCallback, state.OnClose, state.ConsumableId, state.Consumable, state.Quantity, state.Comment, state.ReceiverId, state.SelectedReceiver, state.SelectedStorage, state.QuantityInvalidTranslationKey, true, true, state.IsQuantityInvalid, state.Receivers), Cmd_none()];
                default:
                    return [new State(state.FormState, state.SuccessCallback, state.OnClose, state.ConsumableId, state.Consumable, state.Quantity, state.Comment, state.ReceiverId, state.SelectedReceiver, state.SelectedStorage, state.QuantityInvalidTranslationKey, false, false, state.IsQuantityInvalid, state.Receivers), Cmd_none()];
            }
        }
        case 7:
            return [new State(state.FormState, state.SuccessCallback, state.OnClose, state.ConsumableId, new RequestedValue$1(1, [msg.fields[0]]), state.Quantity, state.Comment, state.ReceiverId, state.SelectedReceiver, state.SelectedStorage, state.QuantityInvalidTranslationKey, state.IsSelectedReceiverInvalid, state.IsSelectedStorageInvalid, state.IsQuantityInvalid, state.Receivers), Cmd_none()];
        case 6:
            return [new State(state.FormState, state.SuccessCallback, state.OnClose, state.ConsumableId, state.Consumable, state.Quantity, state.Comment, state.ReceiverId, state.SelectedReceiver, state.SelectedStorage, state.QuantityInvalidTranslationKey, state.IsSelectedReceiverInvalid, state.IsSelectedStorageInvalid, state.IsQuantityInvalid, new RequestedValue$1(1, [msg.fields[0]])), Cmd_none()];
        case 8:
            return [state, Cmd_none()];
        default:
            return [new State(state.FormState, state.SuccessCallback, state.OnClose, state.ConsumableId, state.Consumable, state.Quantity, state.Comment, state.ReceiverId, msg.fields[0], state.SelectedStorage, state.QuantityInvalidTranslationKey, state.IsSelectedReceiverInvalid, state.IsSelectedStorageInvalid, state.IsQuantityInvalid, state.Receivers), Cmd_none()];
    }
}

function targetStorages(props) {
    const consumableStorages = filter((s) => contains(s.Id, map((sps) => sps.StorageId, props.Consumable.StockPerStorage), {
        Equals: equals,
        GetHashCode: safeHash,
    }), props.Storages);
    const userStorageIds = (props.UserData.Role === "administrator") ? map((s_1) => s_1.Id, props.Storages) : map((s_3) => s_3.Id, filter((s_2) => contains(props.UserData.UserId, s_2.AssignedUserIds, {
        Equals: (x_1, y_1) => (x_1 === y_1),
        GetHashCode: stringHash,
    }), props.Storages));
    return filter((s_4) => contains(s_4.Id, userStorageIds, {
        Equals: equals,
        GetHashCode: safeHash,
    }), consumableStorages);
}

function filterUsers(props) {
    const affectedUserIds = map((tupledArg) => tupledArg[0], getStockPerUser(props.Consumable));
    return filter((u_1) => contains(u_1.Id, affectedUserIds, {
        Equals: (x, y) => (x === y),
        GetHashCode: stringHash,
    }), props.Users);
}

function DialogBody(props) {
    let elems, elems_1;
    const patternInput = useTranslation();
    const t = patternInput[0];
    const targetStorages_1 = targetStorages({
        Consumable: props.Consumable,
        Storages: props.Storages,
        UserData: props.UserData,
    });
    const storageOptions = map((storage) => ({
        label: storage.Name,
        value: storage,
    }), targetStorages_1);
    const receiverOptions = filter((receiver) => (userStock(props.Consumable, receiver.Id) != null), props.Users);
    if (isEmpty(targetStorages_1)) {
        return createElement("div", createObj(singleton((elems = [createElement("p", {
            children: t("consumable.dialog_assign_quantity_empty_storage"),
        })], ["children", reactApi.Children.toArray(Array.from(elems))]))));
    }
    else {
        return createElement("div", createObj(ofArray([["className", join(" ", ["grid", "gap-4"])], (elems_1 = toList(delay(() => append(singleton_1(createElement(Select, {
            ComponentState: props.ComponentState,
            IsClearable: false,
            Label: t("consumable.choose_storage"),
            NoOptionsMessage: "select.storage_no_options",
            OnChange: props.SetSelectedStorage,
            Options: storageOptions,
            PlaceholderKey: "select.storage_placeholder",
            TestId: "",
            ValidationMessage: unwrap(props.IsStorageInvalid ? "tool.switch_storage_missing_storage_validation" : undefined),
            Value: unwrap(props.SelectedStorage),
        })), delay(() => append(singleton_1(createElement(GroupedSelect, (($value) => { $value.key = $value.Key; return $value; })({
            ComponentState: props.ComponentState,
            IsClearable: false,
            Key: "select-receiver",
            Label: t("consumable.choose_sender"),
            NoOptionsMessage: "select.employee_no_options",
            OnChange: props.SetSelectedReceiver,
            Options: map((tupledArg) => {
                const receiverType = tupledArg[0];
                const receivers_1 = toArray(sortBy((receiver_3) => receiver_3.label.toLowerCase(), map((receiver_2) => {
                    const matchValue = receiver_2.LocationName;
                    if (matchValue != null) {
                        const locationName = matchValue;
                        if (equals(receiverType, new ReceiverTypeName(4, []))) {
                            return {
                                label: `${locationName} - ${receiver_2.Name}`,
                                value: receiver_2,
                            };
                        }
                        else {
                            return {
                                label: `${receiver_2.Name} - ${locationName}`,
                                value: receiver_2,
                            };
                        }
                    }
                    else {
                        return {
                            label: `${receiver_2.Name}`,
                            value: receiver_2,
                        };
                    }
                }, tupledArg[1]), {
                    Compare: comparePrimitives,
                }));
                return {
                    label: Helper_receiverTypeNameTranslation(t, receiverType),
                    options: receivers_1,
                };
            }, List_groupBy((receiver_1) => receiver_1.ReceiverType, props.Users, {
                Equals: equals,
                GetHashCode: safeHash,
            })),
            PlaceholderKey: "select.employee_placeholder",
            Value: unwrap(props.SelectedReceiver),
        }))), delay(() => append(singleton_1(createElement(TextArea, {
            ComponentState: props.ComponentState,
            Label: t("general.comment"),
            OnChange: props.SetComment,
            TestId: "",
            Value: props.Comment,
        })), delay(() => {
            let matchValue_1, key_3, value_4, arg_1, key_4;
            return append(singleton_1(createElement(NumberInput, {
                ComponentState: props.ComponentState,
                Label: t("general.quantity_3"),
                OnChange: props.SetQuantity,
                TestId: "",
                ValidationMessage: unwrap((matchValue_1 = props.QuantityInvalidTranslationKey, (matchValue_1[0] == null) ? undefined : ((matchValue_1[1] != null) ? ((key_3 = matchValue_1[0], (value_4 = matchValue_1[1], (arg_1 = {
                    quantity: value_4,
                }, patternInput[1].t(key_3, arg_1))))) : ((key_4 = matchValue_1[0], t(key_4)))))),
                Value: unwrap(props.Quantity),
            })), delay(() => {
                const matchValue_2 = FormState__getValidation(props.FormState, t, "global");
                if (matchValue_2 == null) {
                    return empty();
                }
                else {
                    return singleton_1(createElement(ErrorAlert, {
                        Label: matchValue_2,
                    }));
                }
            }));
        })))))))), ["children", reactApi.Children.toArray(Array.from(elems_1))])])));
    }
}

export function UnassignConsumableDialog(props) {
    let Title;
    const t = useTranslation()[0];
    let patternInput_1;
    const init_1 = init({
        ConsumableId: props.ConsumableId,
        OnClose: props.OnClose,
        ReceiverId: props.UserData.UserId,
        SuccessCallback: props.SuccessCallback,
    });
    patternInput_1 = React_useElmish_Z6C327F2E(() => ProgramModule_mkProgram(() => init_1, update, (_arg, _arg_1) => {
    }), undefined, [props.IsOpen]);
    const state_1 = patternInput_1[0];
    const dispatch = patternInput_1[1];
    const componentState = state_1.FormState.IsLoading ? "disabled" : "enabled";
    return createElement(Dialog, (Title = t("consumable.unassign_consumable"), {
        Body: toList(delay(() => {
            const matchValue = state_1.Consumable;
            const matchValue_1 = state_1.Receivers;
            let matchResult, consumable, receivers;
            if (matchValue.tag === 0) {
                matchResult = 1;
            }
            else if (matchValue_1.tag === 0) {
                matchResult = 1;
            }
            else {
                matchResult = 0;
                consumable = matchValue.fields[0];
                receivers = matchValue_1.fields[0];
            }
            switch (matchResult) {
                case 0:
                    return singleton_1(createElement(DialogBody, {
                        Comment: state_1.Comment,
                        ComponentState: componentState,
                        Consumable: consumable,
                        FormState: state_1.FormState,
                        IsStorageInvalid: state_1.IsSelectedStorageInvalid,
                        Quantity: unwrap(state_1.Quantity),
                        QuantityInvalidTranslationKey: state_1.QuantityInvalidTranslationKey,
                        SelectedReceiver: unwrap(state_1.SelectedReceiver),
                        SelectedStorage: unwrap(state_1.SelectedStorage),
                        SetComment: (value_2) => {
                            dispatch(new Msg(2, [value_2]));
                        },
                        SetQuantity: (value_3) => {
                            dispatch(new Msg(3, [value_3]));
                        },
                        SetSelectedReceiver: (value_1) => {
                            dispatch(new Msg(0, [value_1]));
                        },
                        SetSelectedStorage: (value) => {
                            dispatch(new Msg(1, [value]));
                        },
                        Storages: props.Storages,
                        UserData: props.UserData,
                        Users: receivers,
                    }));
                default:
                    return singleton_1(createElement(Skeleton, {
                        Variant: "normal",
                    }));
            }
        })),
        Controls: ofArray([createElement(TextButton, {
            ComponentState: componentState,
            Label: t("general.cancel"),
            OnClick: props.OnClose,
            TestId: "close-unassign-consumable-dialog-dialog-test-id",
            Variant: "blueButton",
        }), createElement(TextButton, {
            ComponentState: componentState,
            Label: t("general.take_back"),
            OnClick: () => {
                dispatch(new Msg(5, []));
            },
            TestId: "save-unassign-consumable-dialog-dialog-test-id",
            Variant: "blueButton",
        })]),
        IsOpen: props.IsOpen,
        OnClose: props.OnClose,
        Title: Title,
    }));
}

