import { toString, Record, Union } from "../../fable_modules/fable-library-js.4.19.2/Types.js";
import { record_type, class_type, option_type, string_type, union_type } from "../../fable_modules/fable-library-js.4.19.2/Reflection.js";
import { StocktakingState__ToI18nString, StocktakingResultType, StocktakingResultType_$reflection } from "../../Shared/Stocktaking.js";
import { createElement } from "react";
import React from "react";
import { useTranslation } from "react-i18next";
import { reactApi } from "../../fable_modules/Feliz.2.8.0/Interop.fs.js";
import { empty as empty_1, map as map_1, head, tail, isEmpty, singleton as singleton_1, toArray, ofArray, length, choose, append } from "../../fable_modules/fable-library-js.4.19.2/List.js";
import { defaultArg, unwrap, map } from "../../fable_modules/fable-library-js.4.19.2/Option.js";
import { equals, createObj, int32ToString } from "../../fable_modules/fable-library-js.4.19.2/Util.js";
import { join } from "../../fable_modules/fable-library-js.4.19.2/String.js";
import { empty, singleton, append as append_1, delay, toList } from "../../fable_modules/fable-library-js.4.19.2/Seq.js";
import { ConfirmStocktakingResultDialog } from "../Dialog/ConfirmStocktakingResultDialog.js";
import { Msg } from "./Types.js";
import { CorrectionBookingDialog } from "../Dialog/CorrectionBookingDialog.js";
import { TabsMenu } from "../../Widgets/TabsMenu.js";
import { SuccessAlert } from "../../Components/Alert.js";
import { InfiniteTable } from "../../Widgets/Table/InfiniteTable.js";
import Check from "../../../public/assets/icons/Check.svg";
import Delete_1 from "../../../public/assets/icons/Delete_1.svg";
import { defaultOf } from "../../fable_modules/fable-library-js.4.19.2/Util.js";
import { TableControl$1, ColumnDefinition$1, CellContent } from "../../Widgets/Table/Table.js";
import { StocktakingId__get_unwrap, Label as Label_2, LabelId } from "../../Shared/Shared.js";
import Qr_Code from "../../../public/assets/icons/Qr_Code.svg";
import { TextValue } from "../../Components/Text.js";
import { toString as toString_1 } from "../../fable_modules/fable-library-js.4.19.2/Date.js";
import { DetailViewHeader } from "../../Widgets/DetailViewHeader.js";
import { RouterModule_nav } from "../../fable_modules/Feliz.Router.4.0.0/Router.fs.js";
import { update as update_1, init as init_1 } from "./State.js";
import { React_useElmish_Z6C327F2E } from "../../fable_modules/Feliz.UseElmish.2.5.0/UseElmish.fs.js";
import { ProgramModule_mkProgram } from "../../fable_modules/Fable.Elmish.4.2.0/program.fs.js";
import { Skeleton } from "../../Components/Skeleton.js";

export class SelectedTab extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["OpenTasks", "AllRows"];
    }
}

export function SelectedTab_$reflection() {
    return union_type("Stocktaking.StocktakingList.View.SelectedTab", [], SelectedTab, () => [[], []]);
}

export class StocktakingListTableRow extends Record {
    constructor(Name, Quantity, RecordedBy, RecordTimestamp, RecordedQuantity, CorrectedBy, CorrectionTimestamp, CorrectionQuantity, StocktakingResultType) {
        super();
        this.Name = Name;
        this.Quantity = Quantity;
        this.RecordedBy = RecordedBy;
        this.RecordTimestamp = RecordTimestamp;
        this.RecordedQuantity = RecordedQuantity;
        this.CorrectedBy = CorrectedBy;
        this.CorrectionTimestamp = CorrectionTimestamp;
        this.CorrectionQuantity = CorrectionQuantity;
        this.StocktakingResultType = StocktakingResultType;
    }
}

export function StocktakingListTableRow_$reflection() {
    return record_type("Stocktaking.StocktakingList.View.StocktakingListTableRow", [], StocktakingListTableRow, () => [["Name", string_type], ["Quantity", string_type], ["RecordedBy", option_type(string_type)], ["RecordTimestamp", option_type(class_type("System.DateTime"))], ["RecordedQuantity", option_type(string_type)], ["CorrectedBy", option_type(string_type)], ["CorrectionTimestamp", option_type(class_type("System.DateTime"))], ["CorrectionQuantity", option_type(string_type)], ["StocktakingResultType", StocktakingResultType_$reflection()]]);
}

export function StocktakingListOverviewTable(props) {
    let elems_4;
    const t = useTranslation()[0];
    const patternInput_1 = reactApi.useState(undefined);
    const setIsConfirmStocktakingResultDialogOpen = patternInput_1[1];
    const patternInput_2 = reactApi.useState(undefined);
    const setIsCorrectionBookingDialogOpen = patternInput_2[1];
    const patternInput_3 = reactApi.useState(new SelectedTab(0, []));
    const setSelectedTab = patternInput_3[1];
    const selectedTab = patternInput_3[0];
    const overviewRows = append(choose((stocktakingListTool) => (new StocktakingListTableRow(stocktakingListTool.ToolName, "", map((_arg) => _arg.RecordedBy, stocktakingListTool.Result), map((_arg_1) => _arg_1.Timestamp, stocktakingListTool.Result), undefined, map((_arg_2) => _arg_2.CorrectedBy, stocktakingListTool.Correction), map((_arg_3) => _arg_3.Timestamp, stocktakingListTool.Correction), undefined, new StocktakingResultType(0, [stocktakingListTool.ToolId]))), props.StocktakingList.Tools), append(choose((stocktakingListToolWithQuantity) => (new StocktakingListTableRow(stocktakingListToolWithQuantity.ToolName, int32ToString(stocktakingListToolWithQuantity.Quantity), map((_arg_4) => _arg_4.RecordedBy, stocktakingListToolWithQuantity.Result), map((_arg_5) => _arg_5.Timestamp, stocktakingListToolWithQuantity.Result), map((arg) => int32ToString(arg.Quantity), stocktakingListToolWithQuantity.Result), map((_arg_7) => _arg_7.CorrectedBy, stocktakingListToolWithQuantity.Correction), map((_arg_8) => _arg_8.Timestamp, stocktakingListToolWithQuantity.Correction), map((arg_1) => int32ToString(arg_1.Quantity), stocktakingListToolWithQuantity.Correction), new StocktakingResultType(1, [stocktakingListToolWithQuantity.ToolId]))), props.StocktakingList.ToolsWithQuantity), choose((stocktakingListConsumable) => (new StocktakingListTableRow(stocktakingListConsumable.ConsumableName, stocktakingListConsumable.Quantity.toString(), map((_arg_10) => _arg_10.RecordedBy, stocktakingListConsumable.Result), map((_arg_11) => _arg_11.Timestamp, stocktakingListConsumable.Result), map((arg_2) => arg_2.Quantity.toString(), stocktakingListConsumable.Result), map((_arg_13) => _arg_13.CorrectedBy, stocktakingListConsumable.Correction), map((_arg_14) => _arg_14.Timestamp, stocktakingListConsumable.Correction), map((arg_3) => arg_3.Quantity.toString(), stocktakingListConsumable.Correction), new StocktakingResultType(2, [stocktakingListConsumable.ConsumableId]))), props.StocktakingList.Consumables)));
    const requiredActionsRows = append(choose((stocktakingListTool_1) => {
        let matchResult;
        if (stocktakingListTool_1.Result != null) {
            matchResult = 1;
        }
        else if (stocktakingListTool_1.Correction != null) {
            matchResult = 1;
        }
        else {
            matchResult = 0;
        }
        switch (matchResult) {
            case 0:
                return new StocktakingListTableRow(stocktakingListTool_1.ToolName, "", undefined, undefined, undefined, undefined, undefined, undefined, new StocktakingResultType(0, [stocktakingListTool_1.ToolId]));
            default:
                return undefined;
        }
    }, props.StocktakingList.Tools), append(choose((stocktakingListToolWithQuantity_1) => {
        const matchValue_3 = stocktakingListToolWithQuantity_1.Result;
        const matchValue_4 = stocktakingListToolWithQuantity_1.Correction;
        let matchResult_1, result_1;
        if (matchValue_3 != null) {
            if (matchValue_4 != null) {
                matchResult_1 = 2;
            }
            else if (matchValue_3.Quantity !== stocktakingListToolWithQuantity_1.Quantity) {
                matchResult_1 = 1;
                result_1 = matchValue_3;
            }
            else {
                matchResult_1 = 2;
            }
        }
        else if (matchValue_4 != null) {
            matchResult_1 = 2;
        }
        else {
            matchResult_1 = 0;
        }
        switch (matchResult_1) {
            case 0:
                return new StocktakingListTableRow(stocktakingListToolWithQuantity_1.ToolName, int32ToString(stocktakingListToolWithQuantity_1.Quantity), undefined, undefined, undefined, undefined, undefined, undefined, new StocktakingResultType(1, [stocktakingListToolWithQuantity_1.ToolId]));
            case 1:
                return new StocktakingListTableRow(stocktakingListToolWithQuantity_1.ToolName, int32ToString(stocktakingListToolWithQuantity_1.Quantity), map((_arg_16) => _arg_16.RecordedBy, stocktakingListToolWithQuantity_1.Result), map((_arg_17) => _arg_17.Timestamp, stocktakingListToolWithQuantity_1.Result), map((arg_4) => int32ToString(arg_4.Quantity), stocktakingListToolWithQuantity_1.Result), map((_arg_19) => _arg_19.CorrectedBy, stocktakingListToolWithQuantity_1.Correction), map((_arg_20) => _arg_20.Timestamp, stocktakingListToolWithQuantity_1.Correction), map((arg_5) => int32ToString(arg_5.Quantity), stocktakingListToolWithQuantity_1.Correction), new StocktakingResultType(1, [stocktakingListToolWithQuantity_1.ToolId]));
            default:
                return undefined;
        }
    }, props.StocktakingList.ToolsWithQuantity), choose((stocktakingListConsumable_1) => {
        const matchValue_6 = stocktakingListConsumable_1.Result;
        const matchValue_7 = stocktakingListConsumable_1.Correction;
        let matchResult_2, result_3;
        if (matchValue_6 != null) {
            if (matchValue_7 != null) {
                matchResult_2 = 2;
            }
            else if (matchValue_6.Quantity !== stocktakingListConsumable_1.Quantity) {
                matchResult_2 = 1;
                result_3 = matchValue_6;
            }
            else {
                matchResult_2 = 2;
            }
        }
        else if (matchValue_7 != null) {
            matchResult_2 = 2;
        }
        else {
            matchResult_2 = 0;
        }
        switch (matchResult_2) {
            case 0:
                return new StocktakingListTableRow(stocktakingListConsumable_1.ConsumableName, stocktakingListConsumable_1.Quantity.toString(), undefined, undefined, undefined, undefined, undefined, undefined, new StocktakingResultType(2, [stocktakingListConsumable_1.ConsumableId]));
            case 1:
                return new StocktakingListTableRow(stocktakingListConsumable_1.ConsumableName, stocktakingListConsumable_1.Quantity.toString(), map((_arg_22) => _arg_22.RecordedBy, stocktakingListConsumable_1.Result), map((_arg_23) => _arg_23.Timestamp, stocktakingListConsumable_1.Result), map((arg_6) => arg_6.Quantity.toString(), stocktakingListConsumable_1.Result), map((_arg_25) => _arg_25.CorrectedBy, stocktakingListConsumable_1.Correction), map((_arg_26) => _arg_26.Timestamp, stocktakingListConsumable_1.Correction), map((arg_7) => arg_7.Quantity.toString(), stocktakingListConsumable_1.Correction), new StocktakingResultType(2, [stocktakingListConsumable_1.ConsumableId]));
            default:
                return undefined;
        }
    }, props.StocktakingList.Consumables)));
    return createElement("div", createObj(ofArray([["className", join(" ", ["mt-8"])], (elems_4 = toList(delay(() => append_1(singleton(createElement(ConfirmStocktakingResultDialog, {
        IsOpen: unwrap(patternInput_1[0]),
        OnClose: () => {
            setIsConfirmStocktakingResultDialogOpen(undefined);
            props.Dispatch(new Msg(0, []));
        },
        Stocktaking: props.Stocktaking,
        StocktakingListId: props.StocktakingList.Id,
    })), delay(() => append_1(singleton(createElement(CorrectionBookingDialog, {
        IsOpen: unwrap(patternInput_2[0]),
        OnClose: () => {
            setIsCorrectionBookingDialogOpen(undefined);
            props.Dispatch(new Msg(0, []));
        },
        Stocktaking: props.Stocktaking,
        StocktakingListId: props.StocktakingList.Id,
    })), delay(() => {
        let elems, Label, Label_1;
        const requiredActionsTranslation = t("stocktaking.required_actions");
        const overviewTranslation = t("general.overview");
        return append_1(singleton(createElement("div", createObj(ofArray([["className", join(" ", ["mb-4"])], (elems = [createElement(TabsMenu, {
            Tabs: ofArray([(Label = (`${requiredActionsTranslation} (${length(requiredActionsRows)})`), {
                IsSelected: equals(selectedTab, new SelectedTab(0, [])),
                Label: Label,
                OnClick: () => {
                    setSelectedTab(new SelectedTab(0, []));
                },
                TestId: "",
            }), (Label_1 = (`${overviewTranslation} (${length(overviewRows)})`), {
                IsSelected: equals(selectedTab, new SelectedTab(1, [])),
                Label: Label_1,
                OnClick: () => {
                    setSelectedTab(new SelectedTab(1, []));
                },
                TestId: "",
            })]),
        })], ["children", reactApi.Children.toArray(Array.from(elems))])])))), delay(() => {
            let TableRows, Options;
            const rows = (selectedTab.tag === 1) ? overviewRows : requiredActionsRows;
            return (equals(selectedTab, new SelectedTab(0, [])) && (length(rows) === 0)) ? singleton(createElement(SuccessAlert, {
                Label: t("stocktaking.no_required_actions"),
            })) : singleton(createElement(InfiniteTable, (TableRows = toArray(rows), (Options = {
                BorderStyle: "row",
                CSVDownload: true,
                ConfigurableColumns: false,
                DefaultSortColumn: ["name", "asc"],
                GlobalSearch: true,
                RowSelection: "multiRow",
                WithQueryParameters: false,
            }, {
                ColumnDefinitions: [new ColumnDefinition$1("status_icon", "", t("stocktaking.status"), (dto) => {
                    let matchValue_9, elems_3, elems_1, elems_2;
                    return new CellContent(1, [(dto.RecordedBy != null) ? ((matchValue_9 = dto.RecordedQuantity, (matchValue_9 == null) ? createElement("div", createObj(ofArray([["className", join(" ", ["text-primary", "h-5", "w-5"])], (elems_3 = [Check()], ["children", reactApi.Children.toArray(Array.from(elems_3))])]))) : ((matchValue_9 === dto.Quantity) ? createElement("div", createObj(ofArray([["className", join(" ", ["text-primary", "h-5", "w-5"])], (elems_1 = [Check()], ["children", reactApi.Children.toArray(Array.from(elems_1))])]))) : createElement("div", createObj(ofArray([["className", join(" ", ["text-red", "h-5", "w-5"])], (elems_2 = [Delete_1()], ["children", reactApi.Children.toArray(Array.from(elems_2))])])))))) : defaultOf()]);
                }, "none", "text"), new ColumnDefinition$1("name", "", t("general.name"), (dto_1) => (new CellContent(0, [dto_1.Name])), "text", "text"), new ColumnDefinition$1("quantity", "", t("general.quantity"), (dto_2) => (new CellContent(0, [dto_2.Quantity])), "text", "text"), new ColumnDefinition$1("recordedBy", "", t("stocktaking.recorded_by"), (dto_3) => (new CellContent(0, [defaultArg(dto_3.RecordedBy, "")])), "text", "text"), new ColumnDefinition$1("recordTimestamp", "", t("stocktaking.record_timestamp"), (dto_4) => (new CellContent(0, [defaultArg(map(toString, dto_4.RecordTimestamp), "")])), "none", "text"), new ColumnDefinition$1("recordedQuantity", "", t("stocktaking.recorded_quantity"), (dto_5) => (new CellContent(0, [defaultArg(dto_5.RecordedQuantity, "")])), "text", "text"), new ColumnDefinition$1("correctedBy", "", t("stocktaking.corrected_by"), (dto_6) => (new CellContent(0, [defaultArg(dto_6.RecordedBy, "")])), "text", "text"), new ColumnDefinition$1("correctionTimestamp", "", t("stocktaking.correction_timestamp"), (dto_7) => (new CellContent(0, [defaultArg(map(toString, dto_7.RecordTimestamp), "")])), "none", "text"), new ColumnDefinition$1("correctionQuantity", "", t("stocktaking.correction_quantity"), (dto_8) => (new CellContent(0, [defaultArg(dto_8.RecordedQuantity, "")])), "text", "text"), new ColumnDefinition$1("isToolWithQuantity", t("general.master_data"), t("general.tool_type"), (dto_9) => {
                    let matchValue_10;
                    return new CellContent(3, [(matchValue_10 = dto_9.StocktakingResultType, (matchValue_10.tag === 1) ? singleton_1(new Label_2(new LabelId("00000000-0000-0000-0000-000000000000"), t("general.tool_with_quantity"), "#000000", true)) : ((matchValue_10.tag === 2) ? singleton_1(new Label_2(new LabelId("00000000-0000-0000-0000-000000000000"), t("general.consumable"), "#9A9FFF", true)) : singleton_1(new Label_2(new LabelId("00000000-0000-0000-0000-000000000000"), t("general.tool"), "#14BC9C", true))))]);
                }, "select", "text")],
                Controls: toList(delay(() => {
                    const matchValue_11 = props.Stocktaking.State;
                    switch (matchValue_11.tag) {
                        case 2:
                            return singleton(new TableControl$1(t("stocktaking.add_result"), Qr_Code(), (value_23) => {
                                let matchResult_3, selectedElement;
                                if (!isEmpty(value_23)) {
                                    if (isEmpty(tail(value_23))) {
                                        matchResult_3 = 0;
                                        selectedElement = head(value_23);
                                    }
                                    else {
                                        matchResult_3 = 1;
                                    }
                                }
                                else {
                                    matchResult_3 = 1;
                                }
                                switch (matchResult_3) {
                                    case 0: {
                                        setIsConfirmStocktakingResultDialogOpen(selectedElement.StocktakingResultType);
                                        break;
                                    }
                                    case 1: {
                                        break;
                                    }
                                }
                            }, true, false, "stocktaking-assign-helper-to-list"));
                        case 3:
                            return singleton(new TableControl$1(t("stocktaking.do_correction_booking"), Qr_Code(), (values) => {
                                setIsCorrectionBookingDialogOpen(map_1((value_24) => value_24.StocktakingResultType, values));
                            }, true, false, "stocktaking-assign-helper-to-list"));
                        default: {
                            return empty();
                        }
                    }
                })),
                Options: Options,
                TableRows: TableRows,
            }))));
        }));
    })))))), ["children", reactApi.Children.toArray(Array.from(elems_4))])])));
}

function OverviewInformationSection(props) {
    let elems_3, elems_2, elems_1;
    const t = useTranslation()[0];
    return createElement("div", createObj(ofArray([["className", join(" ", [])], (elems_3 = [createElement("div", createObj(ofArray([["className", join(" ", ["flex", "flex-col", "gap-2"])], (elems_2 = [createElement(TextValue, {
        FontWeight: "bold",
        TestId: "overview-name-label",
        Text: t("general.name"),
    }), createElement(TextValue, {
        FontWeight: "normal",
        TestId: "overview-name-value",
        Text: props.Stocktaking.Name,
    }), createElement(TextValue, {
        FontWeight: "bold",
        TestId: "overview-description-label",
        Text: t("general.description"),
    }), createElement(TextValue, {
        FontWeight: "normal",
        TestId: "overview-description-value",
        Text: props.Stocktaking.Description,
    }), createElement(TextValue, {
        FontWeight: "bold",
        TestId: "overview-state-label",
        Text: t("general.state"),
    }), createElement(TextValue, {
        FontWeight: "normal",
        TestId: "overview-state-value",
        Text: t(StocktakingState__ToI18nString(props.Stocktaking.State)),
    }), createElement(TextValue, {
        FontWeight: "bold",
        TestId: "overview-stocktaking-time",
        Text: t("stocktaking.stocktaking_time"),
    }), createElement("div", createObj(ofArray([["className", join(" ", ["flex", "gap-2", "mt-2"])], (elems_1 = toList(delay(() => append_1(singleton(createElement("div", {
        className: join(" ", ["mt-1"]),
        children: reactApi.Children.toArray([]),
    })), delay(() => singleton(`${toString_1(props.Stocktaking.StartDate, "dd.MM.yyyy")} - ${toString_1(props.Stocktaking.EndDate, "dd.MM.yyyy")}`))))), ["children", reactApi.Children.toArray(Array.from(elems_1))])])))], ["children", reactApi.Children.toArray(Array.from(elems_2))])])))], ["children", reactApi.Children.toArray(Array.from(elems_3))])])));
}

function OverviewSection(props) {
    let elems_2, elems_1, elems;
    return createElement("div", createObj(ofArray([["className", join(" ", ["flex", "flex-col", "grow"])], (elems_2 = [createElement("div", createObj(ofArray([["className", join(" ", ["bg-white", "rounded", "px-12", "py-9"])], (elems_1 = [createElement("div", createObj(ofArray([["className", join(" ", ["flex", "mt-6"])], (elems = [createElement(OverviewInformationSection, {
        Stocktaking: props.Stocktaking,
    })], ["children", reactApi.Children.toArray(Array.from(elems))])])))], ["children", reactApi.Children.toArray(Array.from(elems_1))])])))], ["children", reactApi.Children.toArray(Array.from(elems_2))])])));
}

function StocktakingListDetailView(props) {
    let elems_4, elems_3, elems, elems_1, elems_2, children;
    const patternInput = useTranslation();
    return createElement("div", createObj(ofArray([["className", join(" ", ["flex", "grow", "flex-col", "h-full"])], (elems_4 = [createElement("div", createObj(ofArray([["className", join(" ", ["grid", "grid-cols-[min(calc(100vw-16rem-2px-28px-11rem),80rem)]", "h-full"])], (elems_3 = [createElement("div", createObj(ofArray([["className", join(" ", ["col-span-2"])], (elems = [createElement(DetailViewHeader, {
        Badges: empty_1(),
        OnBack: () => {
            RouterModule_nav(ofArray(["stocktaking", StocktakingId__get_unwrap(props.Stocktaking.Id)]), 1, 1);
        },
        Title: props.StocktakingList.DisplayName,
    })], ["children", reactApi.Children.toArray(Array.from(elems))])]))), createElement("div", createObj(ofArray([["className", join(" ", ["col-start-1", "pr-3"])], (elems_1 = [createElement(OverviewSection, {
        Dispatch: props.Dispatch,
        Stocktaking: props.Stocktaking,
    })], ["children", reactApi.Children.toArray(Array.from(elems_1))])]))), createElement("div", createObj(ofArray([["className", join(" ", ["col-start-1", "col-span-2", "h-[calc(100%-50px)]"])], (elems_2 = [(children = singleton_1(createElement(StocktakingListOverviewTable, {
        Dispatch: props.Dispatch,
        Stocktaking: props.Stocktaking,
        StocktakingList: props.StocktakingList,
    })), createElement("div", {
        children: reactApi.Children.toArray(Array.from(children)),
    }))], ["children", reactApi.Children.toArray(Array.from(elems_2))])])))], ["children", reactApi.Children.toArray(Array.from(elems_3))])])))], ["children", reactApi.Children.toArray(Array.from(elems_4))])])));
}

export function DetailView(props) {
    let patternInput;
    const init = init_1(props.StocktakingId, props.StocktakingListId);
    patternInput = React_useElmish_Z6C327F2E(() => ProgramModule_mkProgram(() => init, update_1, (_arg, _arg_1) => {
    }), undefined, undefined);
    const state_1 = patternInput[0];
    const matchValue = state_1.Stocktaking;
    const matchValue_1 = state_1.StocktakingList;
    let matchResult, stocktaking, stocktakingList;
    if (matchValue.tag === 0) {
        matchResult = 1;
    }
    else if (matchValue_1.tag === 0) {
        matchResult = 1;
    }
    else {
        matchResult = 0;
        stocktaking = matchValue.fields[0];
        stocktakingList = matchValue_1.fields[0];
    }
    switch (matchResult) {
        case 0:
            return createElement(StocktakingListDetailView, {
                Dispatch: patternInput[1],
                Stocktaking: stocktaking,
                StocktakingList: stocktakingList,
            });
        default:
            return createElement(Skeleton, {
                Variant: "detail",
            });
    }
}

