import { createElement } from "react";
import React from "react";
import * as react from "react";
import { useTranslation } from "react-i18next";
import { ColumnDefinition$1, CellContent, Table } from "../../Widgets/Table/Table.js";
import { singleton as singleton_1, isEmpty, ofArray, empty, map, toArray } from "../../fable_modules/fable-library-js.4.19.2/List.js";
import { Helper_unitToTranslationKey } from "../../Shared/Consumable.js";
import { reactApi } from "../../fable_modules/Feliz.2.8.0/Interop.fs.js";
import { Helper_stateToTranslationKey, ReservationDto, ReservationToolType } from "../../Shared/Planning.js";
import { createObj } from "../../fable_modules/fable-library-js.4.19.2/Util.js";
import { DeleteReservationDialog } from "../../Widgets/Dialogs/DeleteReservationDialog.js";
import { RouterModule_nav } from "../../fable_modules/Feliz.Router.4.0.0/Router.fs.js";
import { AssignReservationDialog } from "../Dialogs/AssignReservationDialog.js";
import { unwrap, map as map_1, defaultArg } from "../../fable_modules/fable-library-js.4.19.2/Option.js";
import { Msg } from "./Types.js";
import { join } from "../../fable_modules/fable-library-js.4.19.2/String.js";
import { empty as empty_1, singleton, append, delay, toList } from "../../fable_modules/fable-library-js.4.19.2/Seq.js";
import { DetailViewHeader } from "../../Widgets/DetailViewHeader.js";
import { TextButton } from "../../Components/Button.js";
import Following from "../../../public/assets/icons/Following.svg";
import { CustomField, PropertyList } from "../../Widgets/PropertyListWidget.js";
import { toString } from "../../fable_modules/fable-library-js.4.19.2/Date.js";
import { TableContainer } from "../../Widgets/Container.js";
import { update as update_1, init as init_1 } from "./State.js";
import { React_useElmish_Z6C327F2E } from "../../fable_modules/Feliz.UseElmish.2.5.0/UseElmish.fs.js";
import { ProgramModule_mkProgram } from "../../fable_modules/Fable.Elmish.4.2.0/program.fs.js";
import { Skeleton } from "../../Components/Skeleton.js";

function ToolTable(props) {
    let TableRows;
    const t = useTranslation()[0];
    const xs = [createElement(Table, (TableRows = toArray(map((toolInformation) => ({
        CurrentLocation: toolInformation.CurrentLocation,
        DisplayName: toolInformation.DisplayName,
        Id: toolInformation.Id,
        ImageUrl: toolInformation.ImageUrl,
        InventoryNumber: toolInformation.InventoryNumber,
        Location: toolInformation.Location,
        Manufacturer: toolInformation.Manufacturer,
        Model: toolInformation.Model,
        QRCodeIds: toolInformation.QRCodeIds,
        SerialNumber: toolInformation.SerialNumber,
        Storage: toolInformation.Storage,
        StorageSpace: toolInformation.StorageSpace,
    }), props.Reservation.ToolsInformation)), {
        ColumnDefinitions: [new ColumnDefinition$1("image", t("general.master_data"), t("general.image"), (row) => (new CellContent(4, [row.ImageUrl])), "none", "image"), new ColumnDefinition$1("name", "", t("general.name"), (row_1) => (new CellContent(0, [row_1.DisplayName])), "text", "text"), new ColumnDefinition$1("inventoryNumber", "", t("general.inventory_number"), (row_2) => (new CellContent(0, [row_2.InventoryNumber])), "text", "text"), new ColumnDefinition$1("manufacturer", "", t("tool.manufacturer"), (row_3) => (new CellContent(0, [row_3.Manufacturer])), "text", "text"), new ColumnDefinition$1("model", "", t("tool.model"), (row_4) => (new CellContent(0, [row_4.Model])), "text", "text"), new ColumnDefinition$1("serialNumber", "", t("tool.serial_number"), (row_5) => (new CellContent(0, [row_5.SerialNumber])), "text", "text"), new ColumnDefinition$1("qrCodeIds", t("general.master_data"), t("general.qr_code"), (row_6) => (new CellContent(0, [row_6.QRCodeIds])), "text", "text"), new ColumnDefinition$1("location", t("general.master_data"), t("general.location"), (row_7) => (new CellContent(0, [row_7.Location])), "text", "text"), new ColumnDefinition$1("storage", t("general.master_data"), t("general.storage"), (row_8) => (new CellContent(0, [row_8.Storage])), "text", "text"), new ColumnDefinition$1("storageSpace", t("general.master_data"), t("tool.storage_space"), (row_9) => (new CellContent(0, [row_9.StorageSpace])), "text", "text"), new ColumnDefinition$1("currentLocation", t("general.master_data"), t("tool.assgined_to"), (row_10) => (new CellContent(0, [t(row_10.CurrentLocation)])), "text", "text")],
        Controls: empty(),
        Options: {
            BorderStyle: "row",
            CSVDownload: false,
            ConfigurableColumns: false,
            DefaultSortColumn: ["name", "asc"],
            GlobalSearch: false,
            RowSelection: "disabled",
        },
        TableRows: TableRows,
    }))];
    return react.createElement(react.Fragment, {}, ...xs);
}

function ToolWithQuantityTable(props) {
    let TableRows;
    const t = useTranslation()[0];
    const xs = [createElement(Table, (TableRows = toArray(map((toolInformation) => ({
        CurrentLocation: toolInformation.CurrentLocation,
        DisplayName: toolInformation.DisplayName,
        Id: toolInformation.Id,
        ImageUrl: toolInformation.ImageUrl,
        InventoryNumber: toolInformation.InventoryNumber,
        Location: toolInformation.Location,
        Manufacturer: toolInformation.Manufacturer,
        Model: toolInformation.Model,
        QRCodeIds: toolInformation.QRCodeIds,
        Quantity: toolInformation.Quantity,
        SerialNumber: toolInformation.SerialNumber,
        Storage: toolInformation.Storage,
        StorageSpace: toolInformation.StorageSpace,
        Unit: toolInformation.Unit,
    }), props.Reservation.ToolsWithQuantityInformation)), {
        ColumnDefinitions: [new ColumnDefinition$1("image", t("general.master_data"), t("general.image"), (row) => (new CellContent(4, [row.ImageUrl])), "none", "image"), new ColumnDefinition$1("quantity", "", t("general.quantity_3"), (row_1) => (new CellContent(0, [row_1.Quantity])), "text", "text"), new ColumnDefinition$1("unit", "", t("general.unit"), (row_2) => (new CellContent(0, [t(Helper_unitToTranslationKey(row_2.Unit))])), "text", "text"), new ColumnDefinition$1("name", "", t("general.name"), (row_3) => (new CellContent(0, [row_3.DisplayName])), "text", "text"), new ColumnDefinition$1("inventoryNumber", "", t("general.inventory_number"), (row_4) => (new CellContent(0, [row_4.InventoryNumber])), "text", "text"), new ColumnDefinition$1("manufacturer", "", t("tool.manufacturer"), (row_5) => (new CellContent(0, [row_5.Manufacturer])), "text", "text"), new ColumnDefinition$1("model", "", t("tool.model"), (row_6) => (new CellContent(0, [row_6.Model])), "text", "text"), new ColumnDefinition$1("qrCodeIds", t("general.master_data"), t("general.qr_code"), (row_7) => (new CellContent(0, [row_7.QRCodeIds])), "text", "text"), new ColumnDefinition$1("location", t("general.master_data"), t("general.location"), (row_8) => (new CellContent(0, [row_8.Location])), "text", "text"), new ColumnDefinition$1("storage", t("general.master_data"), t("general.storage"), (row_9) => (new CellContent(0, [row_9.Storage])), "text", "text"), new ColumnDefinition$1("storageSpace", t("general.master_data"), t("tool.storage_space"), (row_10) => (new CellContent(0, [row_10.StorageSpace])), "text", "text")],
        Controls: empty(),
        Options: {
            BorderStyle: "row",
            CSVDownload: false,
            ConfigurableColumns: false,
            DefaultSortColumn: ["name", "asc"],
            GlobalSearch: false,
            RowSelection: "disabled",
        },
        TableRows: TableRows,
    }))];
    return react.createElement(react.Fragment, {}, ...xs);
}

function ConsumableTable(props) {
    let TableRows;
    const t = useTranslation()[0];
    const xs = [createElement(Table, (TableRows = toArray(map((consumableReservation) => ({
        Id: consumableReservation.Id,
        ImageUrl: consumableReservation.ImageUrl,
        InventoryNumber: consumableReservation.InventoryNumber,
        Name: consumableReservation.Name,
        QRCodeIds: consumableReservation.QRCodeIds,
        Quantity: consumableReservation.Quantity,
        Storage: consumableReservation.Storage,
        StorageSpace: consumableReservation.StorageSpace,
        Unit: consumableReservation.Unit,
    }), props.Reservation.ConsumablesInformation)), {
        ColumnDefinitions: [new ColumnDefinition$1("image", t("general.master_data"), t("general.image"), (row) => (new CellContent(4, [row.ImageUrl])), "none", "image"), new ColumnDefinition$1("quantity", "", t("general.quantity_3"), (row_1) => (new CellContent(0, [row_1.Quantity])), "none", "text"), new ColumnDefinition$1("unit", "", t("general.unit"), (consumable) => (new CellContent(0, [t(Helper_unitToTranslationKey(consumable.Unit))])), "text", "text"), new ColumnDefinition$1("name", "", t("general.name"), (row_2) => (new CellContent(0, [row_2.Name])), "text", "text"), new ColumnDefinition$1("inventoryNumber", "", t("general.inventory_number"), (row_3) => (new CellContent(0, [row_3.InventoryNumber])), "text", "text"), new ColumnDefinition$1("qrCodeIds", t("general.master_data"), t("general.qr_code"), (row_4) => (new CellContent(0, [row_4.QRCodeIds])), "text", "text"), new ColumnDefinition$1("storage", t("general.master_data"), t("general.storage"), (row_5) => (new CellContent(0, [row_5.Storage])), "text", "text"), new ColumnDefinition$1("storageSpace", t("general.master_data"), t("tool.storage_space"), (row_6) => (new CellContent(0, [row_6.StorageSpace])), "text", "text")],
        Controls: empty(),
        Options: {
            BorderStyle: "row",
            CSVDownload: false,
            ConfigurableColumns: false,
            DefaultSortColumn: ["name", "asc"],
            GlobalSearch: false,
            RowSelection: "disabled",
        },
        TableRows: TableRows,
    }))];
    return react.createElement(react.Fragment, {}, ...xs);
}

function SetTable(props) {
    let TableRows;
    const t = useTranslation()[0];
    const xs = [createElement(Table, (TableRows = toArray(map((setInformation) => ({
        CurrentLocation: setInformation.CurrentLocation,
        DisplayName: setInformation.DisplayName,
        Id: setInformation.Id,
        ImageUrl: setInformation.ImageUrl,
        InventoryNumber: setInformation.InventoryNumber,
        Location: setInformation.Location,
        Manufacturer: setInformation.Manufacturer,
        Model: setInformation.Model,
        QRCodeIds: setInformation.QRCodeIds,
        SerialNumber: setInformation.SerialNumber,
        Storage: setInformation.Storage,
        StorageSpace: setInformation.StorageSpace,
    }), props.Reservation.SetsInformation)), {
        ColumnDefinitions: [new ColumnDefinition$1("image", t("general.master_data"), t("general.image"), (row) => (new CellContent(4, [row.ImageUrl])), "none", "image"), new ColumnDefinition$1("name", "", t("general.name"), (row_1) => (new CellContent(0, [row_1.DisplayName])), "text", "text"), new ColumnDefinition$1("inventoryNumber", "", t("general.inventory_number"), (row_2) => (new CellContent(0, [row_2.InventoryNumber])), "text", "text"), new ColumnDefinition$1("manufacturer", "", t("tool.manufacturer"), (row_3) => (new CellContent(0, [row_3.Manufacturer])), "text", "text"), new ColumnDefinition$1("model", "", t("tool.model"), (row_4) => (new CellContent(0, [row_4.Model])), "text", "text"), new ColumnDefinition$1("serialNumber", "", t("tool.serial_number"), (row_5) => (new CellContent(0, [row_5.SerialNumber])), "text", "text"), new ColumnDefinition$1("qrCodeIds", t("general.master_data"), t("general.qr_code"), (row_6) => (new CellContent(0, [row_6.QRCodeIds])), "text", "text"), new ColumnDefinition$1("location", t("general.master_data"), t("general.location"), (row_7) => (new CellContent(0, [row_7.Location])), "text", "text"), new ColumnDefinition$1("storage", t("general.master_data"), t("general.storage"), (row_8) => (new CellContent(0, [row_8.Storage])), "text", "text"), new ColumnDefinition$1("storageSpace", t("general.master_data"), t("tool.storage_space"), (row_9) => (new CellContent(0, [row_9.StorageSpace])), "text", "text"), new ColumnDefinition$1("currentLocation", t("general.master_data"), t("tool.assgined_to"), (row_10) => (new CellContent(0, [t(row_10.CurrentLocation)])), "text", "text")],
        Controls: empty(),
        Options: {
            BorderStyle: "row",
            CSVDownload: false,
            ConfigurableColumns: false,
            DefaultSortColumn: ["name", "asc"],
            GlobalSearch: false,
            RowSelection: "disabled",
        },
        TableRows: TableRows,
    }))];
    return react.createElement(react.Fragment, {}, ...xs);
}

function ReservationDetails(props) {
    let elems_5, elems_4;
    const t = useTranslation()[0];
    const patternInput_1 = reactApi.useState(false);
    const setIsDeleteDialogOpen = patternInput_1[1];
    const isDeleteDialogOpen = patternInput_1[0];
    const patternInput_2 = reactApi.useState(false);
    const setIsAssignModalSheetOpen = patternInput_2[1];
    const reservationDataDto = new ReservationDto(props.Reservation.Id, props.Reservation.GroupId, props.Reservation.State, "", "", props.Reservation.StartDate, props.Reservation.EndDate, {
        Id: "",
        Name: "",
        Type: new ReservationToolType(4, []),
        Unit: "",
    }, {
        ReceiverId: "",
        ReceiverName: "",
    }, {
        ReceiverId: "",
        SenderName: "",
    });
    let reservationId;
    const matchValue = props.Reservation.GroupId;
    reservationId = ((matchValue == null) ? props.Reservation.Id : matchValue);
    return createElement("div", createObj(singleton_1((elems_5 = [createElement(DeleteReservationDialog, {
        IsOpen: isDeleteDialogOpen,
        OnClose: () => {
            setIsDeleteDialogOpen(false);
        },
        Reservation: reservationDataDto,
        SuccessCallback: () => {
            RouterModule_nav(ofArray(["reservations"]), 1, 1);
        },
        UserData: props.UserData,
    }), createElement(AssignReservationDialog, {
        IsOpen: patternInput_2[0],
        OnClose: () => {
            setIsAssignModalSheetOpen(false);
        },
        ReservationId: defaultArg(map_1((x) => x, props.Reservation.GroupId), props.Reservation.Id),
        SuccessCallback: () => {
            props.Dispatch(new Msg(0, []));
        },
    }), createElement("div", createObj(ofArray([["className", join(" ", ["max-w-[75rem]"])], (elems_4 = toList(delay(() => append(singleton(createElement(DetailViewHeader, {
        Badges: empty(),
        OnBack: () => {
            RouterModule_nav(ofArray(["reservations"]), 1, 1);
        },
        OnDelete: () => {
            setIsDeleteDialogOpen(!isDeleteDialogOpen);
        },
        OnEdit: () => {
            RouterModule_nav(ofArray(["reservations", reservationId, "edit"]), 1, 1);
        },
        Title: props.Reservation.Name,
    })), delay(() => {
        let elems_3;
        return append(singleton(createElement("div", createObj(ofArray([["className", join(" ", ["flex", "flex-shrink-0", "flex-col", "mb-3", "bg-white", "rounded", "px-12", "py-9"])], (elems_3 = toList(delay(() => {
            let elems, Label;
            return append((props.Reservation.State.tag === 1) ? (empty_1()) : singleton(createElement("div", createObj(ofArray([["className", join(" ", ["flex justify-end mb-5"])], (elems = [createElement(TextButton, (Label = t("general.assign"), {
                ComponentState: "enabled",
                Icon: Following(),
                Label: Label,
                OnClick: () => {
                    setIsAssignModalSheetOpen(true);
                },
                TestId: "reservation-assign-button",
                Variant: "default",
            }))], ["children", reactApi.Children.toArray(Array.from(elems))])])))), delay(() => {
                let elems_2, elems_1;
                return singleton(createElement("div", createObj(ofArray([["className", join(" ", ["flex"])], (elems_2 = [createElement("div", createObj(ofArray([["className", join(" ", ["basis-1/2"])], (elems_1 = [createElement(PropertyList, {
                    Properties: ofArray([{
                        Label: t("general.name"),
                        TestId: "reservation-name",
                        Value: props.Reservation.Name,
                    }, {
                        Label: t("general.description"),
                        TestId: "reservation-description",
                        Value: props.Reservation.Description,
                    }, {
                        Label: t("general.state"),
                        TestId: "reservation-state",
                        Value: t(Helper_stateToTranslationKey(props.Reservation.State)),
                    }, {
                        CustomField: new CustomField(1, [`/receivers/${props.Reservation.ReceiverInformation.ReceiverId}`]),
                        Label: t("general.user"),
                        TestId: "reservation-user",
                        Value: props.Reservation.ReceiverInformation.ReceiverName,
                    }, {
                        Label: t("general.start_date"),
                        TestId: "reservation-start-date",
                        Value: toString(props.Reservation.StartDate, "dd.MM.yyyy"),
                    }, {
                        Label: t("general.end_date"),
                        TestId: "reservation-end-date",
                        Value: toString(props.Reservation.EndDate, "dd.MM.yyyy"),
                    }]),
                })], ["children", reactApi.Children.toArray(Array.from(elems_1))])])))], ["children", reactApi.Children.toArray(Array.from(elems_2))])]))));
            }));
        })), ["children", reactApi.Children.toArray(Array.from(elems_3))])])))), delay(() => {
            let Title_2;
            return append(!isEmpty(props.Reservation.ToolsInformation) ? singleton(createElement(TableContainer, (Title_2 = t("general.tool"), {
                Content: createElement(ToolTable, {
                    Reservation: props.Reservation,
                }),
                Title: unwrap(Title_2),
            }))) : empty_1(), delay(() => {
                let Title_3;
                return append(!isEmpty(props.Reservation.ToolsWithQuantityInformation) ? singleton(createElement(TableContainer, (Title_3 = t("general.tool_with_quantity"), {
                    Content: createElement(ToolWithQuantityTable, {
                        Reservation: props.Reservation,
                    }),
                    Title: unwrap(Title_3),
                }))) : empty_1(), delay(() => {
                    let Title_4;
                    return append(!isEmpty(props.Reservation.SetsInformation) ? singleton(createElement(TableContainer, (Title_4 = t("general.set"), {
                        Content: createElement(SetTable, {
                            Reservation: props.Reservation,
                        }),
                        Title: unwrap(Title_4),
                    }))) : empty_1(), delay(() => {
                        let Title_5;
                        return !isEmpty(props.Reservation.ConsumablesInformation) ? singleton(createElement(TableContainer, (Title_5 = t("general.consumable"), {
                            Content: createElement(ConsumableTable, {
                                Reservation: props.Reservation,
                            }),
                            Title: unwrap(Title_5),
                        }))) : empty_1();
                    }));
                }));
            }));
        }));
    })))), ["children", reactApi.Children.toArray(Array.from(elems_4))])])))], ["children", reactApi.Children.toArray(Array.from(elems_5))]))));
}

export function DetailReservationView(props) {
    let patternInput;
    const init = init_1(props.UserData, props.ReservationId);
    patternInput = React_useElmish_Z6C327F2E(() => ProgramModule_mkProgram(() => init, update_1, (_arg, _arg_1) => {
    }), undefined, undefined);
    const state_1 = patternInput[0];
    const matchValue = state_1.Reservation;
    const matchValue_1 = state_1.CustomerConfiguration;
    let matchResult, customerConfiguration, reservation;
    if (matchValue.tag === 0) {
        matchResult = 1;
    }
    else if (matchValue_1.tag === 0) {
        matchResult = 1;
    }
    else {
        matchResult = 0;
        customerConfiguration = matchValue_1.fields[0];
        reservation = matchValue.fields[0];
    }
    switch (matchResult) {
        case 0:
            return createElement(ReservationDetails, {
                CustomerConfiguration: customerConfiguration,
                Dispatch: patternInput[1],
                Reservation: reservation,
                UserData: props.UserData,
            });
        default:
            return createElement(Skeleton, {
                Variant: "detail",
            });
    }
}

